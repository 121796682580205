<template>
  <div class="bg-white">
    <Loading :loadingCounter="loadingCounter" />
    <v-navigation-drawer absolute temporary v-model="openMenu">
      <sideMenu></sideMenu>
    </v-navigation-drawer>
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockListPartsDetailImport" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="mt-1 d-flex gap-3">
            <!-- 取引先 -->
            <c-client-input v-model="commonForm.supplierSelected" required width="350px" />

            <!-- 取込ファイル -->
            <c-file-input v-model="commonForm.importfile" required accept=".csv" width="400px" />
            <!-- 取込ボタン -->
            <v-btn color="primary" id="get-search" class="api-btn" @click="importBtn()">{{
              $t("btn.btn_import")
            }}</v-btn>
          </v-row>
          <v-row class="d-flex justify-space-between gap-3 align-center">
            <div>
              <!--検索結果件数表示-->
              <span>{{
                $t("label.lbl_total") + "：" + commonForm.totalCount + $t("label.lbl_number")
              }}</span>
            </div>
            <!--ボタン領域-->
            <div>
              <!-- アップロードボタン -->
              <v-btn
                color="primary"
                id="get-search"
                :disabled="btnToggle"
                class="api-btn"
                @click="uplaodBtnDialog()"
                >{{ $t("btn.btn_importConfirm") }}</v-btn
              >
              <!-- 画面クリアボタン -->
              <v-btn color="primary" id="btn-search" class="api-btn" @click="clearBtnDialog()">{{
                $t("btn.btn_clear")
              }}</v-btn>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="630px"
        >
          <!-- 品番/品名 -->
          <template v-slot:[`item.product`]="{ item }">
            <div v-if="item.productCd.length > 15 || item.product.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 300px" v-bind="attrs" v-on="on">
                    {{ item.productCd }}<br />
                    {{ item.product }}
                  </div>
                </template>
                <div>{{ item.productCd }}</div>
                <div>{{ item.product }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.productCd }}<br />
              {{ item.product }}
            </div>
          </template>
          <!-- ロケーション -->
          <template v-slot:[`item.location`]="{ item }">
            <div v-if="item.location.length > 10">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 190px" v-bind="attrs" v-on="on">
                    {{ item.location }}
                  </div>
                </template>
                <div>{{ item.location }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.location }}
            </div>
          </template>
          <!-- 部品要求者 -->
          <template v-slot:[`item.partsRequester`]="{ item }">
            <div v-if="item.partsRequester.length > 10">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 190px" v-bind="attrs" v-on="on">
                    {{ item.partsRequester }}
                  </div>
                </template>
                <div>{{ item.partsRequester }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.partsRequester }}
            </div>
          </template>
          <!-- 備考 -->
          <template v-slot:[`item.remarks`]="{ item }">
            <div v-if="item.remarks.length > 30">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 380px" v-bind="attrs" v-on="on">
                    {{ item.remarks }}
                  </div>
                </template>
                <div>{{ item.remarks }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.remarks }}
            </div>
          </template>
        </v-data-table>
      </v-container>

      <!-- 詳細画面ポップアップ -->
      <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
        <StockListPartsDetailUpdateDeleteError
          :isOpenDetailDialog.sync="dialogDetails"
          :entity="detailDialogData"
          class="overflow"
          v-on:closePopup="closePopup"
        />
      </v-dialog>

      <!-- キュー状況ダイアログ -->
      <v-dialog v-model="dialogQueInfoStatus" :max-width="800" persistent no-click-animation>
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_discardOutBoundRequest") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- キュー状況テーブルデータ -->
              <v-data-table
                id="QueInfoStatusData"
                fixed-header
                :headers="queInfoHeader"
                :items="queInfoList"
                disable-sort
                :hide-default-footer="true"
              >
                <!-- 指示作成状況メッセージ -->
                <template v-slot:[`item.queInfoMessage`]="{ item }">
                  <span>
                    <!-- 指示作成状況メッセージを表示 -->
                    {{ item.queInfoMessage }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-divider v-if="sqsBtnToggle1"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div class="btn-area">
                <!-- ホーム -->
                <v-btn v-if="sqsBtnToggle1" @click="movePage('M_HOM')" style="margin-right: 10px">
                  {{ $t("menu.P-MENU-000") }}
                </v-btn>
                <!-- 在庫一覧照会 -->
                <v-btn v-if="sqsBtnToggle1" @click="movePage('P_STK_103')">
                  {{ $t("label.lbl_stockInquiry") }}
                </v-btn>
                <!-- 閉じるボタン -->
                <v-btn v-if="sqsBtnToggle2" class="other-btn" @click="dialogQueInfoStatus = false">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :stockInformationChangeDataFlg="infoDialog.stockInformationChangeDataFlg"
        :outsideClickNotCloseFlg="true"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :title="ConfirmDialog.title"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import StockListPartsDetailUpdateDeleteError from "./StockListPartsDetailUpdateDeleteError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_STK_102,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    StockListPartsDetailUpdateDeleteError,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    commonForm: {
      // 取引先
      supplierSelected: "",
      // 取込ファイル
      importfile: {},
      // 総件数
      totalCount: 0,
    },
    //CSVデータ(配列)
    listCsvData: [],
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: true,
    // メニュー
    openMenu: null,

    // テーブルフッター項目
    inputList: [],
    // テーブルヘッダ項目
    headerItems: [
      // 管理番号
      {
        text: i18n.tc("label.lbl_managementNoJP"),
        value: "controlNumber",
        align: "left",
        width: "261px",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "323px",
        align: "left",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_locationCd"),
        value: "location",
        align: "left",
        width: "215px",
        sortable: false,
      },
      // 廃却指示
      {
        text: i18n.tc("label.lbl_discardInstructions"),
        value: "disposalInstruction",
        align: "left",
        width: "215px",
        sortable: false,
      },

      // 保管期限
      {
        text: i18n.tc("label.lbl_retentionDuedate"),
        value: "retentionPeriod",
        align: "left",
        width: "215px",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "partsRequester",
        width: "215px",
        align: "left",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        align: "left",
        width: "415px",
        sortable: false,
      },
    ],
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    errorDetailDialogData: [],
    // ボタン表示の切り替え
    sqsBtnToggle1: false,
    sqsBtnToggle2: false,
    dialogDetails: false,
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelected: "",
      //画面名
      screenName:
        i18n.tc("label.lbl_stockListPartsDetailImport") + i18n.tc("label.lbl_errorConfirmation"),
      // キー情報
      uniqueName: i18n.tc("label.lbl_managementNoJP"),
      list: [],
    },
    // キュー状況ダイアログ
    dialogQueInfoStatus: false,
    // リクエストID
    requestId: "",
    // タイムID
    timeId: "",
    // キュー状況ヘッダー
    queInfoHeader: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "queInfoMessage",
        align: "left",
        width: "15 % ",
      },
    ],
    // キュー状況データ
    queInfoList: [],
  }),
  methods: {
    handleError(error) {
      if (!this.loadingCounter) {
        Object.assign(this.infoDialog, error);
      }
    },

    // 初期化
    init() {},

    // アップロードボタン押下（ダイアログ）
    uplaodBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("label.lbl_upload"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.csvConfirm;
    },
    // 取込ボタン押下
    importBtn() {
      this.inputList = [];
      this.btnToggle = true;
      // 総件数取得
      this.commonForm.totalCount = 0;
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        // 選択したファイルを読み込む
        const selectFile = this.commonForm.importfile;
        // 拡張子チェック
        let fileType = selectFile.type;
        // 取り込んだファイルはCSVの場合
        if (fileType == "text/csv") {
          this.importCSV(selectFile, true)
            .then((result) => {
              this.listCsvData = result;
            })
            .then(() => {
              this.postCsv("1");
            });
        } else {
          // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
          this.infoDialog.message = i18n.tc("check.chk_limitFileType");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.stockInformationChangeDataFlg = false;
          this.infoDialog.homePageFlag = false;
          return;
        }
      }
    },
    csvConfirm() {
      this.postCsv("2");
    },
    // 取込/アップロードボタン押下(API実行)
    postCsv(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_102;
      // 取引先SID
      body.reqIdv.clientSid = this.commonForm.supplierSelected;
      //操作フラグ
      body.reqIdv.operationFlag = val;
      // 取込対象CSVデータ(配列)
      body.reqIdv.listCsvData = this.listCsvData;

      // リクエストIDを取得
      this.requestId = body.reqCom.reqComRequestId;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力z
          .securePost(appConfig.API_URL.BIZ_STOCK_CHANGE_IMPORT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("csvConfirm() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              //操作フラグが1の場合(取込)
              if (val == "1") {
                // エラーチェック結果がある場合
                if (jsonData.resIdv.csvErrorCheckResults.length != 0) {
                  const errorList = [];
                  this.errorDetailDialogData = [];
                  jsonData.resIdv.csvErrorCheckResults.forEach((row) => {
                    errorList.push({
                      listNo: row.listNo,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                    });
                    this.errorDetailDialogData = errorList;
                  });
                  // エラー一覧タブが開く
                  // 詳細画面へ渡す値を設定します。
                  this.detailDialogData.list = this.errorDetailDialogData;
                  // 選択された列名
                  this.detailDialogData.suppliersSelected = this.commonForm.supplierSelected;
                  this.dialogDetails = true;
                } else {
                  const list = [];
                  let count = 0;
                  // 画面表示項目
                  jsonData.resIdv.csvResults.forEach((row) => {
                    //更新対象があるかチェック
                    if (
                      row.stockDisposal != "" ||
                      row.retentionDuedate != "" ||
                      row.partsRequester != "" ||
                      row.remarks != ""
                    ) {
                      count++;
                    }
                    if (row.retentionDuedate.length == 8) {
                      row.retentionDuedate =
                        row.retentionDuedate.substr(0, 4) +
                        "/" +
                        row.retentionDuedate.substr(4, 2) +
                        "/" +
                        row.retentionDuedate.substr(6, 2);
                    }
                    list.push({
                      // 管理番号
                      controlNumber: row.itemManageNo,
                      // 品番
                      productCd: row.itemCd,
                      // 品名
                      product: row.itemName,
                      // ロケーション
                      location: row.locationNo,
                      // 廃却指示
                      disposalInstruction: row.stockDisposal,
                      // 保管期限
                      retentionPeriod: row.retentionDuedate,
                      // 部品要求者
                      partsRequester: row.partsRequester,
                      // 備考
                      remarks: row.remarks,
                    });
                  });
                  if (count == 0) {
                    this.infoDialog.message = messsageUtil.getMessage("P-STK-102_001_C");
                    this.infoDialog.title = appConfig.DIALOG.title;
                    this.infoDialog.isOpen = true;
                    this.infoDialog.firstPageFlag = true;
                    this.infoDialog.stockInformationChangeDataFlg = false;
                    this.infoDialog.homePageFlag = false;
                  } else {
                    // 画面表示用リストに格納
                    this.inputList = list;
                    // 総件数取得
                    this.commonForm.totalCount = this.inputList.length;
                    this.btnToggle = false;
                  }
                }
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              } else {
                // 廃却更新がある場合
                if (jsonData.resIdv.sqsResult != null) {
                  // キュー状況モダールを表示
                  this.openDialogQunInfoStatus();
                } else {
                  this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                    i18n.tc("label.lbl_upload"),
                  ]);
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = false;
                  this.infoDialog.stockInformationChangeDataFlg = true;
                  this.infoDialog.homePageFlag = true;
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                }
              }
            }
            //異常時
            else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.stockInformationChangeDataFlg = false;
              this.infoDialog.homePageFlag = false;
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.stockInformationChangeDataFlg = false;
            this.infoDialog.homePageFlag = false;
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            reject(resolve);
          });
      });
    },
    /**
     * キュー状況ダイアログ表示処理
     */
    openDialogQunInfoStatus() {
      // キュー状況ダイアログデータ
      this.queInfoList = [
        {
          queInfoMessage: i18n.tc("label.lbl_messageStart"),
        },
      ];
      this.sqsBtnToggle1 = false;
      this.sqsBtnToggle2 = false;
      this.dialogQueInfoStatus = true;
      this.queInfoStatus();
    },

    // キューの進捗表示
    async queInfoStatus() {
      // 既存処理、終了
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(this.reqQueInfoStatusResult, 5000);
    },
    /**
     * キュー状況API
     */
    reqQueInfoStatusResult() {
      this.resultList = [];
      return new Promise((resolve, reject) => {
        getParameter
          .getMstQueInfo({ requestId: this.requestId })
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.queInfo.completeFlg == "1") {
                clearInterval(this.timeId);
                const requestJsonData = JSON.parse(
                  jsonData.resIdv.queInfo.queueRequestJson
                    .replace(/\\/g, "")
                    .replace(/:}/g, "}")
                    .replace(/:$/g, "")
                );
                if (requestJsonData.queInfoMessages && requestJsonData.queInfoMessages.length > 0) {
                  requestJsonData.queInfoMessages.forEach((message) => {
                    this.queInfoList.push({
                      queInfoMessage: message,
                    });
                  });
                }
                this.queInfoList.push({
                  queInfoMessage: i18n.tc("label.lbl_messageFinished"),
                });
                this.sqsBtnToggle1 = true;
                this.sqsBtnToggle2 = false;
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              }
            } else {
              return Promise.reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            clearInterval(this.timeId);
            // エラー時
            this.queInfoList.push({
              queInfoMessage: ex,
            });
            this.sqsBtnToggle1 = false;
            this.sqsBtnToggle2 = true;
            this.loadingCounter = 0;
            reject(resolve);
          });
      });
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    // 画面クリアボタン押下（ダイアログ）
    clearBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearBtn;
    },
    // 画面クリアボタン押下
    clearBtn() {
      this.inputList = [];
      this.btnToggle = true;
      this.commonForm.totalCount = 0;
      this.$refs.form.reset();
    },
    movePage(pageNm, param) {
      this.$router.push({ name: appConfig.SCREEN_ID[pageNm], params: param ?? {} });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.gap-3 > * {
  margin-left: 12px;
}

.gap-3 > *:last-child {
  margin-right: 12px;
}
#QueInfoStatusData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
