<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleAdd" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="commonInput.suppliersSelected"
                :items="commonInput.supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷元-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                :label="$t('label.lbl_arrival')"
                class="txt-single"
                persistent-hint
                v-model="commonInput.arrivalSelected"
                :items="commonInput.arrivalList"
                :hint="setSrhArrival()"
                :search-input.sync="commonInput.searchArrival"
                :rules="[rules.inputRequired]"
                @change="changeArrival"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="commonInput.inListNo"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="commonInput.arrivalScheduleDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                @change="changeDate"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="commonInput.arrivalScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div style="float: left">
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 追加ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">
                  {{ $t("btn.btn_add") }}
                </v-btn>

                <!-- 登録ボタン-->
                <v-btn id="btn-pack" class="post-btn" value="1" @click="rcvScheAdd">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addRcvSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 品番/品名-->
            <template v-slot:[`item.productCd`]="{ item }" style="padding: 0 5px">
              <div id="table-productCd">
                <CItemInputRegistable
                  v-model="item.value"
                  :itemCd.sync="item.itemCd"
                  :clientSid="commonInput.suppliersSelected"
                  :items="commonInput.productList"
                  :errorMessages="item.alertMessageProCd"
                  :date="commonInput.arrivalScheduleDate"
                />
              </div>
            </template>
            <!-- 入り数-->
            <template v-slot:[`item.productQty`]="{ item, index }">
              <div id="table-productQty">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.productQty"
                  maxlength="9"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  :errorMessages="item.alertMessageProQty"
                  @change="changeQty(item, index)"
                />
              </div>
            </template>
            <!-- 詳細情報ボタン -->
            <template v-slot:[`item.detailInfo`]="{ index, item }">
              <v-btn small @click="openDetailsAdd(index, item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px">
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          fixed-header
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>
      <!-- 詳細情報画面ポップアップ -->
      <detail-info-dialog
        :inListSubSid="detailInfo.inListSubSid"
        :clientSid="detailInfo.clientSid"
        :inScheduleDate="detailInfo.inScheduleDate"
        :itemCd="detailInfo.itemCd"
        :itemName="detailInfo.itemName"
        :itemManageNo="detailInfo.itemManageNo"
        :warehouse="detailInfo.warehouse"
        :locationSid="detailInfo.locationSid"
        :deliveryType="detailInfo.deliveryType"
        :groupName="detailInfo.groupName"
        :retentionDuedate="detailInfo.retentionDuedate"
        :amount="detailInfo.amount"
        :currencyUnits="detailInfo.currencyUnits"
        :controlNumber="detailInfo.controlNumber"
        :specificationNoticeNumber="detailInfo.specificationNoticeNumber"
        :partsRequester="detailInfo.partsRequester"
        :remarks="detailInfo.remarks"
        :isShow.sync="openDatailsAdd"
        @addDetailsInfo="addDetailsInfo"
        ref="detailInfo"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchHacListFlg="infoDialog.rcvSchHacListFlg"
        :rcvSchHacAddFlg="infoDialog.rcvSchHacAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
        :rcvSchDownloadListFlg="infoDialog.rcvSchDownloadListFlg"
        :rcvSchDownloadList="rcvSchDownloadList"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import DetailInfoDialog from "@/components/DetailInfoDialog";
import CItemInputRegistable from "@/components/CItemInputRegistable.vue";

export default {
  name: appConfig.SCREEN_ID.P_RCV_011,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CheckDialog,
    NavBar,
    DetailInfoDialog,
    CItemInputRegistable,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // 共通入力
    commonInput: {
      // 取引先
      suppliersSelected: "",
      // 取引先プルダウン
      supplierList: [],
      // 入荷元プルダウン
      arrivalSelected: "",
      // 入荷元プルダウン
      arrivalList: [],
      // 入荷No
      inListNo: "",
      // 入荷予定日付
      arrivalScheduleDateCal: "",
      arrivalScheduleDate: "",
      // 品番/品名
      productList: [],
    },
    index: "",
    // 詳細画面ポップアップ
    openDatailsAdd: false,
    // 総件数
    totalCount: 0,
    // 合計リスト
    inputSumList: [],
    loadingCounter: 0,
    // 一覧index
    editedIndex: -1,
    editedList: [],
    // メニュー
    openMenu: null,
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 初期データ
    defaultData: [],
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "100%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "productQty",
        width: "200px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "detailInfo",
        width: "180px",
        align: "center",
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "3%", align: "center" },
    ],
    inputList: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      productQty: "",
      productList: [],
      locationSid: "",
      isNew: false,
      search: "",
      inScheduleSub: {
        deliveryType: "",
        groupName: "",
        retentionDuedate: "",
        amount: "",
        currencyUnits: "",
        controlNumber: "",
        specificationNoticeNumber: "",
        partsRequester: "",
        remarks: "",
      },
    },
    focusIdx: 0,
    detailInfo: {
      warehouse: null,
      locationSid: null,
      deliveryType: null,
      groupName: null,
      retentionDuedate: null,
      amount: null,
      currencyUnits: null,
      controlNumber: null,
      specificationNoticeNumber: null,
      partsRequester: null,
      remarks: null,
    },
    inListSids: [],
    pdfUrl: null,
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.commonInput.suppliersSelected) {
        // 連続登録の場合
        this.infoDialog.isOpen = false;
        this.commonInput.inListNo = "";
        this.inputList = [];
        this.commonInput.arrivalScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST()
        );
        for (var i = 0; i < 10; i++) {
          this.defaultItem.No = i + 1;
          this.defaultItem.search = "";
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
        // 一覧のバリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
        this.inListSids = [];
      } else {
        // 初期画面
        this.commonInput.inListNo = "";
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST()
        );
        this.commonInput.arrivalScheduleDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
      }
      let sumList = [];
      sumList.push({
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: 0,
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
      // 項目初期化時に発生するバリデーションチェックをキャンセルする
      this.$refs.form.resetValidation();
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.commonInput.arrivalList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.commonInput.productHintArrival = val.displayText;
        this.commonInput.searchArrival = val.displayText;
      }
      this.commonInput.arrivalList = this.commonInput.arrivalList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.commonInput.productHintArrival = "";
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.commonInput.supplierList = result[0];
          this.commonInput.supplierList.length === 1
            ? (this.commonInput.suppliersSelected = this.commonInput.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 入荷予定登録画面：入荷予定日付を－１日
     */
    prevDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = toDate;
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },

    /**
     * 入荷予定登録画面：入荷予定日付を＋１日
     */
    nextDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * 入荷予定登録画面：与えられた日付から年月日を文字列で返却処理
     */
    dateCalc(date) {
      const dt = new Date(date);
      let year = dt.toISOString().substr(0, 4);
      let month = dt.toISOString().substr(5, 2);
      let day = dt.toISOString().substr(8, 2);
      // ISO時間
      const todayISOTime = dt.toISOString().substr(11, 2);
      if (todayISOTime >= 15) {
        // 月末
        if (
          (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10) &&
          day == 31
        ) {
          // 31日まである月
          month = Number(month) + 1;
          day = "1";
        } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 30) {
          // 30日まである月
          month = Number(month) + 1;
          day = "1";
        } else if (month == 2) {
          if (year % 4 == 0 && day == 29) {
            // 2月29日まである年
            month = Number(month) + 1;
            day = "1";
          } else if (year % 4 != 0 && day == 28) {
            // 2月28日まである年
            month = Number(month) + 1;
            day = "1";
          }
        } else if (month == 12 && day == 31) {
          //12月
          year = Number(year) + 1;
          month = "1";
          day = "1";
        } else {
          day = Number(day) + 1;
        }
        if (month < 10 && month.length != 2) {
          month = "0" + month;
        }
        if (day < 10 && day.length != 2) {
          day = "0" + day;
        }
      }
      return year + "-" + month + "-" + day;
      // return dt.toISOString().substr(0, 10);
    },

    /**
     * 入荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 入荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
      let sumList = [];
      sumList.push({
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      const suppliersSelected = this.commonInput.supplierList.find(
        (item) => item.value == this.commonInput.suppliersSelected
      );
      if (suppliersSelected) {
        return suppliersSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      const arrivalSelected = this.commonInput.arrivalList.find(
        (item) => item.value == this.commonInput.arrivalSelected
      );
      if (arrivalSelected) {
        return arrivalSelected.name;
      }
    },

    /**
     * 入荷予定数0ダイアログ、OK
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 入荷予定登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定登録画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");
      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }

      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      }

      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");
          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷予定登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = null;
      }
    },

    /**
     * 入荷予定登録画面：登録ボタン押下
     */
    rcvScheAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].alertMessageProCd = i18n.tc("");
        this.inputList[i].alertMessageProQty = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let errFlg = false;

      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        this.inputList.forEach((row) => {
          if (row.value) {
            row.productCd = row.value;
          }
        });
        let checkList = this.inputList.filter((item) => item.productCd);

        if (checkList.length === 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          errFlg = true;
        }

        for (let i = 0; i < this.inputList.length; i++) {
          // 品番の入力有無チェック
          if (!this.inputList[i].productCd && !this.inputList[i].productQty) {
            this.inputList[i].alertMessageProCd = i18n.tc("");
          } else if (!this.inputList[i].productCd) {
            this.inputList[i].alertMessageProCd = i18n.tc("check.chk_input");
          } else {
            this.inputList[i].alertMessageProCd = i18n.tc("");
          }
          // 入り数の入力有無チェック
          if (!this.inputList[i].productCd && !this.inputList[i].productQty) {
            this.inputList[i].alertMessageProQty = i18n.tc("");
          } else if (!this.inputList[i].productQty) {
            this.inputList[i].alertMessageProQty = i18n.tc("check.chk_input");
          } else {
            this.inputList[i].alertMessageProQty = i18n.tc("");
          }
        }

        for (let i = 0; i < this.inputList.length; i++) {
          if (!errFlg && this.inputList[i].alertMessageProCd === i18n.tc("check.chk_input")) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
            errFlg = true;
          }
        }

        // 明細部、入力チェック実施
        checkList.forEach((item) => {
          let checkQtyNum = Number(commonUtil.zen2han(item.productQty).replaceAll(",", ""));
          if (!errFlg && checkQtyNum === 0) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-011_001_E");
            errFlg = true;
            return;
          }
        });

        if (!errFlg) {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        }
      }
    },

    /**
     * 入荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 入荷予定登録画面：入荷予定API（POST）
     */
    addRcvSch() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        this.inListSids = [];

        // パラメータ設定
        const body = this.$httpClient.createRequestBodyConfig();
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_011;

        body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
        body.reqIdv.clientSid = this.commonInput.suppliersSelected;
        if (this.commonInput.inListNo) {
          body.reqIdv.inListNo = this.commonInput.inListNo;
        }
        body.reqIdv.inScheduleDate = dateTimeHelper.convertUTC(
          this.commonInput.arrivalScheduleDate
        );
        body.reqIdv.freeCostDiv = "01";
        body.reqIdv.processDiv = "01";
        body.reqIdv.blameDiv = "01";
        body.reqIdv.fromSid = this.commonInput.arrivalSelected;
        body.reqIdv.toSid = sessionStorage.getItem("sales_office_sid");
        body.reqIdv.businessSid = "";
        body.reqIdv.deleteFlg = 0;
        body.reqIdv.updateDatetime = dateTimeHelper.convertUTC();

        let inScheduleDtl = [];
        this.inputList.forEach((row) => {
          if (row.productCd) {
            inScheduleDtl.push({
              inListSubNo: row.No,
              itemStandardSid: row.productCd,
              itemCd: row.itemCd,
              inScheduleQuantity: row.productQty.replaceAll(",", ""),
              qualityDiv: "01",
              deleteFlg: 0,
              infoCategoryDtl: row.inScheduleSub
                ? Object.entries(row.inScheduleSub).reduce((acc, [key, value]) => {
                    acc.push({
                      subInformation: key,
                      subInformationComment:
                        key === "retentionDuedate"
                          ? value
                            ? dateTimeHelper.convertUTC(value)
                            : ""
                          : String(value ?? ""),
                    });
                    return acc;
                  }, [])
                : [],
              locationReserve: [{ locationSid: row.locationSid ?? "" }],
            });
          }
        });
        body.reqIdv.inScheduleDtl = inScheduleDtl;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_INSCHEDULES_HAC, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.inListSids.push(jsonData.resIdv.inListSid);
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                // 画面遷移ボタン
                this.infoDialog.homePageFlag = true;
                this.infoDialog.rcvSchHacListFlg = true;
                this.infoDialog.rcvSchHacAddFlg = true;
                this.infoDialog.firstPageFlag = false;
                this.infoDialog.rcvSchDownloadListFlg = true;
                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
                reject(new Error(jsonData.resCom.resComMessage));
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 予定リスト出力処理
     */
    rcvSchDownloadList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_011;
      // 取引先SID
      body.reqIdv.clientSid = this.commonInput.suppliersSelected;
      // 入荷伝票Sid
      body.reqIdv.inListSids = this.inListSids;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_IN_SCHEDULE_HAC_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ファイル名
              this.pdfUrl = jsonData.resIdv.filePath;
              // ファイルダウンロード
              location.href = this.pdfUrl;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = messsageUtil.getMessage(jsonData.resCom.resComMessageId, [
                i18n.tc("label.lbl_upload"),
              ]);
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.rcvSchHacListFlg = false;
              this.infoDialog.rcvSchHacAddFlg = false;
              this.infoDialog.rcvSchDownloadListFlg = false;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.rcvSchHacListFlg = false;
            this.infoDialog.rcvSchHacAddFlg = false;
            this.infoDialog.rcvSchDownloadListFlg = false;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.commonInput.suppliersSelected = "";
      this.commonInput.isPaid = "01";
      this.commonInput.isBlame = "02";

      // 変更検知用の設定
      this.commonInput.arrivalScheduleDateCal = "";
      // 初期化
      this.init();

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    // 詳細情報登録画面をポップアップで開く
    openDetailsAdd(index, item) {
      this.index = index;

      //詳細情報をすでに登録済か確認
      if (item.inScheduleSub.partsRequester) {
        this.detailInfo = this.inputList[index].displayList;
      } else {
        this.detailInfo = {};
      }

      this.openDatailsAdd = true;
    },

    // 詳細情報登録ボタン押下
    addDetailsInfo(value) {
      this.openDatailsAdd = false;

      this.inputList[this.index].displayList = value.infoCategoryDtl;
      this.inputList[this.index].inScheduleSub = {
        deliveryType: value.infoCategoryDtl.deliveryType,
        groupName: value.infoCategoryDtl.groupName,
        retentionDuedate: value.infoCategoryDtl.retentionDuedate,
        amount: value.infoCategoryDtl.amount,
        currencyUnits: value.infoCategoryDtl.currencyUnits,
        controlNumber: value.infoCategoryDtl.controlNumber,
        specificationNoticeNumber: value.infoCategoryDtl.specificationNoticeNumber,
        partsRequester: value.infoCategoryDtl.partsRequester,
        remarks: value.infoCategoryDtl.remarks,
      };
      this.inputList[this.index].locationSid = value.infoCategoryDtl.locationSid;
    },
  },
  computed: {},
  watch: {
    "commonInput.arrivalScheduleDateCal": {
      handler(val) {
        this.commonInput.arrivalScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    "commonInput.arrivalScheduleDate": {
      handler() {
        // 入荷予定日の形式チェック
        if (dateTimeHelper.isDisplayDateFormat(this.commonInput.arrivalScheduleDate)) {
          // 入荷予定日がyyyy/MM/dd形式の場合
          // 取引先を取得する処理
          this.getCustomInfo(this.commonInput.arrivalScheduleDate);
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    "commonInput.suppliersSelected": function () {
      // 取引先を変更する場合はクリアする
      this.commonInput.arrivalList = [];
      this.commonInput.arrivalSelected = "";
      if (this.inputList.length != 0) {
        this.inputList.splice(0, this.inputList.length);
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
      }
      this.totalCount = 0;
      let sumList = [];
      sumList.push({
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (!this.commonInput.suppliersSelected) {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                // クリア処理
                value.value = "";
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.locationSid = null;
                value.warehouseSid = null;
                value.inScheduleSub = [];
                value.displayList = [];

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.commonInput.suppliersSelected,
                    dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate),
                    val
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    // 入荷元取得イベント
    "commonInput.searchArrival": {
      handler(val) {
        if (val ?? false) {
          // 取引先が選択されてない場合、処理しない
          if (!this.commonInput.suppliersSelected) {
            return;
          }
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (val == this.commonInput.productHintArrival) {
            return;
          }
          // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
          if (val.trim().length === 0) {
            return;
          }
          // 入力桁数が定義した数異常の場合検索処理
          if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
            // 選択した会社コードを取得
            let officeCd = this.commonInput.supplierList.find(
              (element) => element.value === this.commonInput.suppliersSelected
            ).clientSid;
            const fromList = getParameter.getRelatedBizPartialMatch(
              appConfig.ISGETCLIENT.FROMTO,
              dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate),
              undefined,
              officeCd,
              val
            );

            Promise.all([fromList])
              .then((result) => {
                // 画面の初期値を設定します。
                const list = [];
                this.commonInput.arrivalList = list.concat(result[0]);
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          } else {
            this.commonInput.arrivalList.length = 0;
            this.commonInput.arrivalList = [];
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

.right {
  text-align: right;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
