<template>
  <div>
    <v-dialog :value="isShow" :max-width="600" persistent="" no-click-animation>
      <Loading :loadingCounter="loadingCounter" />
      <Loading />
      <v-form ref="newProductForm" lazy-validation>
        <v-card>
          <v-card-title id="sub-bar" class="font-weight-bold pb-4">
            <span id="lbl-screen-name">{{ $t("label.lbl_ProductAdd") }}</span>
          </v-card-title>
          <v-card-text class="pt-8">
            <v-container>
              <v-row align-content="center" class="pr-16">
                <span class="text-h6 font-weight-bold pt-1 pr-16">部品番号</span>
                <v-text-field
                  outlined
                  dense
                  v-model="popNewProductCd"
                  :label="$t('label.lbl_ProductNo')"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.isStrLen(this.popNewProductCd, 50)]"
                  clearable
                  :error-messages="alertMessageNo"
                ></v-text-field>
              </v-row>
              <v-row align-content="center" class="pr-12">
                <span class="text-h6 font-weight-bold pt-1 pr-16">部品名称</span>
                <v-text-field
                  outlined
                  dense
                  v-model="popNewProductNm"
                  :label="$t('label.lbl_ProductName')"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.isStrLen(this.popNewProductNm, 50)]"
                  maxlength="50"
                  clearable
                  :error-messages="alertMessageNm"
                  require
                ></v-text-field>
                <span class="require pl-2" style="padding-top: 20px">*</span>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" class="api-btn" @click="popNewProductClose">
              {{ $t("btn.btn_close") }}
            </v-btn>

            <v-btn color="primary" class="api-btn" @click="popNewProductSave">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :okAction="init"
    />
  </div>
</template>

<script>
import Loading from "./loading"; // ローディング画面
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import { i18n } from "@/lang/lang.js";
import commonRules from "@/mixins/CommonRules";

export default {
  name: "ItemRegisterDialog", // 表示名
  components: { Loading, SimpleDialog },
  // コンポーネント
  mixins: [commonRules],
  props: {
    clientSid: {
      type: String,
      required: true,
    },
    productSid: {
      type: String,
      default: "",
    },
    // 有効日付
    date: {
      required: false,
      type: [String],
      default: undefined,
    },
    isShow: { type: Boolean, required: true, default: true },
  },
  data: () => ({
    loadingCounter: 0,
    alertMessageNo: "",
    alertMessageNm: "",
    popNewProductCd: "",
    popNewProductNm: "",
    popNewProductSid: "",
    effectiveDate: "",
    infoDialog: {
      title: "",
      isOpen: false,
    },
  }),
  methods: {
    init() {
      this.popNewProductCd = "";
      this.popNewProductNm = "";
      this.popNewProductSid = this.productSid ?? "";
      this.effectiveDate = this.date;
    },
    popNewProductClose() {
      this.alertMessageNo = "";
      this.alertMessageNm = "";
      this.popNewProductCd = "";
      this.popNewProductNm = "";
      this.popNewProductSid = "";
      this.effectiveDate = "";
      this.infoDialog = {
        title: "",
        isOpen: false,
      };
      this.$refs.newProductForm.resetValidation();
      this.$emit("update:productSid", "");
      this.$emit("update:isShow", false);
    },
    /**
     * 入荷予定登録画面：業務利用商品API（POST）
     */
    popNewProductSave() {
      if (!this.popNewProductNm) {
        this.infoDialog.message = messsageUtil.getMessage("P-DST-002_001_E", [
          i18n.tc("label.lbl_ProductName"),
        ]);
        this.infoDialog.title = appConfig.DIALOG.confirm;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = this.$route.name;
      body.reqIdv = {
        clientSid: this.clientSid, // 取引先SID
        itemCd: this.popNewProductCd ? this.popNewProductCd : `*${dateTimeHelper.convertUTC()}*`, // 品番
        validFrom: this.effectiveDate
          ? dateTimeHelper.convertUTC(this.effectiveDate)
          : dateTimeHelper.convertUTC(), // 有効開始日時（入荷予定日）
        validTo: "2099/12/31 14:59:59.999", // 有効終了日時
        itemCategoryDiv: "02", // 品目区分
        baseUnit: "09", // 基本単位
        actualWeight: 1, // *実重量
        items40Sid: "2100000040", // *40品目SID
        itemName: this.popNewProductNm, // *品名
        itemNameShort: this.popNewProductNm.substr(0, 20), // 品名略称
        itemNameKana: "",
        deleteFlg: "0", // *削除フラグ
        serialAircraftnumberFlg: "1", //S/N有無フラグ
        dueDateFlg: "0", //期限日有無フラグ
        itemCommon: [
          {
            officeSid: sessionStorage.getItem("sales_office_sid"), // *営業所SID
            validFrom: this.effectiveDate
              ? dateTimeHelper.convertUTC(this.effectiveDate)
              : dateTimeHelper.convertUTC(), // *有効開始日時（入荷予定日）
            validTo: "2099/12/31 14:59:59.999", // *有効終了日時
            inboundUnit: "09", // *入庫単位
            inboundStevedoragePrice: null, // 入庫荷役料
            outboundUnit: "09", // *出庫単位
            outboundUnitQuantity: 1, // *出庫単位数
            outboundStevedoragePrice: null, // 出庫荷役料
            storageUnit: "09", // 保管単位
            storageUnitQuantity: 1, // 保管単位数
            storageChargePrice: null, // 保管荷役料
            inventorycountUnit: "09", //棚卸単位
            inventorycountUnitQuantity: 1, // 棚卸単位数
            inboundUnitQuantity: 1, // *入庫単位数
            lotAircraftnumberFlg: "0", // ロット有無フラグ
            jigCd: null, // 治具CD
            pieceCapacityQuantity: 1, // 個体容量
            pieceCapacityUnit: "", // 個体容量単位
            finalOutboundDate: null, // 最終出庫日
            finalInboundDate: null, // 最終入庫日
            // TODO : 後で単価GrはAPI取得する様に修正
            unitPriceGroup: "HACT-TAN", // 単価Gr
            janCd: "", // JANCD
            itfCd: "", // ITFCD
            itemSurrogateCd: "", // 品番代替CD
            itemPropertiesDiv: "1", // 製品特性区分
            inPackingQuantity: null, // 入荷荷姿数
            orderingPointFlg: 0, // 発注点管理フラグ
            deleteFlg: "0", // *削除フラグ
            subItem: [],
          },
        ],
      };

      // console.log("popNewProductSave() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMSEARCH, body, appConfig.APP_CONFIG)
          .then((response) => {
            console.debug("addCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // this.popNewProductNm = jsonData.resIdv.ItemsBizUse.itemName;
              // this.popNewProductCd = jsonData.resIdv.ItemsBizUse.itemCd;
              this.popNewProductSid = jsonData.resIdv.item.itemStandardSid;
              this.$emit("update:productSid", this.popNewProductSid);

              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            this.$emit("update:isShow", false);
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  watch: {
    // productSidが更新された場合
    productSid(newValue) {
      if (!newValue) {
        return;
      }
      this.popNewProductSid = this.productSid ?? "";
      this.popNewProductCd = "";
      this.popNewProductNm = "";
    },
    // 部品番号をAPIから取得した場合
    popNewProductSid() {
      this.$emit("update:productSid", this.popNewProductSid);
      this.popNewProductCd = "";
      this.popNewProductNm = "";
    },
  },
};
</script>

<style>
#sub-bar {
  color: #000;
  background: #dee6f3;
}
</style>
