<template>
  <div class="bg-white">
    <Loading :loadingCounter="loadingCounter" />
    <v-navigation-drawer absolute temporary v-model="openMenu">
      <sideMenu></sideMenu>
    </v-navigation-drawer>
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockInquiry" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先 -->
            <c-client-input v-model="searchForm.suppliersSelected" required width="250px" />
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番/品名 -->
            <c-item-input
              v-model="searchForm.itemSelected"
              :clientSid="searchForm.suppliersSelected"
              width="250px"
            />
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 所属 -->
            <c-belong-input v-model="searchForm.affiliationSelected" width="250px" />
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 部品要求者 -->
            <c-user-input
              v-model="searchForm.partsRequesterSelected"
              :label="$t('label.lbl_partsRequester')"
              width="250px"
            />
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- グループ -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                outlined
                dense
                maxlength="200"
                v-model="searchForm.groupName"
                :label="$t('label.lbl_groupName')"
                :rules="[rules.isStrLen(searchForm.groupName, 200)]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 管理番号 -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                outlined
                dense
                maxlength="20"
                v-model="searchForm.managementNumber"
                :label="$t('label.lbl_managementNoJP')"
                :rules="[rules.isStrLen(searchForm.managementNumber, 20)]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 納品種別 -->
            <div class="search-autocomplete" style="width: 250px">
              <v-autocomplete
                dense
                v-model="searchForm.deliveryTypeSelected"
                :items="searchForm.deliveryTypeList"
                :label="$t('label.lbl_deliveryType')"
                :hint="
                  commonUtil.findPropertyByValue(
                    searchForm.deliveryTypeList,
                    searchForm.deliveryTypeSelected
                  )
                "
                class="txt-single"
                persistent-hint
                :rules="[]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row class="d-flex justify-space-between gap-3 align-center">
            <!--ボタン領域-->
            <div>
              <!-- 検索ボタン -->
              <v-btn
                color="primary"
                id="get-search"
                class="api-btn ml-0"
                @click="getItemList('get-search')"
                >{{ $t("btn.btn_search") }}</v-btn
              >
              <!-- 画面クリアボタン -->
              <v-btn color="primary" class="api-btn" @click="clearBtnDialog()">{{
                $t("btn.btn_clear")
              }}</v-btn>
            </div>
            <div>
              <!-- データ出力ボタン -->
              <v-btn color="primary" id="btn-search" class="api-btn" @click="outPutClick()">{{
                $t("btn.btn_outPutData")
              }}</v-btn>
            </div>
          </v-row>
          <v-row class="d-flex justify-space-between gap-3 align-center pt-2">
            <div>
              <!--検索結果件数表示-->
              <span>{{ $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number") }}</span>
            </div>
            <div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="630px"
          ref="dataTable"
        >
          <!-- 所属 -->
          <template v-slot:[`item.officialName`]="{ item }">
            <div v-if="item.officialName.length > 8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 150px" v-bind="attrs" v-on="on">
                    {{ item.officialName }}
                  </div>
                </template>
                <span>{{ item.officialName }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.officialName }}</span>
            </div>
          </template>
          <!-- 部品要求者 -->
          <template v-slot:[`item.partsRequester`]="{ item }">
            <div v-if="item.partsRequester.length > 7">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 110px" v-bind="attrs" v-on="on">
                    {{ item.partsRequester }}
                  </div>
                </template>
                <span>{{ item.partsRequester }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.partsRequester }}</span>
            </div>
          </template>
          <!-- 品番・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <span>{{ item.productCnCd }}</span>
                <span>{{ item.productName }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.productCnCd }}</span
              ><br />
              <span>{{ item.productName }}</span>
            </div>
          </template>
          <!-- グループ -->
          <template v-slot:[`item.groupName`]="{ item }">
            <div v-if="item.groupName != null && item.groupName.length > 4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 100px" v-bind="attrs" v-on="on">
                    {{ item.groupName }}
                  </div>
                </template>
                <span>{{ item.groupName }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.groupName }}</span>
            </div>
          </template>
          <!-- ロケーション -->
          <template v-slot:[`item.locationNo`]="{ item }">
            <div v-if="item.locationNo != null && item.locationNo.length > 13">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 100px" v-bind="attrs" v-on="on">
                    {{ item.locationNo }}
                  </div>
                </template>
                <span>{{ item.locationNo }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.locationNo }}</span>
            </div>
          </template>
          <!-- 備考 -->
          <template v-slot:[`item.remarks`]="{ item }">
            <div v-if="item.remarks != null && item.remarks.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="ellipsis-tooltip" style="max-width: 250px" v-bind="attrs" v-on="on">
                    {{ item.remarks }}
                  </div>
                </template>
                <span>{{ item.remarks }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ item.remarks }}</span>
            </div>
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openDetailsAdd(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
    <!-- 詳細情報画面ポップアップ -->
    <detail-info-dialog-for-stock-parts
      :itemStandardSid="detailInfo.itemStandardSid"
      :clientSidForDetailInfo="detailInfo.clientSid"
      :itemCd="detailInfo.itemCd"
      :itemName="detailInfo.itemName"
      :itemManageNo="detailInfo.itemManageNo"
      :warehouse="detailInfo.warehouse"
      :locationSid="detailInfo.locationSid"
      :locationNo="detailInfo.locationNo"
      :deliveryType="detailInfo.deliveryType"
      :groupName="detailInfo.groupName"
      :retentionDuedate="detailInfo.retentionDuedate"
      :amount="detailInfo.amount"
      :currencyUnits="detailInfo.currencyUnits"
      :controlNumber="detailInfo.controlNumber"
      :specificationNoticeNumber="detailInfo.specificationNoticeNumber"
      :partsRequester="detailInfo.partsRequester"
      :partsRequesterUserId="detailInfo.partsRequesterUserId"
      :partsRequesterUserName="detailInfo.partsRequesterUserName"
      :remarks="detailInfo.remarks"
      :stocks="detailInfo.stocks"
      :isShow.sync="openDatailsAdd"
      :isExclutionFlg="exclutionLockInfo.isExclutionFlg"
      :clientSidForExclutionLockInfo="exclutionLockInfo.clientSid"
      :stockInfoUpdateDateTime="detailInfo.stockInfoUpdateDateTime"
      :lockedUserName="exclutionLockInfo.lockedUserName"
      :item11="exclutionLockInfo.item11"
      :item14="exclutionLockInfo.item14"
      :item15="exclutionLockInfo.item15"
      :updateDatetime="exclutionLockInfo.updateDatetime"
      @closeDetailsInfo="closeDetailsInfo"
      @addDetailsInfo="addDetailsInfo"
      ref="detailInfo"
    />
  </div>
</template>

<script>
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import DetailInfoDialogForStockParts from "@/components/DetailInfoDialogForStockParts";
// import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_STK_103,
  components: {
    SimpleDialog,
    ConfirmDialog,
    DetailInfoDialogForStockParts,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    searchForm: {
      // 取引先
      suppliersSelected: "",

      // 品番/品名
      itemSelected: "",

      // 所属
      affiliationSelected: "",

      // 部品要求者
      partsRequesterSelected: "",

      // グループ
      groupName: "",

      // 管理番号
      managementNumber: "",

      // 納品種別
      deliveryTypeSelected: "",
      deliveryTypeList: [
        {
          text: "再入庫以外",
          value: "02",
          name: "再入庫以外",
        },
        {
          text: "再入庫",
          value: "01",
          name: "再入庫",
        },
      ],
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // 在庫詳細
    detailInfo: {
      warehouse: null,
      locationSid: null,
      locationNo: null,
      deliveryType: null,
      groupName: null,
      retentionDuedate: null,
      amount: null,
      currencyUnits: null,
      controlNumber: null,
      specificationNoticeNumber: null,
      partsRequester: null,
      partsRequesterUserId: null,
      partsRequesterUserName: null,
      remarks: null,
    },
    // 排他制御
    exclutionLockInfo: {
      clientSid: null,
      item11: null,
      item14: null,
      item15: null,
      functionId: null,
      updateDatetime: null,
    },
    // 詳細画面ポップアップ
    openDatailsAdd: false,
    registerDialog: {
      isOpen: false,
    },
    u_100: false,
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: false,
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // メニュー
    openMenu: null,
    dateMenu: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // テーブルフッター項目
    inputList: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    // 排他制御フラグ
    isExclutionFlg: false,
    // テーブルヘッダ項目
    headerItems: [
      // 所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "officialName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "partsRequester",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // グループ
      {
        text: i18n.tc("label.lbl_groupName"),
        value: "groupName",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "locationNo",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 管理番号
      {
        text: i18n.tc("label.lbl_managementNoJP"),
        value: "manageNumber",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "stocks",
        align: "center",
        width: "8%",
        sortable: false,
      },
      // 保管期限
      {
        text: i18n.tc("label.lbl_retentionDuedate"),
        value: "retentionPeriod",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarks"),
        value: "remarks",
        align: "left",
        width: "15%",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        align: "center",
        width: "10%",
        sortable: false,
      },
    ],
  }),
  methods: {
    clearBtnDialog() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearBtn;
    },
    clearBtn() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      this.pageCount = 0;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    // ページング
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.getItemList("get-search");
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.getItemList("get-search");
    },

    // 初期化
    init() {
      this.itemsPerPage = 100;
      this.itemsPerPageBtn = "100";

      if (
        this.$route.params.searchFilter &&
        Object.keys(this.$route.params.searchFilter).length !== 0
      ) {
        // 画面遷移前の検索条件をセット
        Object.assign(this, this.$route.params.searchFilter);
        this.getItemList("get-search");
      }

      this.searchForm.suppliersSelected = "";
      this.searchForm.itemSelected = "";
      this.searchForm.affiliationSelected = "";
      this.searchForm.partsRequesterSelected = "";
      this.searchForm.groupName = "";
      this.searchForm.managementNumber = "";
      this.searchForm.deliveryTypeSelected = "";
      this.searchForm.deliveryTypeList = [
        {
          text: "再入庫以外",
          value: "02",
          name: "再入庫以外",
        },
        {
          text: "再入庫",
          value: "01",
          name: "再入庫",
        },
      ];
      this.inputList = [];
      this.totalCount = 0;
    },
    // 検索ボタン押下後、商品マスタ一覧を取得
    getItemList(val) {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createGetApiRequestConfig();

        // ローディング画面表示ON
        this.loadingCounter++;

        // 取引先
        if (this.searchForm.suppliersSelected) {
          config.params.clientSid = this.searchForm.suppliersSelected;
        }

        // 品番/品名
        if (this.searchForm.itemSelected) {
          config.params.itemStandardSid = this.searchForm.itemSelected;
        }

        // 所属
        if (this.searchForm.affiliationSelected) {
          config.params.officialSid = this.searchForm.affiliationSelected;
        }

        // 部品要求者
        if (this.searchForm.partsRequesterSelected) {
          config.params.partsRequester = this.searchForm.partsRequesterSelected;
        }

        // グループ
        if (this.searchForm.groupName != "") {
          config.params.groupName = this.searchForm.groupName;
        }

        // 管理番号
        if (this.searchForm.managementNumber != "") {
          config.params.managementNumber = this.searchForm.managementNumber;
        }

        // 納品種別
        if (this.searchForm.deliveryTypeSelected) {
          config.params.deliveryType = this.searchForm.deliveryTypeSelected;
        }
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // console.debug("apigetStock() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_STOCKS_PARTS, config)
            .then((response) => {
              // console.debug("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                this.stockList = jsonData.resIdv.stockParts;

                for (let i = 0; i < this.stockList.length; i++) {
                  list.push({
                    productCnCd: this.stockList[i].itemCd.substring(0, 50).trim(), // 品番
                    productCnCdShow: this.stockList[i].itemCd, // 品番
                    itemStandardSid: this.stockList[i].itemStandardSid, // 商品標準SID
                    userid: this.stockList[i].userId, // ユーザID
                    productName: this.stockList[i].itemName, // 品名
                    officialName:
                      this.stockList[i].officialName == null ? "" : this.stockList[i].officialName, // 所属
                    partsRequester:
                      this.stockList[i].partsRequester == null
                        ? ""
                        : this.stockList[i].partsRequester, // 部品要求者
                    groupName:
                      this.stockList[i].groupName == null ? "" : this.stockList[i].groupName, // グループ名
                    locationNo:
                      this.stockList[i].locationNo == null ? "" : this.stockList[i].locationNo, // ロケーションNo
                    locationSid:
                      this.stockList[i].locationSid == null ? "" : this.stockList[i].locationSid, // ロケーションSid
                    retentionPeriod:
                      this.stockList[i].retentionPeriod == null ||
                      this.stockList[i].retentionPeriod == ""
                        ? ""
                        : dateTimeHelper
                            .convertUTC2JST(this.stockList[i].retentionPeriod)
                            .split(" ")[0], // 保管期限
                    manageNumber: this.stockList[i].itemManageNo, // 管理番号
                    remarks: this.stockList[i].remarks == null ? "" : this.stockList[i].remarks, // 備考
                    stocks: commonUtil.formatToCurrency(Number(this.stockList[i].stocks)),
                  });
                }
                this.inputList = list;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter--;
            });
        });
      }
    },

    // 在庫情報出力画面：データ出力ボタン押下時
    outPutClick() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter++;

        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_101;
        // 取引先
        if (this.searchForm.suppliersSelected) {
          config.reqIdv.clientSid = this.searchForm.suppliersSelected;
        }

        // 品番/品名
        if (this.searchForm.itemSelected) {
          config.reqIdv.itemStandardSid = this.searchForm.itemSelected;
        }

        // 所属
        if (this.searchForm.affiliationSelected) {
          config.reqIdv.officialSid = this.searchForm.affiliationSelected;
        }

        // 部品要求者
        if (this.searchForm.partsRequesterSelected) {
          config.reqIdv.partsRequester = this.searchForm.partsRequesterSelected;
        }

        // グループ
        if (this.searchForm.groupName != "") {
          config.reqIdv.groupName = this.searchForm.groupName;
        }

        // 管理番号
        if (this.searchForm.managementNumber != "") {
          config.reqIdv.managementNumber = this.searchForm.managementNumber;
        }

        // 納品種別
        if (this.searchForm.deliveryTypeSelected) {
          config.reqIdv.deliveryType = this.searchForm.deliveryTypeSelected;
        }
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_STOCKLIST_PARTS_DATA_FILE,
              config,
              appConfig.APP_CONFIG
            )
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                // 返却されたurlを共通関数へ呼び出し
                commonFunction.getLink(jsonData.resIdv.filePath);
                resolve(response);
              } else {
                // エラー時(件数0件またはapiエラー時)
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter--;
            });
        });
      }
    },
    // 詳細情報登録画面をポップアップで開く
    openDetailsAdd(item) {
      const config = this.$httpClient.createRequestBodyConfig();

      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_101;
      // 取引先
      config.reqIdv.clientSid = this.searchForm.suppliersSelected;

      // 商品標準SID
      config.reqIdv.itemStandardSid = item.itemStandardSid;

      // 管理番号
      config.reqIdv.itemManageNo = item.manageNumber;

      // 操作画面
      config.reqIdv.screenId = appConfig.SCREEN_ID.P_STK_101;
      // 排他制御
      config.reqIdv.exclution = {
        clientSid: this.searchForm.suppliersSelected,
        procDiv: "01",
        item11: item.locationSid,
        item14: item.productCnCdShow,
        item15: "",
      };

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCKS_DETAILS_PARTS, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.exclutionLockList = jsonData.resIdv.exclutionLockInfo;
              if (this.isEmpty(this.exclutionLockList)) {
                this.exclutionLockInfo = {
                  clientSid: this.searchForm.suppliersSelected,
                  item11:
                    jsonData.resIdv.stockDetailParts[0].locationSid == null
                      ? ""
                      : jsonData.resIdv.stockDetailParts[0].locationSid,
                  item14: item.productCnCd,
                  item15: "",
                  updateDatetime: config.reqCom.reqComExecTimestamp,
                  functionId: appConfig.SCREEN_ID.P_STK_101,
                  isExclutionFlg: false,
                };
              } else {
                this.exclutionLockInfo = {
                  clientSid: jsonData.resIdv.exclutionLockInfo.clientSid,
                  item11: jsonData.resIdv.exclutionLockInfo.item11,
                  item14: jsonData.resIdv.exclutionLockInfo.item14,
                  item15: jsonData.resIdv.exclutionLockInfo.item15,
                  updateDatetime: jsonData.resIdv.exclutionLockInfo.updateDatetime,
                  lockedUserName: jsonData.resIdv.exclutionLockInfo.lockedUserName,
                  functionId: appConfig.SCREEN_ID.P_STK_101,
                  isExclutionFlg: true,
                };
              }
              this.detailInfo = {
                itemStandardSid: jsonData.resIdv.stockDetailParts[0].itemStandardSid, // 商品標準SID
                clientSidForDetailInfo: this.searchForm.suppliersSelected, // 取引先SID
                itemCd: item.productCnCdShow, // 品番
                userid: jsonData.resIdv.stockDetailParts[0].partsRequester, // ユーザID
                itemName: item.productName, // 品名
                partsRequester:
                  jsonData.resIdv.stockDetailParts[0].partsRequester == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].partsRequester, // 部品要求者
                groupName:
                  jsonData.resIdv.stockDetailParts[0].groupName == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].groupName, // グループ名
                locationNo:
                  jsonData.resIdv.stockDetailParts[0].locationNo == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].locationNo, // ロケーション
                retentionDuedate:
                  jsonData.resIdv.stockDetailParts[0].retentionDuedate == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].retentionDuedate, // 保管期限
                deliveryType:
                  jsonData.resIdv.stockDetailParts[0].deliveryType == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].deliveryType, // 納品種別
                warehouse:
                  jsonData.resIdv.stockDetailParts[0].warehouseSid == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].warehouseSid, // 倉庫
                locationSid:
                  jsonData.resIdv.stockDetailParts[0].locationSid == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].locationSid, // ロケーションSid
                amount: jsonData.resIdv.stockDetailParts[0].amount, // 金額
                controlNumber:
                  jsonData.resIdv.stockDetailParts[0].controlNumber == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].controlNumber, // コントロールNo
                specificationNoticeNumber:
                  jsonData.resIdv.stockDetailParts[0].specificationNoticeNumber == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].specificationNoticeNumber, // 仕様通知No
                currencyUnits:
                  jsonData.resIdv.stockDetailParts[0].currencyUnits == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].currencyUnits, // 通貨単位
                itemManageNo: jsonData.resIdv.stockDetailParts[0].itemManageNo, // 管理番号
                remarks:
                  jsonData.resIdv.stockDetailParts[0].remarks == null
                    ? ""
                    : jsonData.resIdv.stockDetailParts[0].remarks, // 備考
                stockInfoUpdateDateTime:
                  jsonData.resIdv.stockDetailParts[0].stockInfoUpdateTime == null
                    ? ""
                    : dateTimeHelper.convertJST(
                        jsonData.resIdv.stockDetailParts[0].stockInfoUpdateTime
                      ), // 付属情報更新日時
                stocks: commonUtil.formatToCurrency(Number(item.stocks)),
              };
              this.openDatailsAdd = true;
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    closeDetailsInfo(value) {
      if (value.exclutionLockInfo.isExclutionFlg) {
        this.openDatailsAdd = false;
      } else {
        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_101;
        // 取引先
        config.reqIdv.clientSid = this.searchForm.suppliersSelected;

        // 操作画面
        config.reqIdv.functionId = appConfig.SCREEN_ID.P_STK_101;
        // 排他制御
        config.reqIdv.procDiv = "04";
        config.reqIdv.item11 = value.exclutionLockInfo.item11;
        config.reqIdv.item14 = value.exclutionLockInfo.item14;
        config.reqIdv.item15 = value.exclutionLockInfo.item15;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.EXCLUSIVE_RESERVE, config, appConfig.APP_CONFIG)
            .then((response) => {
              // // console.debug("btnSearch() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.openDatailsAdd = false;
                resolve(response);
              } else {
                // エラー時(件数0件またはapiエラー時)
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {});
        });
      }
    },
    // 項目が空かチェック
    isEmpty(value) {
      if (Object.keys(value).length != 0) {
        return false;
      }
      return true;
    },

    // 詳細情報登録ボタン押下
    addDetailsInfo(value) {
      const config = this.$httpClient.createRequestBodyConfig();

      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_101;
      // 取引先
      config.reqIdv.clientSid = this.searchForm.suppliersSelected;
      Object.assign(config.reqIdv, value);
      Object.assign(config.reqIdv, value.detailInfoUpdateData);
      // 排他制御
      config.reqIdv.screenId = appConfig.SCREEN_ID.P_STK_101;
      config.reqIdv.screenName = "はい替え実績登録（在庫一覧）（PC)";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.BIZ_STOCKS_DETAILS_UPDATE_PARTS,
            config,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              //this.openDatailsAdd = false;
              this.getItemList("get-search");
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom
                ? messsageUtil.fillMessagePadding(jsonData.resCom.resComMessage, [
                    i18n.tc("label.lbl_stockDetailInfo"),
                  ])
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex.resCom
              ? messsageUtil.fillMessagePadding(ex.resCom.resComMessage, [
                  i18n.tc("label.lbl_stockDetailInfo"),
                ])
              : ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      //画面クリア時の場合
      if (this.clearFlg) {
        return;
      }
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        //初期画面読み込み時
        if (this.initial == 0) {
          this.sortItem = "";
          this.initial = 1;
        }
        //画面クリア時の場合
        else if (this.clearFlg) {
          this.clearFlg = false;
          return;
        } else if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_007_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getItemList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

// td {
//   text-align: center;
//   // border: 1px solid rgba(0, 0, 0, 0.12);
//   border: 1px solid rgb(118, 118, 118);
// }

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}

.ellipsis-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
</style>
