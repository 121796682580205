import { appCertification } from "./AppCertification";

// コメント位置はPrettier対策
class AppConfig {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APP_CONFIG = {
    MOCK_MODE: false,
    BASE_URL: appCertification.APP_CONFIG.BASE_URL,
  };
  /**
   * APIのURLです。
   */
  API_URL = {
    /** ユーザ情報マスタ */
    MST_USERINFO: "master/user-infomations",
    /** コードマスタ */
    MST_CODE: "master/codedefinitions",
    /** 商品付属マスタ */
    MST_ITEMSINDIVI: "master/items-individual",
    /** 業務利用商品マスタ(GET/POST) */
    MST_ITEMBIZ: "master/items-bizuse",
    /** 業務利用商品マスタ2(GET) */
    MST_ITEMBIZ2: "master/items-bizuse2",
    /** 取引先営業所別在庫一覧照会画面の業務利用商品マスタ(GET) */
    MST_ITEMBIZ_FOR_STOCK_BY_CLIENT: "master/a-items-bizuse-for-stock-by-client",
    /** 業務利用関係先マスタ */
    MST_RELATEDBIZ: "master/related-parties-bizuse",
    /** 業務利用関係先マスタ(HT画面制御マスタ使用) */
    MST_RELATEDBIZ_AUTHORITY: "master/a-related-parties-bizuse_authority",
    /** 業務利用関係先マスタ部分一致検索 */
    MST_RELATEDBIZ_PARTIAL_MATCH: "master/related-parties-bizuse/partialMatch",
    /** 関係先マスタ(GET) */
    MST_RELATED: "master/related-parties",
    /** 入荷予定(POST) */
    BIZ_INSCHEDULES: "business/in-schedules",
    /** 入荷予定取得(試作部品)(GET) */
    BIZ_INSCHEDULES_HAC: "business/a-in-schedules-parts",
    /** 出荷予定(POST) */
    BIZ_OUTSCHEDULES: "business/out-schedules",
    /** 在庫照会(GET) */
    BIZ_STOCK: "business/stocks",
    /** 取引先営業所別在庫照会(GET) */
    BIZ_STOCK_BY_CLIENT: "business/a-stock-by-client",
    /** 取引先営業所別在庫詳細照会(GET) */
    BIZ_STOCK_BY_CLIENT_DTL: "business/a-stock-by-client-detail",
    /** 在庫報告書発行 */
    BIZ_STOCKREPORT: "business/stock-report",
    /**  在庫推移 */
    BIZ_STOCKTRANSITION: "business/stock-transition",
    /** 在庫推移(調達) */
    BIZ_DELIVERYSTOCKTRANSITION: "business/a-delivery-stock-transition",
    /** 在庫推移(調達)CSV出力 */
    BIZ_DELIVERYSTOCKTRANSITION_DOWNLOAD: "business/a-delivery-stock-transition-download",
    /** 在庫推移(調達)排他制御開始 */
    BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION: "business/a-delivery-stock-transition-exclusion",
    /** 在庫推移(調達)排他制御終了 */
    BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION_END: "business/a-delivery-stock-transition-exclusion-end",
    /** 在庫推移(調達) */
    BIZ_DELIVERYSTOCKTRANSITION_REFERENCEDATE: "business/a-delivery-stock-transition-date",
    /** 出荷予定明細(GET) */
    BIZ_OUTSCHEUDLES_DTL: "business/out-schedules/details",
    /** 入荷予定明細(GET) */
    BIZ_INSCHEUDLES_DTL: "business/in-schedules/details",
    /** 路線業者マスタ(GET) */
    MST_DELIVERY: "master/delivery-traders",
    /** 全社進捗 */
    BIZ_OFFICEALL_TRANSITION: "business/office-sum",
    /** 営業所 */
    BIZ_OFFICELIST_TRANSITION: "business/office-list",
    /** 取引先営業所別在庫一覧照会の営業所取得(GET) */
    BIZ_OFFICELIST_TRANSITION_FOR_STOCK_INQUERY_BY_CLIENT:
      "business/a-office-list-for-stock-by-client",
    /** 取引先 */
    BIZ_CLIENTLIST_TRANSITION: "business/client-list",
    /**  取引先営業所別在庫一覧照会の取引先取得(GET) */
    BIZ_CLIENTLIST_TRANSITION_FOR_STOCK_INQUERY_BY_CLIENT:
      "business/a-client-list-for-stock-by-client",
    /** 品目 */
    BIZ_40ITEMLIST_TRANSITION: "business/40item-list",
    /** 輸送業者取得(GET) */
    MST_DELIVERY_BIZUSE: "master/delivery-traders-bizuse",

    /** 在庫詳細（GET） */
    BIZ_STOCKDTL: "business/stocks/details",
    /** 工程実績(GET) */
    BIZ_OPEHIST: "business/opeHist",
    /** 工程実績(GET) */
    BIZ_OPEHIST_ALL: "business/opeHistAll",
    /** 工程実績(GET) */
    BIZ_OPEHIST_ALLDTL: "business/opeHistAllDtl",
    /** トレース対象検索 */
    BIZ_TRACE_SEARCH: "business/trace-search",
    /** トレース対象検索CSV(POST) */
    BIZ_TRACE_SEARCH_FILE: "business/trace-search-file",
    /** トレース展開(POST) */
    BIZ_TRACE_DEPLOYMENT: "business/trace-deployment",
    /** データ作成(入荷実績) */
    BIZ_INSCTUALSFILE: "business/in-actuals-file",
    create: "business/in-actuals-file",
    /** データ作成(出荷実績) */
    BIZ_OUTACTUALSFILE: "business/out-actuals-file",
    /** 倉庫マスタ */
    MST_WAREHOUSE: "master/warehouses",
    /** 格納可能ロケーション */
    BIZ_LOCATIONSAVA: "business/locations-available",
    /** 出庫指示サマリー(GET) */
    BIZ_WAREOUTORDERSUMMARY: "business/ware-out-orders-summary",
    /** 出庫指示一覧-部品 */
    BIZ_WAREOUTORDERSUMMARYPARTS: "business/a-ware-out-orders-summary-parts",
    /** 在庫引当 (GET) */
    BIZ_STOCKALLOCATION: "business/stocks-allocation",
    /** 在庫引当 (POST) */
    stocksAllocationP: "business/stocks-Allocation",
    /** 引当検索 (GET) */
    BIZ_ALLOCATIONSEARCH: "business/allocation-search",
    /** 送り状発行(GET) */
    BIZ_OUTINVOICESHIP: "business/out-invoice-shipment",
    /** 入荷実績一覧(GET) */
    BIZ_INACTUALS: "business/in-actuals",
    /** 入荷実績差異(GET) */
    BIZ_INDIFFERENC: "business/in-difference",
    /** ユーザーマスタ */
    MST_USER: "master/users",
    /** 業務利用ユーザーマスタ */
    MST_USERBIZ: "master/users-bizuse",
    /** ユーザマスタ取込（POST） */
    MST_USERFILE: "master/user-file",
    /** ユーザマスタ登録 */
    MST_USERFILE_CREATE: "master/user-file-create",
    /** ユーザマスタ一括削除 */
    MST_USERFILE_DELETE: "master/user-file-delete",
    /** 商品マスタ取込（POST） */
    MST_ITEMSFILE_TMP: "master/a-items-file-tmp",
    /** 商品マスタ取込チェック（POST） */
    MST_ITEMSFILE_CHECK: "master/a-items-file-check",
    /** 商品マスタ登録 */
    MST_ITEMSFILE_CREATE: "master/a-items-file-create",
    /** 商品マスタ一括削除 */
    MST_ITEMSFILE_DELETE: "master/a-items-file-delete",
    /** 倉庫・ロケーションマスタ取込（POST） */
    MST_WAREHOUSE_LOCATIONFILE_TMP: "master/a-warehouse-location-file-tmp",
    /** 倉庫・ロケーションマスタ取込チェック（POST） */
    MST_WAREHOUSE_LOCATIONFILE_CHECK: "master/a-warehouse-location-file-check",
    /** 倉庫・ロケーションマスタ登録 */
    MST_WAREHOUSE_LOCATIONFILE_CREATE: "master/a-warehouse-location-file-create",
    /** 倉庫・ロケーションマスタ一括削除 */
    MST_WAREHOUSELOCATIONFILE_DELETE: "master/a-warehouse-location-file-delete",
    /** 関係先マスタ取込（POST） */
    MST_RELATED_PARTIESFILE_TMP: "master/a-related-parties-file-tmp",
    /** 関係先マスタ取込チェック（POST） */
    MST_RELATED_PARTIESFILE_CHECK: "master/a-related-parties-file-check",
    /** 関係先マスタ登録 */
    MST_RELATED_PARTIESFILE_CREATE: "master/a-related-parties-file-create",
    /** 関係先マスタ一括削除 */
    MST_RELATED_PARTIESFILE_DELETE: "master/a-related-parties-file-delete",
    /** 請求先マスタ取込（POST） */
    MST_BUSINESS_INVOICE_ITEM_TMP: "master/a-business-invoice-item-file-tmp",
    /** 請求先マスタチェック（POST） */
    MST_BUSINESS_INVOICE_ITEM_CHECK: "master/a-business-invoice-item-file-check",
    /** 請求先マスタ登録 */
    MST_BUSINESS_INVOICE_ITEM_CREATE: "master/a-business-invoice-item-file-create",
    /** 請求先マスタ一括削除 */
    MST_BUSINESS_INVOICE_ITEM_DELETE: "master/a-business-invoice-item-file-delete",
    /** 単価Grマスタ取込 (POST) */
    MST_UNITPRICE_GROUPFILE_TMP: "master/a-unitprice-group-file-tmp",
    /** 単価Grマスタ取込チェック (POST) */
    MST_UNITPRICE_GROUPFILE_CHECK: "master/a-unitprice-group-file-check",
    /** 単価Grマスタ登録 */
    MST_UNITPRICE_GROUPFILE_CREATE: "master/a-unitprice-group-file-create",
    /** 単価Grマスタ一括削除 */
    MST_UNITPRICE_GROUPFILE_DELETE: "master/a-unitprice-group-file-delete",
    /** ログインQR発行 */
    BIZ_LOGINQR: "business/login-qr-code",
    /** ロケーションマスタ */
    MST_LOCATION: "master/locations",
    /** 業務利用ロケーションマスタ */
    MST_LOCATIONBIZ: "master/locations-bizuse",
    /** 棚卸用ロケーションマスタ */
    MST_CNT_LOCATIONBIZ: "master/locations-stockCheck",
    /** 在庫引当結果取消 */
    BIZ_STOCKALLOCATION_DELETE: "business/stocks-allocation-cancel",
    /** 在庫引当結果取消(部品) */
    BIZ_STOCKALLOCATION_DELETEPARTS: "business/a-stocks-allocation-cancel-parts",
    /** はい替え（GET） */
    BIZ_LOCATION_MOVE: "business/locationMove",
    /** はい替え対象 */
    BIZ_LOCATION_TARGET: "business/location-move-target",
    /** はい替え実績（指示あり:PC用）（POST） */
    BIZ_LOCATIONMOVE_ACHIEVE: "business/location-move-actual-list-pc",
    /** はい替え実績（指示なし:PC用）（POST） */
    BIZ_LOCATIONMOVE_ACHIEVE_WITHOUT_INS: "business/location-move-actual-pc",
    /** はい替え実績（GET） */
    BIZ_LOCATIONMOVE_ADDACHIEVE: "business/location-move-list",
    /** 出庫指示 */
    BIZ_WAREOUTORDER: "business/ware-out-orders",
    /** 出庫指示(部品) */
    BIZ_WAREOUTORDERPARTS: "business/a-ware-out-orders-parts",
    /** 入庫実績一覧（GET） */
    BIZ_WAREINACTUALS: "business/ware-in-actuals",
    /** 棚卸計画（GET） */
    BIZ_STOCKCHECKPLAN: "business/stock-check-plan",
    /** 予定実績対比（GET） */
    BIZ_PLANACTUALDIFF: "business/plan-actual-diff",
    /** 空き容量探索 */
    BIZ_AVAILABLESPACE: "business/available-space",
    /**月締め処理の登録 */
    BILL_REGISTRATION: "business/period-stock-entry",
    /**月締め処理の解除 */
    BILL_RESCISSION: "business/period-stock-delete",
    /** 期在庫WK登録 */
    BILL_PERIODSTOCKWKENTRY: "business/a-period-stock-wk-entry",
    /** 請求内訳書発行 */
    BILL_INVOICEBREAKDOWN: "business/invoice-breakdown-report",
    /** 棚卸対象 */
    CNT_CHECKTARGET: "business/stock-check-target",
    /** 棚卸計画明細 */
    CNT_PLANDETAILS: "business/stock-check-plan-dtl",
    /** 棚卸計画登録 */
    CNT_ADDPLANS: "business/stock-check-plan",
    /** 棚卸実績登録 */
    CNT_ADDACHIEVE: "business/stock-check-result-upsert",
    /** 棚卸実績GET */
    CNT_GETACHIEVE: "business/stock-check-achievement",
    /** 棚卸実績承認 */
    CNT_APPROVALACHIEVE: "business/stock-check-approval",
    /** 棚卸リスト発行 */
    CNT_LISTISSUE: "business/stock-check-list",
    /** 棚卸完了登録 */
    CNT_COMPLETE: "business/stock-check-complete-flg",
    /** 入庫履歴 */
    BIZ_WAREINHIST: "business/ware-in-hist",
    /** 入荷履歴 */
    BIZ_INHIST: "business/in-hist",
    /** 郵便番号検索 */
    MST_BIZ_POST_CODES: "master/a-post-codes",
    /** 関係先マスタ検索（登録） */
    MST_RELATED_PARTY_ADD: "master/a-related-party-add",
    /** 関係先マスタ検索 */
    MST_RELATED_PARTY_LIST: "master/a-related-party-list",
    /** 倉庫マスタ登録 */
    MST_ADDWAREHOUSE: "master/warehouses",
    /** 商品マスタ一覧 */
    MST_ITEMSEARCH: "master/items",
    /** 倉庫 */
    MST_WAREHOUSEBIZ_USESEARCH: "master/warehouses-bizuse",
    /** 倉庫マスタ */
    MST_WAREHOUSLIST: "master/warehouses",
    /** 商品マスタ詳細 */
    MST_ITEMSEARCH_DETAILS: "master/items-dtl",
    /** 40品目マスタ */
    MST_40ITEMSEARCH: "master/items40",
    /** 商品マスタ登録（POST） */
    MST_ITEMADD: "master/items",
    /** 同一品番商品検索 */
    MST_SAME_ITEM_CODE_ITEMSEARCH: "master/a-same-item-code-items",
    /** 出庫履歴 */
    BIZ_WAREOUTHIST: "business/ware-out-hist",
    /** 出荷履歴 */
    BIZ_OUTHIST: "business/out-hist",
    /** 荷札発行 */
    BIZ_TAGLABEL: "business/taglabel",
    /** 実績登録 */
    BIZ_ACTUALS: "business/actuals",
    /** 実績登録（試作） */
    BIZ_ACTUALS_HAC: "business/a-actuals-parts",
    /** 出荷指示 */
    BIZ_SHIPPINGORDERS: "business/shipping-orderlist",
    /** 出荷予定(差分取込) */
    BIZ_COMPAREIMPORT: "business/a-out-schedules-file/import",
    /** 出荷予定(差分チェック) */
    BIZ_COMPARECHECK: "business/a-out-schedules-file/compare",
    /** 入庫指示 */
    BIZ_INORDERS: "business/in-orders",
    /** 入出荷実績報告 */
    BIZ_ACTUALREPORT: "business/actualReport",
    /** 入庫実績 */
    BIZ_INORDERSACHIEVEMWNTHEADER: "business/in-orders-achievement-header",
    /** 入出庫履歴 */
    BIZ_ENTEXTHIST: "business/ware-in-out-hist",
    /** 入出庫履歴 */
    BIZ_ENTEXTHISTDATA: "business/ware-in-out-hist-data",
    /** 入出庫履歴 */
    BIZ_ENTEXTDETAILHIST: "business/ware-in-out-hist-detail",
    /** 伝票実績（GET） */
    BIZ_RETURNAPPROVAL: "business/stock-check-achievement",
    /** 出庫実績 */
    BIZ_OUTWAREACTUALS: "business/outWareActuals",
    /** 入荷ファイル取込（仮登録POST） */
    BIZ_INSCHDULESFILE: "business/schedules-file/tmp",
    /** 入荷予定出荷予定ファイル取込（仮登録POST） */
    BIZ_INSCHDULESFILE_HAC: "business/a-schedules-file/a-tmp-parts",
    /** 入荷ファイル差分取込  */
    BIZ_DIFFINSCHDULESFILE: "business/a-in-schedules-file/import",
    /** 返品伝票一覧 */
    BIZ_RETURNS: "business/returns",
    /** 返品伝票承認 */
    BIZ_RETURNSAPPROVAL: "business/returns-approval",
    /** 入荷ファイル取込（本登録POST） */
    BIZ_INSCHDULESFILEMAIN: "business/schedules-file/main",
    /** 入荷予定出荷予定ファイル取込（本登録POST） */
    BIZ_INSCHDULESFILEMAIN_HAC: "business/a-schedules-file/a-main-parts",
    /** 入荷ファイル差分取込 登録・比較  */
    BIZ_DIFFINSCHDULESFILEMAIN: "business/a-in-schedules-file/compare",
    /** 返品伝票承認明細 */
    BIZ_RETURNSAPPROVALDETAIL: "business/returns-detail",
    /** 入出荷データ出力 */
    BIZ_ACTUALSFILE: "business/actualsFile",
    /** 治具マスタ */
    MST_JIG: "master/jig",
    /** 業務マスタ */
    MST_BUSINESS: "master/businesses",
    /** 単位マスタ */
    MST_UNIT: "master/unit",
    /** 単位CDマスタ */
    MST_UNITPRICE_CD: "master/unitprice-codes",
    /** 単位Grマスタ */
    MST_UNITPRICE_GROUP: "master/unitprice-groups",
    /** 出荷実績検索 */
    BIZ_RETURNSEARCH: "business/returns-search",
    /** お知らせ */
    BIZ_NOTICES: "business/notices",
    /** ピッキングリスト */
    BIZ_PICKINGLIST: "business/picking-list",
    /** ピッキングリスト（ホンダアクセス） */
    BIZ_PICKING_LIST_PARTS: "business/a-picking-list-parts",
    /** 請求契約一覧取得 */
    BIZ_BIL_CONTRACT: "business/bill-contract",
    /** 請求実績一覧取得 */
    BIZ_BIL_ACTUAL: "business/invoice-act-list",
    /** 請求実績詳細 */
    BIZ_BIL_ACTUAL_DETAIL: "business/invoice-act-detail",
    /** 請求実績詳細 */
    BIZ_BIL_ACTUAL_SYSTEM_UPDATE: "business/invoice-act-detail-refrash",
    /** 請求実績新規詳細 */
    BIZ_BIL_ACTUAL_NEW_DETAIL: "business/new-invoice-act-info",
    /** G業務仕様マスタ */
    MST_BIZSPEC: "master/bizspec",
    /** G請求項目マスタ */
    MST_INVOICE_ITEM: "master/invoice-items",
    /** 請求実績登録変更削除 */
    BIZ_BIL_ACTUAL_REGIST: "business/invoice-act",
    /** 棚卸対象品番取得 */
    BIZ_STOCKCHECK_ITEM: "business/stock-check-target-item",
    /** 月度初期表示時取得API */
    BIZ_BIL_INIT_MONTH: "business/period-stock-yyyy-mm",
    /** ギネス連携登録 */
    BIZ_BIL_SEND_GINES: "business/bill-file-out",
    /** 請求明細書発行 */
    BIZ_BIL_INVOICE_DETAILS_REPORT: "business/invoice-details-report",
    /** ユーザー更新 */
    MST_USER_UPDATE: "master/users",
    /** ユーザーQR作成 */
    BIZ_USER_CREATE_QR: "business/user_qr",
    /** 路線伝票の出荷取得 */
    ROUTE_SLIP_OUTS_CHEDULES: "business/out-schedules-for-route-slip",
    /** 路線伝票フォーマット一覧取得 */
    ROUTE_SLIP_FORMAT_LIST: "master/format-match-no-list",
    /** 路線伝票CSV */
    ROUTE_SLIP_CSV: "business/route-slip/csv",
    /** 路線伝票明細登録・更新 */
    ROUTE_SLIP_DETAILS_REGIT: "business/route-slip/additional-info",
    /** 路線伝票発行 */
    ROUTE_SLIP_ISSUING_SLIPS: "business/route-slip/issuing-slips",
    /** 路線伝票フォーマット明細 */
    ROUTE_SLIP_FORMAT: "business/route-slip-format",
    /** 在庫情報変更指示登録（検索GET） */
    BIZ_STOCKDTL_LOT: "business/stocks/details/lot",
    /** 路線伝票フォーマット明細 */
    BIZ_STOCK_UPDATE_INSTRUCTION_LIST: "business/stock-change-orders",
    /** 在庫情報変更指示登録（登録POST） */
    BIZ_STOCK_INSTRUCTION_ADD: "business/stock-state-change",
    /** 在庫情報変更CSVファイルダウンロード */
    BIZ_STOCK_UPDATE_INSTRUCTION_LIST_FILE_DOWNLOAD: "business/a-stock-change-orders-file-download",
    /** 在庫一覧出力 */
    STOCK_SLIP_FORMAT: "business/stocks-data-file",
    /** 取引先営業所別在庫一覧出力 */
    STOCK_BY_CLIENT_SLIP_FORMAT: "business/a-stocks-by-client-data-file",
    /** 在庫推移出力 */
    STOCK_TRANSITION_FORMAT: "business/inventory-data-file",
    /** 入荷差異確認 */
    STOCK_CONSIGNOR_COMPARE: "business/a-stocks/consignor/compare",
    /** 入荷差異確認 */
    STOCK_CONSIGNOR_COMPARE_DOWNLOAD: "business/a-stocks/consignor/compare/download",
    /** 入荷差異確認CSV出力 */
    STOCK_STOCK_CONSIGNOR_COMPARE_CSVOUTPUT: "business/a-stocks/consignor/compare/download",
    /** 入荷予定一覧(QRリスト出力) */
    BIZ_IN_SCHEDULE_LIST: "business/in-schedule-list",
    /** 入荷予定リストダウンロードAPI */
    BIZ_IN_SCHEDULE_HAC_LIST: "business/a-in-schedule-list-parts",
    /** 画面権限一覧取得 */
    MST_ROLES_LIST: "master/roleInc",
    /** 全社進捗 */
    BIZ_WIDE_TRANSITION: "business/wide-transition",
    /** 不良・廃棄一覧取得 */
    DEFECTIVE_DISCARDED_LIST: "business/defective-list",
    /** 不良・廃棄アップロード */
    DEFECTIVE_DISCARDED_UPLOAD: "business/defective-upload",
    /** 不良・廃棄アップロード: 受領区分を更新API実行 */
    DEFECTIVE_REPORT_GET_DIV_UPDATE: "business/defective-init-report-get-div",
    /** 不良・廃棄内容明細取得 */
    DEFECTIVE_DISCARDED_DETAILS_GET: "business/defective-contents",
    /** 不良・廃棄内容明細登録・更新・削除 */
    DEFECTIVE_DISCARDED_DETAILS_REGIT: "business/defective",
    /** 不良・廃棄内容明細：検査項目内容取得 */
    MST_TEST_OFFICE: "master/inspection-office",
    /** 不良・廃棄内容明細：工程マスタ取得 */
    MST_INSPECTION_OFFICE: "master/proc",
    /** 不良・廃棄内容明細：不良報告書出力 */
    EXCEL_DEFECTREPORT: "business/download-excel-defectReport",
    /** 不良・廃棄アップロード */
    DEFECTIVE_DISCARDED_DOWNLOAD: "business/defective-download",
    /** 不良・廃棄内容明細登録(モーダル使用) */
    DEFECTIVE_DISCARDED_INSERT_FOR_DIALOG: "business/defective-single-upsert",
    /** 在庫情報変更指示一覧（承認POST） */
    BIZ_STOCK_DEFECT_APPROVAL: "business/stock-defect-approval",
    /** 認証情報取得 */
    MST_AUTH_INFO: "master/a-auth-info",
    /** 業務排他API */
    EXCLUSIVE_RESERVE: "business/a-exclusive-reserve",
    /** 権限一覧 */
    MST_ROLELIST: "master/a-role",
    /**  ユーザー削除 */
    MST_USER_DELETE: "master/a-user-delete",
    /** 機能権限管理 */
    MST_FUNCTION_PERMISSIONS: "master/a-function-permissions",
    /** 号車登録 */
    BIZ_TRANSPORT_VEHICLE: "business/a-transport-vehicle",
    /** 都道府県 */
    MST_PREFECTURE: "master/a-prefecture",
    /** 個別引当登録 (対象検索) */
    BIZ_STOCKALLOCATION_SEARCH: "business/a-stocks-allocation-search",
    /** 搬入計画一覧参照API */
    BIZ_DELIVERY_INSTRUCTIONS_SEARCH: "business/a-pl-delivery-instructions-search",
    /** 業務利用ユーザマスタ参照API */
    BIZ_DELIVERY_USER_PRODUCT: "master/a-user-manufacturer-bizuse",
    /** 業務利用ユーザ品番マスタ参照API */
    BIZ_DELIVERY_USER_PRODUCT_CD: "master/a-user-items-bizuse",
    /** 業務利用ユーザ品番マスタ参照API */
    BIZ_DELIVERY_USER_ITEM_CD: "master/a-user-item1",
    /** 業務利用ユーザ基本品番マスタ参照API */
    BIZ_USER_ITEM_BASE_CD: "master/a-user-items-base-cd",
    /**  ユーザ基本マスタ参照API */
    BIZ_GET_USER_ITEM: "master/a-user-items",
    /**  ユーザーメーカーリンクマスタ参照API */
    BIZ_GET_USER_MAKER_LINK: "master/a-user-maker-link",
    /**  ユーザーメーカーリンクマスタ削除API */
    BIZ_USER_MAKER_LINK_DELETE: "master/a-user-maker-link-delete",
    /**  ユーザーメーカーリンクマスタ登録・更新API */
    BIZ_USER_MAKER_LINK_REGIT: "master/a-user-maker-link",
    /** 業務利用メーカ工場CD取得1 */
    BIZ_MEKER_FACTORY_CD1: "master/a-delivery-sorting-bizuse/plant-cd-1",
    /** 業務利用メーカ工場CD取得2 */
    BIZ_MEKER_FACTORY_CD2: "master/a-delivery-sorting-bizuse/plant-cd-2",
    /** 業務利用搬入場所取得1API */
    BIZ_DELIVERY_PLANCECD1: "master/a-delivery-sorting-bizuse/delivery-sortingp-cd-1",
    /** 業務利用搬入場所取得2API */
    BIZ_DELIVERY_PLANCECD2: "master/a-delivery-sorting-bizuse/delivery-sortingp-cd-2",
    /** 業務利用計画コード取得1API */
    BIZ_SORTING_PLAN_CD1: "master/a-delivery-sorting-bizuse/plan-cd-1",
    /** 業務利用計画コード取得2API */
    BIZ_SORTING_PLAN_CD2: "master/a-delivery-sorting-bizuse/plan-cd-2",
    /** 業務利用搬入先工場コード取得API */
    BIZ_SORTING_CALLY_COMP_CD: "master/a-delivery-sorting-bizuse/carry-comp-cd",
    /** 搬入計画仕分マスタ検索API */
    MST_GET_DELIVERY_SORTING1: "master/a-get-delivery-sorting1-info",
    /** 搬入計画仕分マスタ2検索API */
    MST_GET_DELIVERY_SORTING2: "master/a-get-delivery-sorting2-info",
    /** 搬入計画仕分マスタ登録・修正API */
    MST_DELIVERY_SORTING_INFO: "master/a-regist-delivery-sorting-info",
    /** ユーザ品番情報取得API */
    MST_USER_ITEM_INFO_GET: "master/a-get-user-item1-info",
    /** ユーザ品番マスタ登録API */
    MST_INSERT_USER_ITEM_INFO: "master/a-regist-user-item1-info",
    /** ユーザ品番マスタ更新・削除API */
    MST_UPDATE_USER_ITEM_INFO: "master/a-update-user-item1-info",
    /** 調達-在庫推移 計算API */
    BIZ_STOCKS_TRANSITION_CALCULATION: "business/a-stocks-transition-calculation",
    /** 調達-在庫推移 検索API */
    BIZ_STOKCS_TRANSITION: "business/a-stocks-transition",
    /** 調達-在庫推移 検索詳細API */
    BIZ_STOKCS_TRANSITION_DETAIL: "business/a-stocks-transition-detail",
    /** 調達-在庫推移 CSVファイル出力API */
    BIZ_STOKCS_TRANSITION_DATA_FALE: "business/a-stocks-transition-data-file",
    /** 異常・緊急通知(件数取得のみ)参照API */
    BIZ_GET_NUMBER: "business/a-pl-urgent-notice-count",
    /** 異常・緊急通知参照API */
    BIZ_ANOMALY_NOTICE: "business/a-pl-delivery-emergency-notification-search",
    /** 搬入計画削除API */
    BIZ_DELIVERY_DELETE: "business/a-pl-delivery-instructions-delete",
    /** 搬入計画ダウンロードAPI */
    BIZ_DELIVERY_DOWNLOAD: "business/a-pl-delivery-instructions-search-download",
    /** 異常・通知更新API */
    BIZ_ANOMALY_UPDATE: "business/a-pl-emergency-notification-update",
    /**業務利用付帯品番マスタ参照API */
    BIZ_INCIDENTAL_ITEMS: "master/a-incidental-items-bizuse",
    /** 搬入計画 計画・供給先コード取得API */
    BIZ_DELIVERY_PSCD_SEARCH: "business/a-pl-delivery-plancd-supplycd-search",
    /**  搬入計画情報参照API */
    BIZ_DELIVERY_DATA_ACQUISITION: "business/a-pl-delivery-data-acquisition",
    /**  搬入計画登録API */
    BIZ_DELIVERY_INSERT: "business/a-pl-delivery-instructions-insert",
    /** 搬入計画修正API */
    BIZ_DELIVERY_UPDATE: "business/a-pl-delivery-instructions-correction",
    /** 仕分情報参照API */
    BIZ_SORTING_INFO_SEARCH: "business/a-pl-sorting-information-search",
    /** 搬入計画明細取得API */
    BIZ_DELIVERY_DETAIL_ACQUISITION: "business/a-pl-delivery-instructions-acquisition",
    /** 搬入データ取込ファイル種類取得API */
    BIZ_DELIVERY_IMPORT_FILE_TYPE_GET: "business/a-delivery-file/type",
    /** 搬入データ取込API */
    BIZ_DELIVERY_IMPORT_POST: "business/a-delivery-file/import",
    /** 搬入データアップロードAPI */
    BIZ_DELIVERY_UPLOAD_POST: "business/a-delivery-file/upload",
    /** 加工計画一覧取得API */
    BIZ_DSTSCHEDULELIST: "/business/a-dst-schedule-list",
    /** 加工計画一覧CSVダウンロードAPI */
    BIZ_DSTSCHEDULELIST_DOWNLOAD: "/business/a-dst-schedule-list-download",
    /**  工程実績登録テスト用 */
    BIZ_OPEHIST_PARTS: "business/test-actual-parts",
    /** 在庫一覧照会(GET) */
    BIZ_STOCKS_PARTS: "business/a-stocks-parts",
    /** 在庫一覧出力(POST) */
    BIZ_STOCKLIST_PARTS_DATA_FILE: "business/a-stocks-data-file-parts",
    /** 在庫詳細取得(POST) */
    BIZ_STOCKS_DETAILS_PARTS: "business/a-stocks-details-parts",
    /** 在庫詳細更新(POST) */
    BIZ_STOCKS_DETAILS_UPDATE_PARTS: "business/a-stocks-details-update-parts",
    /** 加工完成指示作成対象一覧 */
    DST_PROCESS_INSTRUCTION_TARGET_LIST: "business/a-dst-process-instruction-target-list",
    /** 加工完成指示作成登録 */
    DST_PROCESS_INSTRUCTION_REGIST: "/business/a-dst-process-instruction-regist",
    /** 加工完成指示作成状況確認 */
    DST_PROCESS_INSTRUCTION_SURVEILLANCE: "business/a-dst-process-instruction-surveillance",
    /** 加工引当結果取得 */
    DST_ALLOCATION_RESULT: "business/a-dst-allocation-result",
    /** 加工完成指示取消対象一覧 */
    DST_PROCESS_INSTRUCTION_CANCEL_TARGET_LIST:
      "/business/a-dst-process-instruction-cancel-target-list",
    /** 加工完成指示取消 */
    DST_PROCESS_INSTRUCTION_CANCEL: "/business/a-dst-process-instruction-cancel",
    /** 完成指示取消登録 */
    DST_PROCESS_INSTRUCTION_CANCEL_REGIST: "/business/a-dst-process-instruction-cancel-regist",
    /** 加工引当結果確認：資材状況取得 */
    DST_PROCESS_INSTRUCTION_MAINTENANCE_STOCK:
      "/business/a-dst-process-instruction-maintenance-stock",
    /** 加工計画登録・修正削除 */
    DST_PROCESS_PLAN_ADD: "/business/a-dst-schedule-list-maintenance",
    /** 加工計画登録：資材取得 */
    DST_MATERIAL_LIST: "/business/a-dst-material-list",
    /** 加工計画修正削除：明細取得 */
    DST_LIST_DETAIL: "/business/a-dst-list-detail",
    /** 加工計画進捗 */
    DST_PLAN_ACTUAL_DIFF: "business/a-dst-plan-actual-diff",
    /** 工程別実績CSVダウンロード */
    DST_PLAN_ACTUAL_DIFF_CSV_DOWNLOAD: "business/a-dst-plan-actual-diff-download-csv",
    /** 加工計画進捗詳細 */
    DST_PLAN_ACTUAL_DIFF_DETAIL: "business/a-dst-plan-actual-diff-detail",
    /** 加工計画進捗検査実績詳細 */
    DST_PLAN_ACTUAL_DIFF_TEST_DETAIL: "business/a-dst-plan-actual-diff-test-detail",
    /** 資材発注点参照 */
    DST_MATERIALS_ORDER_POINT_DISP: "business/a-dst-materials-order-point-disp",
    /** 資材発注点登録 */
    DST_MATERIALS_ORDER_POINT_UPDATE: "business/a-dst-materials-order-point-update",
    /** 加工計画工程別在庫確認 */
    DST_INVENTORY_PROCESS_CONFIRM_LIST: "business/a-dst-inventory-process-confirm-list",
    /** 加工計画工程別在庫確認 */
    DST_ORDER_POINT_WARNING_LIST_DOWNLOAD: "business/a-order-point-warning-list-download",
    /** 加工計画工程別在庫詳細確認 */
    DST_INVENTORY_PROCESS_CONFIRM_DETAIL_LIST:
      "business/a-dst-inventory-process-confirm-detail-list",
    /** 営業所別モジュール登録状況取得API */
    INI_OFFICE_MODULE: "/init-master/a-office-module",
    /** 営業所モジュール別登録状況取得API */
    INI_OFFICE_MODULE_INFO: "/init-master/a-office-module-info",
    /** 営業所モジュール別マスタ登録状況登録削除API */
    INI_OFFICE_MODULE_ADD: "/init-master/a-office-module-master",
    /** 営業所マスタ登録状況取得API */
    INI_OFFICE_MASTER_JANAL: "/init-master/a-office-master-janal",
    /** 営業所マスタ登録状況完了更新API */
    INI_OFFICE_MASTER_COMPLETED: "/init-master/a-office-master-completed",
    /** 営業所マスタ一括削除API */
    INI_OFFICE_MASTER_DELETE: "/init-master/a-office-master-delete",
    /** 営業所マスタ登録件数更新API */
    INI_OFFICE_MASTER_RECORD_COUNT_UPDATE: "/init-master/a-office-master-record-count-update",
    /** マスタテンプレートダウンロードURL取得 */
    INI_MASTER_TEMPLETE_FILE_DOWMLOAD_URL: "/init-master/a-master-templetefile-download",
    /** QR位置情報マスタ */
    INI_QR_DEFINITIONS: "init-master/a-qrdefinitions",
    /** バーコード種類マスタ一覧 */
    INI_BCR_TYPE_LIST: "init-master/a-bcr-type",
    /** バーコード項目マスタ一覧 */
    INI_BCR_ITEM_LIST: "init-master/a-bcr-item",
    /** 工程別印刷制御マスタ */
    INI_PROC_PRINT_CTRL_LIST: "init-master/a-procprintctrl",
    /** HT画面制御マスタ */
    INI_HT_DISP_CTRL_LIST: "init-master/a-ht-disp-ctrl",
    /** 流通加工マスタ登録状況取得API */
    INI_DP_MASTER_REGIST_STATUS_LIST: "init-master/a-distribution-processing-state",
    /** 流通加工作業工程パターン取得API */
    INI_DP_PROC_PATTERN_LIST: "init-master/a-distribution-processing-proc-pattern",
    /** 流通加工作業工程パターン登録API */
    INI_DP_PROC_PATTERN_ADD: "init-master/a-distribution-processing-proc-pattern-create",
    /** 流通加工作業工程パターン登録API */
    INI_DP_PROC_PATTERN_UPDATE: "init-master/a-distribution-processing-proc-pattern-update",
    /** 作業手順メンテナンス通過工程パターン取得API */
    INI_DP_WORK_SEQ_PROC_PATTER: "init-master/a-dp-work-seq-proc-patter",
    /** 作業手順取得API */
    INIT_DP_WORK_SEQ: "init-master/a-dp-work-seq",
    /** 同一作業手順品番取得API */
    INIT_DP_SAME_WORK: "init-master/a-dp-same-work-seq-item",
    /** 工程作業手順マスタ登録API */
    INIT_DP_WORK_SEQ_INSERT: "init-master/a-dp-work-seq",
    /** 画像アップロードAPI */
    INIT_PROC_SEQ_IMAGE_UPLOAD: "init-master/a-proc-work-seq-image-upload",
    /** 検査項目メンテナンス通過工程パターン取得API */
    INI_DP_TEST_ITEM_PROC_PATTERN: "init-master/a-dp-test-item-proc-pattern",
    /** 同一帳票Gr品番取得API */
    INIT_DP_SAME_LIST_GROUP_ITEM: "init-master/a-dp-same-list-group-item",
    /** 検査項目属性マスタ */
    INIT_TEST_PROPERTY: "init-master/a-test-property",
    /** 検査項目マスタ */
    INIT_TEST_ITEM: "init-master/a-test-item",
    /** 営業所別検査項目一覧取得API */
    INIT_DP_TEST_OFFICE: "init-master/a-dp-test-office",
    /** 営業所別検査項目一覧登録API */
    INIT_DP_TEST_OFFICE_INSERT: "init-master/a-dp-test-office",
    /** キュー管理情報 */
    BIZ_QUE_INFO: "business/a-que-info",
    /** 入荷ファイル取込（仮登録POST） */
    BIZ_: "business/schedules-file/tmp",
    /** 配車一覧参照API */
    BIZ_DISPATCH_LIST: "business/a-dispatch-list",
    /** 配車計画区分更新API */
    BIZ_DISPATCH_PLAN_UPD: "business/a-dispatch-plan-update",
    /** 未配車貨物情報取得API */
    BIZ_UNDISPATCHED_FREIGHT_SEARCH: "business/a-undispatched-freight-search",
    /** 乗務員情報取得API */
    BIZ_DRIVER_SEARCH: "business/a-driver-search",
    /** 配車計画登録API */
    BIZ_DISPATCH_PLAN: "business/a-dispatch-plan",
    /** 配車計画削除API */
    BIZ_DISPATCH_PLAN_DELETE: "business/a-dispatch-plan-delete",
    /** 拠点マスタ取得API */
    MST_BIZ_BASE_SEARCH: "master/a-base-search",
    /** 取引先マスタ参照API */
    MST_CUSTOMERS_SEARCH: "master/a-customers-search",
    /** コードマスタ参照API */
    MST_CODE_SEARCH: "master/a-code-search",
    /** 業務マスタ参照API */
    MST_OPERATION_SEARCH: "master/a-operation-search",
    /** 所属マスタ取得API */
    MST_BELONG_LIST: "master/a-belong-list",
    /** ユーザー所属更新API */
    MST_BELONG_UPDATE: "master/a-belong",
    /** ユーザーマスタ取得API */
    MST_USERS_SEARCH: "master/users-search",
    /** 受注登録API */
    BIZ_TRANSPORT_INSERT: "business/a-transport-orders",
    /** 受注修正削除API */
    BIZ_TRANSPORT_UPDATE: "business/a-update-transport-orders",
    /** 受注一覧API */
    BIZ_TRANSPORT_SEARCH: "business/a-transport-orders",
    /** 受注明細API */
    BIZ_TRANSPORT_DETAIL_SEARCH: "business/a-transport-order-details",
    /** 荷札発行API */
    BIZ_TRANSPORT_TAGLABEL: "business/a-taglabel",
    /** 送り状発行API */
    BIZ_TRANSPORT_INVOICE: "business/a-invoice",
    /** 集配状況参照（外部向け）API */
    BIZ_PICKUP_DELIVERY_STATUTS_SEARCH: "business/a-pickup-delivery-status-search",
    /** 集配状況参照（内部向け）API */
    BIZ_PICKUP_DELIVERY_STATUTS_INSIDE_SEARCH: "business/a-pickup-delivery-status-search-inside",
    /** 入出庫一覧照会API*/
    BIZ_WAREINOUT_PARTS: "business/a-ware-in-out-parts",
    /** 入出庫一覧照会データ出力API*/
    BIZ_WAREOUT_FILE_PARTS: "business/a-ware-in-out-file-parts",
    /** 在庫情報変更データ取込API */
    BIZ_STOCK_CHANGE_IMPORT: "business/a-stocks-change-file-main-parts",
    /** 出庫依頼登録の一覧取得API */
    BIZ_WARE_OUT_REQUEST_PARTS: "business/a-ware-out-request-parts",
    /** 出庫依頼の仮登録リスト登録API */
    BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_ADD_PARTS:
      "business/a-ware-out-request-tmp-create-add-parts",
    /** 出庫依頼の仮登録リスト削除API */
    BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_DELETE_PARTS:
      "business/a-ware-out-request-tmp-create-delete-parts",
    /** 出庫依頼の仮登録リスト全削除API */
    BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_ALLDELETE_PARTS:
      "business/a-ware-out-request-tmp-create-alldelete-parts",
    /** 出庫依頼の仮登録リスト取得API */
    BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_PARTS: "business/a-ware-out-request-tmp-create-get-parts",
  };
  MENU_ID = {
    /** 初期導入取引先一覧 */
    M_INI_CLIENT_LIST: "P-INI-001",
    /** モジュール選択 */
    M_INI_MODULE_SELECT: "P-INI-002",
    /** マスタ登録 */
    M_INI_MASTER_REGIST: "P-INI-003",
    /**  バーコードマスタ */
    M_INI_BARCODE_MASTER: "P-INI-004",
    /** DP流通加工マスタ登録状況一覧 */
    M_INI_DP_MASTER_REGIST_LIST: "P-INI-008",
    /** 作業手順メンテナンス */
    M_INI_WORK_PROCEDURE_MAINTENANCE: "P-INI-009",
    /** 検査項目メンテナンス */
    M_INI_TEST_ITEM_MAINTENANCE: "P-INI-010",
    /** ホーム */
    M_HOM: "P-MENU-000",
    /** 入荷準備 */
    M_RCV_RDY: "P-MENU-001",
    /** 入荷予定登録 */
    P_RCV_SCHEDULE_ADD: "P-RCV-001",
    /** 入荷予定登録(試作部品) */
    P_RCV_SCHEDULE_HAC_ADD: "P-RCV-011",
    /** 入荷予定データ取込 */
    P_RCV_SCHEDULE_DATA: "P-RCV-002",
    /** 入荷予定データ取込(試作部品) */
    P_RCV_SCHEDULE_HAC_DATA: "P-RCV-101",
    /** 入荷予定差分取込 */
    P_RCV_DIFFERENCE_DATA: "P-RCV-007",
    /** 入荷予定データ取込エラー確認 */
    P_RCV_SCHEDULE_DATA_ERROR: "P-RCV-010",
    /** 入荷予定一覧 */
    P_RCV_SCHEDULE_LIST: "P-RCV-003",
    /** 入荷予定一覧(試作部品) */
    P_RCV_SCHEDULE_HAC_LIST: "P-RCV-102",
    /** 入荷予定修正削除 */
    P_RCV_SCHEDULE_UPDATE: "P-RCV-004",
    /** 入荷予定修正削除(試作部品) */
    P_RCV_SCHEDULE_HAC_UPDATE: "P-RCV-103",
    /** 入荷実績登録 */
    P_RCV_ACTUAL_ADD: "P-RCV-006",
    /** 入荷実績登録(試作部品) */
    P_RCV_ACTUAL_HAC_ADD: "P-RCV-111",
    /** 入荷 */
    M_RCV: "P-MENU-002",
    /** 入荷実績報告 */
    P_RCV_ACTUAL_REPORT: "P-RCV-009",
    /** 入庫 */
    M_ENT: "P-MENU-003",
    /** 入庫実績登録(検索) */
    P_ENT_ACTUAL_SERCH: "P-ENT-001",
    /** 入庫実績登録 */
    P_ENT_ACTUAL_ADD: "P-ENT-002",
    /** 出荷準備 */
    M_SHP_RDY: "P-MENU-004",
    /** 出荷予定登録 */
    P_SHP_SCHEDULE_ADD: "P-SHP-001",
    /** 出荷予定データ取込 */
    P_SHP_SCHEDULE_DATA: "P-SHP-002",
    /** 出荷予定差分取込 */
    P_SHP_DIFFERENCE_DATA: "P-SHP-012",
    /** 出荷予定一覧 */
    P_SHP_SCHEDULE_LIST: "P-SHP-003",
    /** 出荷予定修正削除 */
    P_SHP_SCHEDULE_UPDATE: "P-SHP-004",
    /** 路線伝票 */
    P_SHP_SLIP: "P-SHP-005",
    /** 路線伝票明細 */
    P_SHP_SLIP_DETAIL: "P-SHP-007",
    /** 出荷予定CSV取込エラー確認 */
    P_SHP_ACTUAL_IMPORT_ERROR: "P-SHP-008",
    /** フォーマット別追加情報入力 */
    P_SHP_ADDITIONAL_INFOMATION_BY_FORMAT: "P-SHP-010",
    /** 流通加工 */
    M_TMP: "P-MENU-900",
    /** 流通加工計画登録 */
    P_TMP_901: "P-TMP-901",
    /** 流通加工計画データ取込 */
    P_TMP_902: "P-TMP-902",
    /** 流通加工計画一覧・実績登録 */
    P_TMP_903: "P-TMP-903",
    /** 流通加工計画詳細 */
    P_TMP_904: "P-TMP-904",
    /** 流通加工計画一覧・実績登録 */
    P_TMP_905: "P-TMP-905",
    /** 加工完成指示作成 */
    P_TMP_906: "P-TMP-906",
    /** 加工引当結果確認 */
    P_TMP_907: "P-TMP-907",
    /** 加工実績登録 */
    P_TMP_908: "P-TMP-908",
    /** 加工完成指示取消 */
    P_TMP_909: "P-TMP-909",
    /** 加工計画進捗 */
    P_TMP_910: "P-TMP-910",
    /** 加工計画進捗詳細 */
    P_TMP_911: "P-TMP-911",
    /** 資材発注点登録 */
    P_TMP_912: "P-TMP-912",
    /** 加工計画工程別在庫確認 */
    P_TMP_913: "P-TMP-913",
    /** 出庫 */
    M_EXT: "P-MENU-005",
    /** 在庫引当 */
    P_STK_INVEN_ALLOC: "P-STK-001",
    /** 引当結果取り消し */
    P_STK_IVEN_ALLOC_DEL: "P-STK-002",
    /** 個別引当 */
    P_STK_IVEN_ALLOC_INDIVIDUAL: "P-STK-004",
    /** 個別引当登録 */
    P_STK_RESERVATION_REGISTER: "P-STK-005",
    /** 引当結果確認(部品) */
    P_STK_CONFIRM_RESERVATION_RESULT: "P-STK-006",
    /** 出荷 */
    M_SHP: "P-MENU-006",
    /** 出荷実績報告 */
    P_SHP_ACTUAL_REPORT: "P-SHP-009",
    /** 号車登録 */
    P_SHP_TRUCK_NUM_ADD: "P-SHP-011",
    /** 在庫 */
    M_SRG: "P-MENU-007",
    /** 在庫一覧照会 */
    P_SRG_INVEN_LIST: "P-SRG-001",
    /** 在庫報告書 */
    P_SRG_STOCK_REPORT: "P-SRG-003",
    /** 在庫推移 */
    P_SRG_STOCK_TRANSITION: "P-SRG-004",
    /** 荷主在庫比較 */
    P_STC_RECEIVING_STOCK_COMPARE: "P-SRG-005",
    /** 在庫情報変更指示一覧 */
    P_STC_STOCK_UPDATE_INSTRUCTION_LIST: "P-STC-001",
    /** 在庫情報変更指示 */
    P_STC_STOCK_UPDATE_INSTRUCTION: "P-STC-002",
    /** 在庫情報変更指示登録 */
    P_STC_STOCK_INSTRUCTION_ADD: "P-STC-003",
    /** 不良・廃棄内容一覧 */
    P_WST_DEFECTIVE_DISCARDED_LIST: "P-WST-001",
    /** 不良・廃棄内容詳細 */
    P_WST_DEFECTIVE_DISCARDED_DETAIL: "P-WST-002",
    /** 在庫情報変更データ取込 */
    P_STK_STOCK_UPDATE_DATA: "P-STK-102",
    /** 在庫一覧 */
    P_STK_STOCK_LIST_PARTS: "P-STK-103",
    /** はい替え */
    M_MOV: "P-MENU-008",
    /** はい替え指示登録（検索） */
    P_MOV_ORDER_ADD: "P-MOV-001",
    /** はい替え指示登録 */
    P_MOV_ADD_SEARCH: "P-MOV-002",
    /** はい替え指示一覧 */
    P_MOV_ORDER_LIST: "P-MOV-003",
    /** 棚卸 */
    M_CNT: "P-MENU-009",
    /** 棚卸計画登録 */
    P_CNT_PLAN_INSERT: "P-CNT-002",
    /** 棚卸計画登録（検索） */
    P_CNT_PLAN_ADD: "P-CNT-001",
    /** 棚卸計画一覧 */
    P_CNT_PLAN_LIST: "P-CNT-003",
    /** 棚卸実績承認 */
    P_CNT_ACUTAL_APPLY: "P-CNT-006",
    /** 入出庫一覧照会 */
    P_MNG_101: "P_MNG_101",
    /** 請求 */
    M_BIL: "P-MENU-010",
    /** 請求契約一覧 */
    P_BIL_CONTRACT_LIST: "P-BIL-001",
    /** 請求実績一覧 */
    P_BIL_ACTUAL_LIST: "P-BIL-002",
    /** 請求月締め処理 */
    P_BIL_CUT_OFF_MONTH_REGISTRATION: "P-BIL-003",
    /** 請求契約単価登録変更 */
    P_BIL_CONTRACT_UNIT_PRICE_REGISTRATION: "P-BIL-004",
    /** 請求実績登録変更 */
    P_BIL_ACTUAL_ADD_UPD: "P-BIL-005",
    /** 請求内訳書発行 */
    P_BIL_STATEMENT: "P-BIL-006",
    /** 返品 */
    M_RTN: "P-MENU-011",
    /** 返品伝票登録 */
    P_RTN_SLIP_ADD: "P-RTN-001",
    /** 返品伝票承認 */
    P_RTN_SLIP_APPLY: "P-RTN-003",
    /** 返品伝票一覧 */
    P_RTN_SLIP_LIST: "P-RTN-002",
    /** 管理 */
    M_MNG: "P-MENU-012",
    /** 予定実績対比 */
    P_MNG_COMP_SCHEDULE: "P-MNG-001",
    /** 入荷履歴 */
    P_MNG_RCV_HISTORY: "P-MNG-002",
    /** 入出庫履歴 */
    P_MNG_ENT_EXT_HISTORY: "P-MNG-006",
    /** ??? */
    P_MNG_OPE_HISTORY: "P-MNG-008",
    /** ロケーションバーコード */
    P_MNG_LOCATION_BARCODE: "P-MNG-099",
    /** トレース対象検索 */
    P_MNG_TRACE_SEARCH: "P-MNG-010",
    /** 入出庫一覧照会 */
    P_MNG_INOUT_HISTORYLIST: "P-MNG-101",
    /** マスタ */
    M_MST: "P-MENU-013",
    /** 倉庫マスタ一覧 */
    P_MST_WAREHOUSE_LIST: "P-MST-002",
    /** ロケーションマスタ一覧 */
    P_MST_LOCATION_LIST: "P-MST-005",
    /** ロケーションマスタ登録 */
    P_MST_LOCATION_ADD: "P-MST-003",
    /** 商品マスタ一覧 */
    P_MST_ITEM_MASTER_LIST: "P-MST-007",
    /** 商品マスタ登録 */
    P_MST_ITEM_MASTER_ADD: "P-MST-008",
    /** ユーザマスタ登録 */
    P_MST_USER_MASTER_LIST: "P-MST-012",
    /** マスタ一括取込 */
    P_MST_MASTER_FILE: "P-MST-014",
    /** 画面権限一括修正 */
    P_MST_ROLE_MASTER_BATCH_ADD_UPDATE: "P-MST-016",
    /** 関係先マスタ登録 */
    P_MST_RELATED_ADD: "P-MST-017",
    /** 関係先マスタ一覧 */
    P_MST_RELATED_LIST: "P-MST-018",
    /** 所属マスタ登録 */
    P_MST_AFFILIATION_ADD: "P-MST-019",
    /** 所属マスタ一覧 */
    P_MST_AFFILIATION_LIST: "P-MST-020",
    /** 全社進捗 */
    M_TRS: "P-MENU-014",
    /** 全社進捗 */
    P_WIDE_TRANSITION: "P-TRS-001",
    /** 調達 */
    M_PRO: "P-MENU-015",
    /** 取引先営業所別 */
    M_CLT: "P-MENU-016",
    /** 取引先別在庫一覧照会 */
    P_CLT_INVEN_LIST_BY_CLIENT: "P-CLT-001",
    /** 取引先別在庫詳細照会 */
    P_CLT_INVEN_LIST_DETAIL_BY_CLIENT: "P-CLT-002",
    /** 納入指示計画一覧 */
    P_SUP_DELIVERY_ORDER_PLAN_LIST: "P-SUP-001",
    /** 搬入指示計画登録 */
    P_SUP_DELIVERY_ORDER_PLAN_ADD: "P-SUP-002",
    /** 搬入指示計修正 */
    P_SUP_DELIVERY_ORDER_PLAN_UPDATE: "P-SUP-003",
    /** 異常通知 */
    P_SUP_DELIVERY_ORDER_PLAN_NOTICE: "P-SUP-004",
    /** 搬入計画データ取込 */
    P_SUP_DELIVERY_ORDER_DATA_IMPORT: "P-SUP-005",
    /** 納入指示計画仕分マスタ一覧 */
    P_SUP_DELIVERY_ORDER_PLAN_SORTING_MASTER: "P-SUP-010",
    /** ユーザー品番マスタ一覧 */
    P_SUP_USER_PRODUCT_MASTER_LIST: "P-SUP-012",
    /** ユーザー品番登録 */
    P_SUP_USER_PRODUCT_MASTER_ADD: "P-SUP-013",
    /** ユーザーメーカーリンクマスタ一覧 */
    P_SUP_USER_MAKER_LINK_MASTER_LIST: "P-SUP-015",
    /** ユーザーメーカーリンクマス登録 */
    P_SUP_USER_MAKER_LINK_MASTER_Add: "P-SUP-016",
    /** User_Makerリンクマスタ */
    P_SUP_USER_MAKER_LINK: "P-SUP-017",
    /** 搬入指示仕分マスタ */
    P_SUP_DELIVERY_ORDER_SORTING_MASTER: "P-SUP-018",
    /** ユーザ品番マスタ */
    P_SUP_USER_ITEM_MASTER: "P-SUP-019",
    /** 在庫推移(ユーザー品番) */
    P_SUP_STOCK_TRANSITION: "P-SUP-020",
    /** 在庫推移(調達) */
    P_SUP_PROCUREMENT_STOCK_TRANSITION: "P-SUP-021",
    /** 受注 */
    M_TOD: "P-MENU-017",
    /** 受注登録 */
    P_TOD_ADD: "P-TOD-001",
    /** 受注一覧 */
    P_TOD_LIST: "P-TOD-002",
    /** 受注修正削除 */
    P_TOD_UPDATE: "P-TOD-003",
    /** 配車 */
    M_DVP: "P-MENU-018",
    /** 配車一覧参照 */
    P_DVP_LIST: "P-DVP-001",
    /** 配車登録 */
    P_DVP_ADD: "P-DVP-002",
    /** 輸送 */
    M_TRN: "P-MENU-019",
    /** 問合せ */
    P_TRN_INQUIRY: "P-TRN-002",
    /** 出庫依頼登録 */
    P_EXT_101: "P-EXT-101",
  };

  /**
   * ボタン制御用ID
   */
  BTN_AUTHORITY_ID = {
    /** 在庫引当遷移ボタン(ダイアログ) */
    P_STK_INVEN_ALLOC_RESERVE: "P-SHP-001_RESERVE",
  };

  /**
   * 画面IDです。
   */
  SCREEN_ID = {
    /** 初期導入 */
    P_INI_001: "P-INI-001",
    /** モジュール選択 */
    P_INI_002: "P-INI-002",
    /** マスタ登録 */
    P_INI_003: "P-INI-003",
    /** バーコードマスタ */
    P_INI_004: "P-INI-004",
    /** バーコード情報登録 */
    P_INI_005: "P-INI-005",
    /** ラベル印刷制御登録 */
    P_INI_006: "P-INI-006",
    /** ハンディー画面制御登録 */
    P_INI_007: "P-INI-007",
    /** DP流通加工マスタ登録状況一覧 */
    P_INI_008: "P-INI-008",
    /** 作業手順メンテナンス */
    P_INI_009: "P-INI-009",
    /** 検査項目メンテナンス */
    P_INI_010: "P-INI-010",
    /** ホーム */
    M_HOM: "P-MENU-000",
    P_COM_000: "P-COM-000",
    /** 入荷予定登録 */
    P_RCV_001: "P-RCV-001",
    /**  入荷実績報告(試作部品) */
    P_RCV_011: "P-RCV-011",
    /**  入荷予定登録（CSV取込）(試作部品) */
    P_RCV_101: "P-RCV-101",
    /** 入荷予定登録（CSV取込） */
    P_RCV_002: "P-RCV-002",
    /** 入荷予定差分取込（CSV取込） */
    P_RCV_007: "P-RCV-007",
    /** 入荷予定登録（CSV取込）エラー確認 */
    P_RCV_010: "P-RCV-010",
    /** 入荷予定一覧 */
    P_RCV_003: "P-RCV-003",
    /** 入荷予定一覧(試作部品) */
    P_RCV_102: "P-RCV-102",
    /** 入荷予定修正・削除 */
    P_RCV_004: "P-RCV-004",
    /** 入荷予定修正・削除(試作部品) */
    P_RCV_103: "P-RCV-103",
    /** 入荷実績登録 */
    P_RCV_006: "P-RCV-006",
    /** 入荷実績登録(試作部品) */
    P_RCV_111: "P-RCV-111",
    /** 入荷実績報告 */
    P_RCV_009: "P-RCV-009",
    /** 入庫実績登録（検索） */
    P_ENT_001: "P-ENT-001",
    /** 入庫実績登録 */
    P_ENT_002: "P-ENT-002",
    /** 在庫一覧照会 */
    P_SRG_001: "P-SRG-001",
    /** 在庫詳細照会 */
    P_SRG_002: "P-SRG-002",
    /** 在庫報告書出力 */
    P_SRG_003: "P-SRG-003",
    /** 在庫推移 */
    P_SRG_004: "P-SRG-004",
    /** 荷主在庫比較 */
    P_SRG_005: "P-SRG-005",
    /** 在庫情報変更指示一覧 */
    P_STC_001: "P-STC-001",
    /** 在庫情報変更指示 */
    P_STC_002: "P-STC-002",
    /** 在庫情報変更指示一覧登録 */
    P_STC_003: "P-STC-003",
    /** 不良廃棄内容一覧 */
    P_WST_001: "P-WST-001",
    /** 不良廃棄内容詳細 */
    P_WST_002: "P-WST-002",
    /** 空きスペース照会 */
    //P_SRG_002: "P-SRG-002",
    /** 出荷予定登録 */
    P_SHP_001: "P-SHP-001",
    /** 出荷予定登録（CSV取込） */
    P_SHP_002: "P-SHP-002",
    /** 出荷予定一覧 */
    P_SHP_003: "P-SHP-003",
    /** 出荷予定修正・削除 */
    P_SHP_004: "P-SHP-004",
    /** 路線伝票 */
    P_SHP_005: "P-SHP-005",
    /** 路線伝票明細 */
    P_SHP_007: "P-SHP-007",
    /** 出荷予定CSV取込エラー確認 */
    P_SHP_008: "P-SHP-008",
    /** フォーマット別追加情報入力 */
    P_SHP_010: "P-SHP-010",
    /** 出荷実績登録 */
    P_SHP_006: "P-SHP-006",
    /** 出荷実績報告 */
    P_SHP_009: "P-SHP-009",
    /** 号車登録 */
    P_SHP_011: "P-SHP-011",
    /** 出荷予定データ取込 */
    P_SHP_012: "P-SHP-012",
    /** 流通加工計画登録 */
    P_TMP_901: "P-TMP-901",
    /** 流通加工計画データ取込 */
    P_TMP_902: "P-TMP-902",
    /** 流通加工計画一覧・実績登録 */
    P_TMP_903: "P-TMP-903",
    /** 流通加工計画詳細登録 */
    P_TMP_904: "P-TMP-904",
    /** 流通加工計画実績登録 */
    P_TMP_905: "P-TMP-905",
    /** 加工完成指示作成 */
    P_TMP_906: "P-TMP-906",
    /** 加工引当結果確認 */
    P_TMP_907: "P-TMP-907",
    /** 加工実績登録 */
    P_TMP_908: "P-TMP-908",
    /** 加工完成指示取消 */
    P_TMP_909: "P-TMP-909",
    /** 加工計画進捗 */
    P_TMP_910: "P-TMP-910",
    /** 加工計画進捗詳細 */
    P_TMP_911: "P-TMP-911",
    /** 資材発注点登録 */
    P_TMP_912: "P-TMP-912",
    /** 加工計画工程別在庫確認 */
    P_TMP_913: "P-TMP-913",
    /** 一括引当 */
    P_STK_001: "P-STK-001",
    /** 引当結果取消 */
    P_STK_002: "P-STK-002",
    /** ピッキングリスト */
    P_STK_003: "P-STK-003",
    /** 個別引当 */
    P_STK_004: "P-STK-004",
    /** 個別引当登録 */
    P_STK_005: "P-STK-005",
    /** 引当結果確認(部品) */
    P_STK_006: "P-STK-006",
    /** 在庫情報変更データ取込 */
    P_STK_102: "P-STK-102",
    /** 出庫実績登録 */
    P_EXT_001: "P-EXT-001",
    /** 出庫依頼登録 */
    P_EXT_101: "P-EXT-101",
    /** 出庫依頼登録確認 */
    P_EXT_102: "P-EXT-102",
    /** はい替え指示登録（検索） */
    P_MOV_001: "P-MOV-001",
    /** はい替え指示登録 */
    P_MOV_002: "P-MOV-002",
    /** はい替え指示一覧 */
    P_MOV_003: "P-MOV-003",
    /** はい替え実績登録 */
    P_MOV_004: "P-MOV-004",
    /** 棚卸計画登録（検索） */
    P_CNT_001: "P-CNT-001",
    /** 棚卸計画登録 */
    P_CNT_002: "P-CNT-002",
    /** 棚卸計画一覧 */
    P_CNT_003: "P-CNT-003",
    /** 棚卸実績登録 */
    P_CNT_004: "P-CNT-004",
    /** 棚卸実績承認 */
    P_CNT_006: "P-CNT-006",
    /** 返品伝票登録 */
    P_RTN_001: "P-RTN-001",
    /** 返品伝票一覧 */
    P_RTN_002: "P-RTN-002",
    /** 返品伝票承認 */
    P_RTN_003: "P-RTN-003",
    /** 予定実績対比 */
    P_MNG_001: "P-MNG-001",
    /** 入荷履歴 */
    P_MNG_002: "P-MNG-002",
    /** 入庫履歴 */
    P_MNG_003: "P-MNG-003",
    /** 出庫履歴 */
    P_MNG_004: "P-MNG-004",
    /** 出荷履歴 */
    P_MNG_005: "P-MNG-005",
    /** 入出庫履歴 */
    P_MNG_006: "P-MNG-006",
    /** 入出庫詳細履歴 */
    P_MNG_007: "P-MNG-007",
    /** 工程実績一覧修正 */
    P_MNG_008: "P-MNG-008",
    /** 工程実績修正 */
    P_MNG_009: "P-MNG-009",
    /** ロケーションバーコード */
    P_MNG_099: "P-MNG-099",
    /** トレース対象検索 */
    P_MNG_010: "P-MNG-010",
    /** トレース展開 */
    P_MNG_011: "P-MNG-011",
    /** 入出庫一覧照会 */
    P_MNG_101: "P-MNG-101",
    /** 倉庫マスタ登録 */
    P_MST_001: "P-MST-001",
    /** 倉庫マスタ一覧 */
    P_MST_002: "P-MST-002",
    /** ロケーションマスタ登録 */
    P_MST_003: "P-MST-003",
    /** ロケーションマスタ登録（詳細） */
    P_MST_004: "P-MST-004",
    /** ロケーションマスタ一覧 */
    P_MST_005: "P-MST-005",
    /** ロケーションマスタ一括登録 */
    P_MST_006: "P-MST-006",
    /** 商品マスタ一覧 */
    P_MST_007: "P-MST-007",
    /** 商品マスタ登録 */
    P_MST_008: "P-MST-008",
    /** 商品詳細登録 */
    P_MST_009: "P-MST-009",
    /** 商品付属情報登録 */
    P_MST_010: "P-MST-010",
    /** 商品マスタ登録（CSV取込） */
    P_MST_011: "P-MST-011",
    /** ユーザマスタ一覧 */
    P_MST_012: "P-MST-012",
    /** ユーザマスタ編集 */
    P_MST_013: "P-MST-013",
    /** マスタ一括取込（Excel取込） */
    P_MST_014: "P-MST-014",
    /** マスタ一括取込（Excel取込）エラー確認 */
    P_MST_015: "P-MST-015",
    /** 画面権限一括修正 */
    P_MST_016: "P-MST-016",
    /** 関係先マスタ 登録 */
    P_MST_017: "P-MST-017",
    /** 関係先マスタ 一覧 */
    P_MST_018: "P-MST-018",
    /** 所属マスタ登録 */
    P_MST_019: "P-MST-019",
    /** 所属マスタ一覧 */
    P_MST_020: "P-MST-020",
    /** 進捗編集 */
    P_TRS_001: "P-TRS-001",
    /** はい替え実績（指示あり）*/
    P_MOV_005: "P-MOV-005",
    /** 請求契約一覧 */
    P_BIL_001: "P-BIL-001",
    /** 請求実績一覧 */
    P_BIL_002: "P-BIL-002",
    /** 請求月締め処理 */
    P_BIL_003: "P-BIL-003",
    /** 請求契約単価登録変更 */
    P_BIL_004: "P-BIL-004",
    /** 請求実績登録変更 */
    P_BIL_005: "P-BIL-005",
    /** 請求内訳書発行 */
    P_BIL_006: "P-BIL-006",
    /** 倉庫マスタ登録 */
    P_COM_007: "P-COM-007",
    /** ロケーションマスタ登録 */
    P_COM_009: "P-COM-009",
    /** 実績修正 */
    P_COM_006: "P-COM-006",
    /** 納入指示計画一覧 */
    P_SUP_001: "P-SUP-001",
    /** 納入指示計画登録 */
    P_SUP_002: "P-SUP-002",
    /** 納入指示計画修正 */
    P_SUP_003: "P-SUP-003",
    /** 異常通知 */
    P_SUP_004: "P-SUP-004",
    /** 搬入計画データ取込 */
    P_SUP_005: "P-SUP-005",
    /** 納入指示計画仕分マスタ登録 */
    P_SUP_010: "P-SUP-010",
    /** 納入指示計画仕分マスタ詳細 */
    P_SUP_011: "P-SUP-011",
    /** ユーザー品番マスタ一覧 */
    P_SUP_012: "P-SUP-012",
    /** ユーザー品番マスタ登録 */
    P_SUP_013: "P-SUP-013",
    /** ユーザーメーカーリンクマスタ一覧 */
    P_SUP_015: "P-SUP-015",
    /** ユーザーメーカーリンクマス登録 */
    P_SUP_016: "P-SUP-016",
    /** ユーザーメーカーリンクマスタ */
    P_SUP_017: "P-SUP-017",
    /** 搬入指示仕分マスタ */
    P_SUP_018: "P-SUP-018",
    /** ユーザ品番マスタ */
    P_SUP_019: "P-SUP-019",
    /** 在庫推移（ユーザー品番）*/
    P_SUP_020: "P-SUP-020",
    /** 在庫推移（調達）*/
    P_SUP_021: "P-SUP-021",
    /** 取引先別在庫一覧照会 */
    P_CLT_001: "P-CLT-001",
    /** 取引先別在庫詳細照会 */
    P_CLT_002: "P-CLT-002",
    /** 受注登録 */
    P_TOD_001: "P-TOD-001",
    /** 受注一覧 */
    P_TOD_002: "P-TOD-002",
    /** 受注修正削除 */
    P_TOD_003: "P-TOD-003",
    /** 配車一覧参照 */
    P_DVP_001: "P-DVP-001",
    /** 配車登録 */
    P_DVP_002: "P-DVP-002",
    /** 貨物状況問合せ(外部) */
    P_TRN_001: "P-TRN-001",
    /** 貨物状況問合せ */
    P_TRN_002: "P-TRN-002",
    /** ファイルリンクダウンロード（ピッキングリスト発行） */
    P_STK_110: "P-STK-110",
    /** 在庫一覧 */
    P_STK_103: "P-STK-103",
    /** 在庫詳細 */
    P_STK_101: "P-STK-101",
  };

  /**
   * 輸送会社区分
   */
  CARRIERCOMPDIV = {
    // 社有
    OWNED: "01",
    // グループ会社
    GROUP: "02",
    // 協力会社
    COOP: "03",
    //路線
    DELIVERY: "04",
  };
  /**
   * 業務排他処理区分
   */
  EXCLUSIVE_RESERVE_PROC_DIV = {
    // ロック取得
    LOCK: "01",
    // 強制割込み
    FORCE_INTERRUPT: "02",
    // ロック解除
    UNLOCK: "03",
    // 自身のロック情報を破棄
    DESTROY: "04",
  };
  /**
   * 更新トークン
   */
  UPDATE_TOKEN = {
    /**
     * 分
     */
    tokenTimeOut: 1439,
  };

  /**
   * ダイアログ
   */
  DIALOG = {
    /**
     * タイトル
     */
    title: "結果",
    confirm: "確認",
  };

  /**
   * コードマスタ種別
   */
  CODETYPE = {
    /**  入荷処理区分 */
    IN_PROCESS_DIV: "0101",
    /**  入荷品質区分 */
    IN_QUALITY_DIV: "0102",
    /**  入荷責任区分 */
    IN_BLAME_DIV: "0103",
    /**  入荷有償無償区分 */
    IN_FREECOST_DIV: "0104",
    /**  入荷利用コード */
    IN_REASON_DIV: "0105",
    /**  入荷登録区分 */
    IN_ENTRY_DIV: "0107",
    /**  実績登録有無 */
    ACHIEVE_CONFIRM: "0109",
    /**  比較結果 */
    COMPARISON_RESULT: "0110",
    /**  引当基準 */
    ALLOCATION_STANDARD: "0201",
    /**  引当基準 */
    PICKING_TYPE: "0202",
    /**  在庫ステータス */
    STATUS_TYPE: "0203",
    /**  在庫推移 */
    TRANSITION_TYPE: "0204",
    /**  推移プールダウン **/
    TRANSACTION_TYPE: "0204",
    /**  表示期間   **/
    DISPLAY_PERIOD: "0205",
    /**  表示期間(初期値)   **/
    DISPLAY_PERIOD_LNITIAL: "0206",
    /**  出力形式(在庫一覧照会用) **/
    DATAFORMAT_DIV: "0207",
    /**  出力パターン **/
    OUTPUTPATTERN_DIV: "0208",
    /**  単価Gr   **/
    UNITPRICE_GR: "0209",
    /**  在庫有無区分   **/
    IN_STOCK_DIV: "0212",
    /**  在庫推移CSV出力パターン **/
    CSV_OUTPUTPATTERN_DIV: "0210",
    /** 結果表示区分 */
    RESULT_DISPLAY_DIV: "0211",
    /**  出荷発行条件 */
    OUT_INVOICE_DIV: "0301",
    /**  出荷無償理由 */
    OUT_FREEREASON_DIV: "0302",
    /**  輸送会社区分 */
    OUT_CARRIER_COMP_DIV: "0303",
    /**  出荷処理区分 */
    OUT_PROCESS_DIV: "0304",
    /**  出荷品質区分 */
    OUT_QUALITY_DIV: "0305",
    /** 個人宅用 */
    OUT_INDIVIDUAL_DIV: "0306",
    /**  はい替え指示、処理区分 */
    PROCESS_DIV: "0401",
    /**  棚卸方式 */
    INVENTORY_MET: "0501",
    /**  追加条件 */
    OPTION_TYPE: "0502",
    /** 税区分 */
    BIL_TAX_DIV: "0601",
    /** 計算区分 */
    BIL_CALCULATE_DIV: "0602",
    /** 計画区分 */
    PROCESSING_DIV: "0605",
    /** CSV出力済情報 */
    PRINT_FLG: "0607",
    /**  返品理由区分 */
    RETURNREASON_DIV: "0701",
    /**  指示内容 */
    INSTRUCTION_CONTENT: "0801",
    /**  指示理由 */
    INSTRUCTION_REASON: "0802",
    /**  倉庫種別 */
    WAREHOUSE_TIPE: "1001",
    /**  仮登録プルダウン */
    DUMMY_ADD_DIV: "1002",
    /**  詳細プルダウン */
    DETAILS_DIV: "1003",
    /**  社有外傭区分 */
    companyOwned_DIV: "1004",
    /**  坪貸区分 */
    RENTCLASSIFICATION_DIV: "1005",
    /**  ロケ管理区分 */
    LOCATION_MNG_DIV: "1006",
    /**  ロケ優先度 */
    LOCATION_PRIO_DIV: "1007",
    /**  状況 */
    SITUATION_DIV: "1008",
    /**  ロット有無 */
    LOT_DIV: "1009",
    /**  対象商品区分 */
    TARGETPRODUCT_DIV: "1010",
    /**  出力形式区分 */
    OUTPUTFORMAT_DIV: "1011",
    /**  製品特性区分 */
    PRODUCTSPECIFIC_DIV: "1012",
    /**  表示区分 */
    DISPLAY_DIV: "1013",
    /** ユーザーマスタサブ検索条件 */
    USER_MASTER_SUB_CONDITION: "1014",
    /**  展開方向 */
    DEPLOY_DIRECTION: "1016",
    /** チェック上限値 **/
    CHECK_LIMIT: "1017",
    /**  表示区分(初期値) */
    DISPLAY_DIV_INITIAL: "1018",
    /**  取込種別 */
    IMPORT_CATEGORY: "1019",
    /** ロールパターン */
    ROLE_PATTERNS: "1020",
    /** 実績確認・修正 ステータス */
    WORKING_STATUS_DIV: "1022",
    /** 取込待機時間 */
    SLEEP_TIME: "1023",
    /**  棚卸 最大データ選択可能数 */
    INVENTORY_AVAILABLE_MAX_CHECK_COUNT: "1024",
    /** 職種 */
    JOB_TYPE: "1025",
    /** 方式 */
    METHOD_DIV: "1026",
    /** 発注点管理 */
    ORDER_MANAGEMENT: "1027",
    /** 承認者 */
    CODE_AUTHORIZER: "1028",
    /** 関係先区分 */
    RELATED_PARTY_DIV: "1029",
    /** 顧客区分 */
    CUSTOMER_DIV: "1030",
    /**コードマスタ:納品種別 */
    DELIVERY_TYPE_DIV: "1031",
    /**コードマスタ:通貨単位 */
    MONETARY_UNIT_DIV: "1032",
    /**コードマスタ:担当  */
    IN_CHARGE_DIV: "1033",
    /** コードマスタ:所属区分 */
    DEPARTMENT_TYPE_DIV: "1034",
    /**コードマスタ:出庫依頼有無 */
    OUT_REQUEST_DIV: "1036",
    /** 不良・廃棄一覧：ステータス */
    DEFECTIVE_STATUS_DIV: "1101",
    /** 不良・廃棄一覧：品質区分 */
    DEFECTIVE_QUALITY_DIV: "1103",
    /** 在庫情報変更指示：廃棄未承認 */
    UN_APPROVAL: "1106",
    /** 引当上限値 */
    LIMIT_RESERVATION: "1201",
    /** 搬入計画一覧：搬入時間 */
    USER_DELIVERY_TIME: "2004",
    /** 搬入計画一覧：ユーザー・メーカー選択 */
    USER_MAKER_SELECT: "2003",
    /** 搬入計画一覧：取込状態 */
    IMPORT_STATUS_CANCEL: "2002",
    /** 搬入計画一覧：取込状態 */
    IMPORT_STATUS_NORMAL: "2001",
    /** 搬入計画修正：差替区分 */
    REPLACEMENT_KBN: "2006",
    /** 搬入計画一覧：積付積込 */
    CARGOS_KBN: "2007",
    /** 搬入計画仕分マスタ：要否 */
    NECESSITY_KBN: "2008",
    /** 引当結果 */
    ALLOTMENTRESULT_DIV: "0606",
    /** 在庫区分 */
    INVENTORY_DIV: "3002",
    /** 警告 */
    ALERT_DIV: "3003",
    /** 差異有り区分 */
    DIFFERENCE_DIV: "3004",
    /** 引当単位 */
    ALLOCATION_UNIT: "3006",
    /**  推移 **/
    TRANSACTION_DIV: "3007",
    /**  搬入計画出力区分 **/
    DELIVERYORDER_OUTPUT_DIV: "3008",
    /** 引当順 */
    ALLOCATION_ORDER: "0213",
    /** TODO:DBのm921,m922に追加した計画区分コード番号を設定 */
    /** 計画区分 */
    PLAN_ON_FLG: "",
    /** 集荷方法区分 */
    PICKUP_DIV: "pickup_way_div",
    /** 配達方法区分 */
    DELIVERY_DIV: "delivery_way_div",
    /** 配車計画対象フラグ */
    IS_TARGET_FLG: "vehicle_plan_flg",
    /** 公開フラグ */
    IS_PRIVATE_FLG: "private_flg",
    /** NACCSコード */
    NACCS_CODE: "packing_nac_cd",
    /** 輸送要件 */
    TRANSPORT_REQUIREMENT_DIV: "shipping_requirements",
    /** 配車状況 */
    PICKUP_DISPATCH_STATUS_DIV: "pickup_dispatch_status",
    /** 配車状況 */
    DELIVERY_DISPATCH_STATUS_DIV: "delivery_dispatch_status",
  };
  API_ERROR_MSG_ID = {
    /**  排他DB更新失敗エラー */
    A_999_999_020_E: "A-999-999_020_E",
  };
  /**
   * 業務利用関係先マスタ
   */
  ISGETCLIENT = {
    /** 取引先 */
    CLIENT: "1",
    /** FromもしくはTo */
    FROMTO: "0",
  };
  /**
   * 定数
   */
  CONSTANT = {
    /** 一括登録可能件数 */
    BATCH: "100",
    /** ロケーション最大登録可能件数 */
    LOCATIONMAXADD: "500",
    /** 基本単位 */
    BASEUNIT: "ｍ",
    /** 容量単位 */
    LOCATIONCAPACITY: "㎥",
    /** 延床面積 */
    TOTALFLOORAREASQMETER: "㎥",
    /** 延床面積坪 */
    TOTALFLOORAREASQMETERTSUBO: "坪",
    // 床耐荷重
    FROORWITHSTANDLOAD: "t/㎡",
    // 天井高
    CELILINGHEIGHT: "m",
    /** 拠点CDの表示桁数 */
    BASE_CD_DISPLAY_LENGTH: 12,
    /** 文字コード */
    CHARSET: {
      SHIFT_JIS: "shift_jis",
      UTF_8: "utf-8",
    },
    /** 画面クリア時のメッセージID */
    CLEAR_MESSAGE: "P-COM-001_006_C",
  };
  RESCOMCODE_SUCCESS = "000";
  CNTCHARITEM_2 = 2;
  CNTCHARITEM = 3;
  CNTCHARLOCATION = 3;
  CNT_CHAR_RELATED_PARTY = 2;
  /**
   * ソート
   */
  // 入荷予定一覧
  P_RCV_003_SORT = {
    shipNo: 'in_list_no COLLATE "ja_JP.utf8" {0}',
    arrivalScheduleDate: "in_schedule_date {0}",
    isPaid: "free_cost_div {0}",
    inStock: "complete_flg {0}",
    processDivName: "process_div_name {0}",
    arrivalto: "from_name {0}",
    howtoregister: "entry_div {0}",
  };
  // 入荷実績報告
  P_RCV_009_SORT = {
    arrivalAcheiveDate: "yyyymmdd_hhmmss {0}",
    productCnCd: "item_cd {0}",
    status: "quality_div_name {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    productQty: "stock {0}",
    shipNo: 'in_list_no COLLATE "ja_JP.utf8" {0}',
  };
  // 入庫実績登録（検索）
  P_ENT_001_SORT = {
    arrivalScheduleDateJ: "in_schedule_date {0}",
    arrivalNo: 'in_list_no COLLATE "ja_JP.utf8" {0}',
    arrivalNm: "from_name {0}",
    isPaid: "free_cost_div_name {0}",
    processingdivision: "process_div_name {0}",
    exted: "complete_flg {0}",
  };
  // 在庫一覧照会
  P_SRG_001_SORT = {
    productCnCd: "item_cd {0}",
    userSupplier: "user_client_cd {0}",
    userProductCnCd: "user_item_cd {0}",
    status: "quality_div {0}",
    recieveQty: "in_schedule_cnt {0}",
    waitEnterQty: "in_cnt {0}",
    strageQty: "stocks_cnt {0}",
    reservedQty: "stocks_allocation_cnt {0}",
    leaveWarehouseQty: "warehouse_out_cnt {0}",
    leaveQty: "out_cnt {0}",
  };
  // 在庫推移
  P_SRG_004_SORT = {
    productCnCd: "item_cd {0}",
  };
  // 出荷予定一覧
  P_SHP_003_SORT = {
    shippingScheduleDateJ: "out_schedule_date {0}",
    shipNo: 'out_list_no COLLATE "ja_JP.utf8" {0}',
    isPaid: "free_cost_div_name {0}",
    involce: "invoice_print_date {0}",
    taglabel: "taglabel_print_date {0}",
    processingdivision: "process_div_name {0}",
    shippingNm: "to_name {0}",
    truck: "delivery_trader_name {0}, carrier_comp_cd {0}",
    exted: "complete_flg {0}",
    deliveryDate: "delivery_date {0}",
    transportVehicleCd: "transport_vehicle_cd {0}",
  };
  // 出荷実績報告
  P_SHP_009_SORT = {
    arrivalAcheiveDate: "yyyymmdd_hhmmss {0}",
    shipNo: 'out_list_no COLLATE "ja_JP.utf8" {0}',
    productCnCd: "item_cd {0}",
    status: "quality_div_name {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    productQty: "stock {0}",
  };
  // 在庫引当
  P_STK_001_SORT = {
    shipInsNumber: 'out_list_no COLLATE "ja_JP.utf8" {0}',
    shipInsDate: "out_schedule_date {0}",
    destination: "related_party_name {0}",
    productCnCd: "item_cd {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    shipQtyDisp: "ship_schedule_quantity {0}",
    location: "location_no {0}",
    PIC: "location_user_name {0}",
    shipCampany: "delivery_trader_name {0}, carrier_comp_cd {0}",
    reserveQtyDisp: "allocated_cnt {0}",
    unreserveQtyDisp: "allocated_failed_cnt {0}",
    unreserveQtyResult: "unreserve_qty {0}",
  };
  // 引当結果取消
  P_STK_002_SORT = {
    pickingNo: "picking_gr_sid {0}",
    shipInsDate: "out_schedule_date {0}",
    pickingNm: "picking_div_name {0}",
    status: "status {0}",
    shipCampany: "delivery_trader_name {0} , carrier_comp_cd {0}",
    destination: "base_cd {0}",
    outListNo: "out_List_No {0}",
  };
  // 個別引当
  P_STK_004_SORT = {
    shippingScheduleDateJ: "out_schedule_date {0}",
    shipNo: 'out_list_no COLLATE "ja_JP.utf8" {0}',
    isPaid: "free_cost_div_name {0}",
    deliveryDate: "delivery_date {0}",
    processingdivision: "process_div_name {0}",
    shippingNm: "to_name {0}",
    truck: "delivery_trader_name {0}, carrier_comp_cd {0}",
    transportVehicleCd: "transport_vehicle_cd {0}",
    unDetailQty: "total_detail_cnt {0}",
    unShipQty: "total_ship_cnt {0}",
  };
  // はい替え指示登録（検索）
  P_MOV_001_SORT = {
    productCnCd: "item_cd {0}",
    lotNo: 'lot_no COLLATE "ja_JP.utf8" {0}',
    locations: "warehouse_name {0}, location_no {0}",
    status: "quality_div {0}",
    stockQty: "stocks {0}",
  };
  // はい替え指示一覧
  P_MOV_003_SORT = {
    instNo: "loca_move_sid {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    locations: "from_warehouse_name {0}, from_location_no {0}",
    postWareLoc: "to_warehouse_name {0}, to_location_no {0}",
    productCnCd: "item_cd {0}",
    status: "quality_name {0}",
    isPaid: "free_cost_name {0}",
    quantity: "stock {0}",
  };
  // 返品伝票一覧
  P_RTN_002_SORT = {
    returnsNo: "returns_no {0}",
    returnsDate: "returns_date {0}",
    toName: "to_name {0}",
    outListNo: 'out_list_no COLLATE "ja_JP.utf8" {0}',
  };
  // 予定実績対比
  P_MNG_001_SORT = {
    clientName: "m301lang.related_party_name {0}",
    planRcv: "plan_rcv {0}",
    planEnt: "plan_ent {0}",
    planExt: "plan_ext {0}",
    planShp: "plan_shp {0}",
  };
  // 入荷履歴
  P_MNG_002_SORT = {
    inSchedulequantity: "in_schedule_quantity {0}",
    arrivalFrom: "from_name {0}",
    product: "item_cd {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    status: "in_status_name {0}",
    startTime: "in_end_time {0}",
    endTime: "in_end_time {0}",
  };
  // 入庫履歴
  P_MNG_003_SORT = {
    inSchedulequantity: "in_schedule_quantity {0}",
    arrivalFrom: "from_name {0}",
    product: "item_cd {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    status: "ware_in_status_name {0}",
    startTime: "ware_in_time {0}",
    endTime: "ware_in_end_time {0}",
    inLocationNo: "in_location_no {0}",
    wareLocationNo: "ware_location_no {0}",
  };
  // 出庫履歴
  P_MNG_004_SORT = {
    scheduleQty: "ship_schedule_quantity {0}",
    shippingTo: "to_name {0}",
    itemCd: "item_cd {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    status: "ware_out_status_name {0}",
    startTime: "ware_out_start_time {0}",
    endTime: "ware_out_end_time {0}",
    wareLocationNo: "ware_out_location_no {0}",
    outLocationNo: "out_location_no {0}",
  };
  // 出荷履歴
  P_MNG_005_SORT = {
    scheduleQty: "ship_schedule_quantity {0}",
    shippingTo: "to_name {0}",
    itemCd: "item_cd {0}",
    lot: 'lot_no COLLATE "ja_JP.utf8" {0}',
    status: "out_status_name {0}",
    outEndTime: "out_end_time {0}",
    outStock: "out_stock {0}",
  };
  // 商品マスタ一覧
  P_MST_007_SORT = {
    productCnCd: "item_cd {0}",
    tempoRegist: "dummy_add {0}",
    dtlInfo: "dtl_info {0}",
  };
  P_MNG_008_SORT = {
    itemManageNo: "opeHist.item_manage_no {0}",
    yyyymmddHhmmss: "yyyymmdd_hhmmss {0}",
    itemCd: "item_cd {0}",
    freeCostDivName: "free_cost_div {0}",
    qualityDivName: "quality_div {0}",
    blameDivName: "blame_div {0}",
    locationNo: "location_no {0}",
  };
  // 倉庫マスタ一覧
  P_MST_002_SORT = {
    officeName: "office_name {0}",
    warehouseCd: "warehouse_cd {0}",
    warehouseKindName: "warehouse_kind_name {0}",
    warehouseOwnerDivName: "warehouse_owner_div_name {0}",
    validityPeriod: "valid_from {0}",
    totalFloorAreaSqmetreName: "floor_space {0}",
  };
  // ロケーションマスタ一覧
  P_MST_005_SORT = {
    warehouseNameCd: "warehouse_name {0}",
    situation: "use_status {0}",
    validStartDateAndTime: "valid_from {0}",
    validEndDateAndTime: "valid_to {0}",
    location: "location_no {0}",
    locationGroup: "location_group {0}",
    capacity: "location_capacity_quantity {0}",
    locationGroupPriority: "location_priority_name {0}",
  };
  // 請求契約
  P_BIL_001_SORT = {
    bussinessNo: "business_no",
    bussinessName: "gggnak",
    shipperCutOffDate: "ggsime",
    paymentDate: "ggsiha",
  };
  // 請求実績
  P_BIL_002_SORT = {
    month: "yyyy_mm",
    billingNo: "business_no",
    billingSlipNo: 'LEFT(T800.inv_list_no, 6) COLLATE "ja_JP.utf8"',
    billingAmount: "amount_money",
  };
  //関係先マスタ
  P_MST_018_SORT = {
    supplierCd: "M001.comp_cd {0}, M301.base_cd ASC",
    baseCd: "M301.base_cd {0}, M001.comp_cd ASC",
    baseNameShort:
      'M301l.related_party_name_short COLLATE "ja_JP.utf8" {0}, M001.comp_cd ASC, M301.base_cd ASC',
  };
  // ユーザマスタ
  P_MST_012_SORT = {
    userId: "m501.user_id {0}",
    userName: "m502.user_name {0}, m501.user_id ASC",
    officialName: "m521.official_name {0}, m501.user_id ASC",
    validFrom: "m501.valid_from {0}, m501.user_id ASC",
    validTo: "m501.valid_to {0}, m501.user_id ASC",
    passwordExpirationDatetime: "m501.password_expiration_datetime {0}, m501.user_id ASC",
  };
  USER_PASSWORD = {
    addInitializeCode: "_Inipw",
  };
  // トレース対象検索(入荷予定)
  P_MNG_010_SORT_IN = {
    date: "INFO.process_date {0}, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, M301.base_cd ASC",
    productCode:
      "SUBSTRING(INFO.item_cd, 1, 50) {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, M301.base_cd ASC",
    accessoryProduct:
      "SUBSTRING(INFO.item_cd, 51, 100) {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, INFO.lot_no ASC, M301.base_cd ASC",
    lotNo:
      "INFO.lot_no {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, M301.base_cd ASC",
    quantity:
      "INFO.stock {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, M301.base_cd ASC",
    recieveShipTo:
      "M301.base_cd {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC",
  };
  // トレース対象検索(出荷予定)
  P_MNG_010_SORT_OUT = {
    date: "INFO.process_date {0}, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, MAX(INFO.delivery_cd) ASC",
    productCode:
      "SUBSTRING(INFO.item_cd, 1, 50) {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, MAX(INFO.delivery_cd) ASC",
    accessoryProduct:
      "SUBSTRING(INFO.item_cd, 51, 100) {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, INFO.lot_no ASC, MAX(INFO.delivery_cd) ASC",
    lotNo:
      "INFO.lot_no {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, MAX(INFO.delivery_cd) ASC",
    quantity:
      "INFO.stock {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC, MAX(INFO.delivery_cd) ASC",
    recieveShipTo:
      "MAX(INFO.delivery_cd) {0}, INFO.process_date ASC, SUBSTRING(INFO.item_cd, 1, 50) ASC, SUBSTRING(INFO.item_cd, 51, 100) ASC, INFO.lot_no ASC",
  };
  // 在庫情報変更指示一覧
  P_STC_001_SORT = {
    instructionContent: "out_schedule_date",
    instructionNo: "stock_state_change_no",
    instructionDate: "inst_date",
    itemCdName: "item_cd",
    totalQuantity: "stock_after",
    taglabel: "complete_flg",
  };
  // 在庫情報変更指示登録
  P_STC_003_SORT = {
    itemCdName: "item_cd",
    status: "quality_div_name",
    warehouse: "warehouse_name",
    location: "location_no",
    lot: "lot_no",
    enteringDate: "yyyymmdd",
    stockQty: "stocks",
    inputQuantity: "stocks",
  };
  // 搬入計画指示一覧
  P_SUP_001_SORT_T601 = {
    makerPlant:
      "t601.client_plant_cd {0}, t601.delivery_time ASC ,t601.delivery_place_cd ASC ,t601.plan_cd ASC ,t601.user_item_cd ASC , t601.user_item_sub_cd ASC, t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    // makerPlant: "client_plant_cd",
    deliveryTime:
      "t601.delivery_time {0}, t601.client_plant_cd  ASC , t601.delivery_place_cd ASC ,t601.plan_cd ASC , t601.user_item_cd ASC , t601.user_item_sub_cd ASC , t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    // deliveryTime: "delivery_time",
    deliveryDestinationy:
      "t601.delivery_place_cd {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC ,t601.plan_cd ASC ,t601.user_item_cd ASC ,t601.user_item_sub_cd ASC ,t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //deliveryDestinationy: "delivery_place_cd",
    deliveryPlan:
      "t601.plan_cd {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC ,t601.user_item_cd ASC ,t601.user_item_sub_cd ASC ,t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //deliveryPlan: "plan_cd",
    userProductCd:
      "t601.user_item_cd {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC , t601.plan_cd ASC ,t601.user_item_sub_cd ASC ,t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //userProductCd: "user_item_cd",
    productAccessoryCd:
      "t601.user_item_sub_cd {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC , t601.plan_cd ASC ,t601.user_item_cd ASC ,t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //productAccessoryCd: "user_item_sub_cd",
    billingSlipNo:
      "t601.out_list_no {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC , t601.plan_cd ASC ,t601.user_item_cd ASC, t601.user_item_sub_cd ASC,  t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //billingSlipNo: "out_list_no",
    instNum:
      "t601.stock {0},t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC , t601.plan_cd ASC ,t601.user_item_cd ASC , t601.user_item_sub_cd ASC, t601.out_list_no ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //instNum: "stock",
    dataIssueDate:
      "t601.data_create_date {0}, t601.data_create_Time {0}, t601.delivery_time ASC ,t601.client_plant_cd  ASC , t601.delivery_place_cd ASC , t601.plan_cd ASC ,t601.user_item_cd ASC, t601.user_item_sub_cd ASC, t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.actual_ticket_print_date ASC ",
    //dataIssueDate: "data_create_date",
    deliveryPrintDate:
      "t601.delivery_print_date {0}, t601.client_plant_cd ASC , t601.delivery_time ASC ,t601.delivery_place_cd ASC ,t601.plan_cd ASC ,t601.user_item_cd ASC , t601.user_item_sub_cd ASC, t601.out_list_no ASC, t601.stock ASC, t601.actual_ticket_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //deliveryPrintDate: "delivery_print_date",
    actualTicketPrintDate:
      "t601.actual_ticket_print_date {0}, t601.client_plant_cd ASC , t601.delivery_time ASC ,t601.delivery_place_cd ASC ,t601.plan_cd ASC ,t601.user_item_cd ASC , t601.user_item_sub_cd ASC, t601.out_list_no ASC, t601.stock ASC, t601.delivery_print_date ASC, t601.data_create_date ASC, t601.data_create_Time ASC ",
    //actualTicketPrintDate: "actualTicket_print_date",
  };
  P_SUP_001_SORT_T600 = {
    makerPlant:
      "t600.client_plant_cd {0}, t600.delivery_time ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC , t600.user_item_sub_cd ASC , t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    // makerPlant: "client_plant_cd",
    deliveryTime:
      "t600.delivery_time {0}, t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC , t600.user_item_sub_cd ASC , t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    // deliveryTime: "delivery_time",
    deliveryDestinationy:
      "t600.delivery_place_cd {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.plan_cd ASC , t600.user_item_cd ASC , t600.user_item_sub_cd ASC , t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    //deliveryDestinationy: "delivery_place_cd",
    deliveryPlan:
      "t600.plan_cd {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.user_item_cd ASC , t600.user_item_sub_cd ASC , t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    //deliveryPlan: "plan_cd",
    userProductCd:
      "t600.user_item_cd {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_sub_cd ASC , t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    //userProductCd: "user_item_cd",
    productAccessoryCd:
      "t600.user_item_sub_cd {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC ,t600.out_list_no ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    //productAccessoryCd: "user_item_sub_cd",
    billingSlipNo:
      "t600.out_list_no {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC, t600.user_item_sub_cd ASC, t600.stock ASC, t600.data_create_date ASC, t600.data_create_Time ASC  ",
    //billingSlipNo: "out_list_no",
    instNum:
      "t600.stock {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC , t600.user_item_sub_cd ASC, t600.out_list_no ASC, t600.data_create_date ASC, t600.data_create_Time ASC ",
    //instNum: "stock",
    dataIssueDate:
      "t600.data_create_date {0}, t600.data_create_Time {0}, t600.delivery_time ASC , t600.client_plant_cd  ASC , t600.delivery_place_cd ASC , t600.plan_cd ASC , t600.user_item_cd ASC, t600.user_item_sub_cd ASC, t600.out_list_no ASC, t600.stock ASC ",
    //dataIssueDate: "data_create_date",
  };
  P_SUP_019_SORT = {
    //ユーザー品番
    userProductCd:
      "SUBSTRING(M651.user_item_cd, 0 ,50) {0}, SUBSTRING(M651.user_item_cd, 51, 100) Asc, M651.user_item_base_cd Asc ",
    //付帯品番
    accessoryProduct:
      "SUBSTRING(M651.user_item_cd, 51, 100) {0}, SUBSTRING(M651.user_item_cd, 0 ,50) Asc, M651.user_item_base_cd Asc ",
    //基本品番
    userBaseProductCd:
      "SUBSTRING(M651.user_item_base_cd, 51, 100) {0}, SUBSTRING(M651.user_item_cd, 0 ,50) Asc, SUBSTRING(M651.user_item_cd, 51, 100) Asc",
    //ユーザー品名
    userProductCodeName:
      "M651.user_item_name {0}, SUBSTRING(M651.user_item_cd, 0 ,50) Asc, SUBSTRING(M651.user_item_cd, 51, 100) Asc, M651.user_item_base_cd Asc ",
  };
  // 受注一覧
  P_TOD_002_SORT = {
    supplier: "shipper_client_cd {0}",
    pickupPoint: "pickup_point_sid {0}",
    pickupScheduleDate: "pickup_schedule_date {0}",
    pickupScheduleTime: "pickup_schedule_from_date {0}",
    deliveryPoint: "delivery_point_sid {0}",
    deliveryScheduleDate: "delivery_schedule_date {0}",
    deliveryScheduleTime: "delivery_schedule_from_date {0}",
    pickupStatusDiv: "pickup_status_div {0}",
    deliveryStatusDiv: "delivery_status_div {0}",
    vehiclePlanFlg: "pickup_status_div {0}",
    privateFlg: "private_flg {0}",
    orderDate: "order_date {0}",
    weight: "total_weight {0}",
    smallLotQuantity: "small_lots_quantity {0}",
    invNumSid: "inv_num_sid {0}",
    inquiryNum: "invoice_print_date {0}",
    invoicePrintDate: "invoice_print_date {0}",
    taglabelPrintDate: "invoice_print_date {0}",
  };
  TEST_PROPERTY_CD = {
    /** テキスト */
    TEXT: "100",
    /** 合否、テキスト */
    PASS_FAIL_TEXT: "101",
    /** 日付 */
    DATE: "110",
    /** 整数 */
    INTEGER: "120",
    /** 合否、整数 */
    PASS_FAIL_INTEGER: "121",
    /** 合否、整数、小数点1 */
    PASS_FAIL_INTEGER_DECIMAL_POINT1: "130",
    /** 合否、整数、小数点2 */
    PASS_FAIL_INTEGER_DECIMAL_POINT2: "131",
    /** レ点 */
    SCORE: "140",
    /** 合否 */
    PASS_FAIL: "142",
    /** 電子印 */
    ELECTRONIC_SEAL: "900",
  };
  /**
   * 加工計画一覧 計画区分
   */
  PLANNING_SECTION_DIV = {
    // 出荷
    SHIPPING: "01",
    // 入荷
    ARRIVAL: "02",
    // 加工
    DISTRIBUTION: "03",
  };
  /**
   * 検索区分
   */
  SEARCH_DIV = {
    // 区分値1
    FIRST: "1",
    // 区分値2
    SECOND: "2",
    // 区分値3
    THIRD: "3",
  };
}

export const appConfig = new AppConfig();
