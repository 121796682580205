<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-container fluid>
      <!--ボタン領域-->
      <v-row style="margin-left: 3px">
        <div class="search-row-exeBtn pt-2">
          <!-- 詳細内容 -->
          <div style="float: left">
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_close')">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
          <div style="float: right">
            <!-- 追加ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_add')"
              :disabled="lastListFlg == true"
              >{{ $t("btn.btn_add") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- 入力フォーム -->
    <v-form ref="editList" lazy-validation>
      <!-- コンテナ -->
      <v-container fluid>
        <!-- データテーブル -->
        <!-- ヘッダーを固定(fixed-header) -->
        <!-- ヘッダーバインディング(headerItems) -->
        <!-- アイテムバインディング(inputList) -->
        <!-- 波紋エフェクトの無効化(ripple) -->
        <!-- フィルタリングの無効化(disable-filtering) -->
        <!-- ページングの無効化(disable-pagination) -->
        <!-- デフォルトフッターの非表示(hide-default-footer) -->
        <!-- 高さの指定(height) -->
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="750px"
        >
          <!-- 商品コード/品名-->
          <template v-slot:[`item.productCd`]="{ item }" style="padding: 0 5px">
            <div id="table-productCd">
              <CItemInputRegistable
                v-model="item.productCd"
                :itemCd.sync="item.itemCd"
                :itemName.sync="item.productNm"
                :clientSid="commonInput.suppliersSelected"
                :date="arrivalScheduleDate"
                :items="item.productList"
                :rules="[
                  $inputRules.isRequiredRow(headerItems, item),
                  $inputRules.isCreatedRowRequired(item.inListSubSid, item.productCd),
                  inputProductValid(item),
                ]"
              />
            </div>
          </template>
          <!-- 入り数-->
          <template v-slot:[`item.inQty`]="{ item }">
            <div id="table-inQty">
              <v-text-field
                class="input-number"
                outlined
                dense
                v-model="item.inQty"
                maxlength="9"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck, rules.limitNumLength]"
                required
              />
            </div>
          </template>
          <!-- 備考-->
          <template v-slot:[`item.remarks`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.remarks"
              class="text-box"
              persistent-hint
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- 詳細情報ボタン -->
          <template v-slot:[`item.detailInfo`]="{ item, index }">
            <v-btn small @click="openDetailsAdd(item, index)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-form>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
    <!-- 詳細情報画面ポップアップ -->
    <detail-info-dialog
      :inListSubSid="detailInfo.inListSubSid"
      :clientSid="detailInfo.clientSid"
      :inScheduleDate="detailInfo.inScheduleDate"
      :itemCd="detailInfo.itemCd"
      :itemName="detailInfo.itemName"
      :itemManageNo="detailInfo.itemManageNo"
      :warehouse="detailInfo.warehouse"
      :locationSid="detailInfo.locationSid"
      :deliveryType="detailInfo.deliveryType"
      :groupName="detailInfo.groupName"
      :retentionDuedate="detailInfo.retentionDuedate"
      :amount="detailInfo.amount ?? ''"
      :currencyUnits="detailInfo.currencyUnits"
      :controlNumber="detailInfo.controlNumber"
      :specificationNoticeNumber="detailInfo.specificationNoticeNumber"
      :partsRequester="detailInfo.partsRequester"
      :remarks="detailInfo.remarks"
      :isShow.sync="openDatailsAdd"
      @addDetailsInfo="addDetailsInfo"
    />
  </div>
</template>

<script>
import Loading from "./loading"; // ローディング画面
import { getParameter } from "../assets/scripts/js/GetParameter"; // マスタ取得
import ConfirmDialog from "./ConfirmDialog"; // 確認ダイアログ
import SimpleDialog from "./SimpleDialog"; // シンプルダイアログ
import DetailInfoDialog from "@/components/DetailInfoDialog";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper"; // 日時ヘルパー
import { i18n } from "../lang/lang.js"; // 多言語対応
import { commonUtil } from "../assets/scripts/js/CommonUtil"; // 共通ユーティリティ
import { appConfig } from "../assets/scripts/js/AppConfig"; // アプリ設定
import { messsageUtil } from "../assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import commonRules from "@/mixins/CommonRules";
import commonMixin from "@/mixins/CommonMixin";

export default {
  // 表示名
  name: "DetailListDialog",

  // コンポーネント
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    DetailInfoDialog,
  },
  mixins: [commonMixin, commonRules],
  // プロパティ
  props: {
    isMenu: { type: Boolean, default: false },
    viewDataList: { type: Array, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    listLength: { type: Number, default: 0 },
    warehouseList: { type: Array, default: null },
    suppliersNm: { type: String, default: "" },
    suppliersSelected: { type: String, default: "" },
    arrivalScheduleDate: { type: String, default: "" },
    lotPullList: { type: Array, default: null },
    screenId: { type: String, default: null },
  },

  // データ
  data: () => ({
    commonInput: {
      suppliersSelected: "",
      // 入荷元プルダウン
      arrivalList: [],
      // 納品種別
      deliveryDivSelected: "",
      // 納品種別プルダウン
      deliveryDivList: [],
      // グループ
      groupSelected: "",
      // グループプルダウン
      groupList: [],
      // 部品要求者
      partsRequesterSelected: "",
      // 部品要求者プルダウン
      partsRequesterList: [],
    },
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // APIメッセージ確認ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 確認メッセージダイアログ
    approvalDialog: {
      completeDialogMsg: false,
      completeMessage: "",
    },
    // 登録ボタン押下時確認メッセージ
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // 詳細情報ダイアログ
    detailInfo: {
      warehouse: "",
      locationSid: "",
      deliveryType: "",
      groupName: "",
      retentionDuedate: "",
      amount: null,
      currencyUnits: "",
      controlNumber: "",
      specificationNoticeNumber: "",
      partsRequester: "",
      remarks: "",
    },
    // ヘッダー項目
    headerItemsForRcv: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "100%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "inQty",
        width: "200px",
        align: "center",
        isRequired: true,
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "detailInfo",
        width: "180px",
        align: "center",
      },
    ],

    detailDialog: {
      // 保管期限
      retentionPeriod: "",
      retentionPeriodCal: "",
      dateMenu: null,

      // 金額
      amount: "",
      formattedAmount: "",

      // 通貨単位
      monetaryUnit: "",
      monetaryUnitList: [],

      // コントロールナンバー
      controlNumber: "",

      // 通知仕様ナンバー
      notificationSpecificationNumber: "",

      // 備考
      remarks: "",
    },
    headerItems: [],
    // 合計リスト
    inputSumList: [],
    totalPage: 0,
    page: 1,
    itemsPerPage: 10,
    lastListFlg: false,
    // キャッシュ・変更部分登録用リスト
    changeList: [],
    // 一旦非表示するためのフラグ
    kariFlg: true,
    popInboundUnitQuantity: "",
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 修正登録時アラートダイアログ
    fixInsertDialogMsg: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 追加ダイアログ
    isOpenCountAchieveAddDialog: false,
    // 修正登録フラグ
    addFlg: false,
    // 伝票削除フラグ
    delFlg: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新付帯品番
    popNewProductAccessoryCd: "",
    // 新製品名
    popNewProductNm: "",
    // 新ロットフラグ
    popNewProductLotFlg: "",
    // 新シリアルフラグ
    popNewProductSerialFlg: "",
    // 新期限日フラグ
    popNewProductDueDateFlg: "",
    // 新付属キー１フラグ
    popNewProductLotSubkey1Flg: "",
    // 新付属キー２フラグ
    popNewProductLotSubkey2Flg: "",
    // 新商品を選択したインデックス
    newProductSelectedIndex: 0,
    // 一覧index
    editedIndex: -1,
    // 有償無償
    freeCostDivList: [],
    isDisabledFreeReason: true,
    // 無償理由プルダウン
    freeReasonList: [],
    // 責任区分
    blameDivList: [],
    // 削除リスト
    deleteList: [],
    // バリデーションチェックリスト
    editedList: [],
    // 入荷ステータス
    arrivalStatusList: [],
    // 付帯情報ダイアログ
    dialogAddProudct: false,
    // 入荷No
    txt_slipNo: "",
    // 有償/無償
    isPaid: "01",
    // 入荷元SID（一覧返す用）
    fromSidLList: "",
    // 無償理由
    freeReasonSelected: "",
    // 責任
    isRes: "",
    isResBadValue: "",
    isResBadCount: 0,
    // 入荷先
    arrivalSelected: "",
    // 入荷ステータス
    arrivalStatusSelected: "",
    // メニュー
    openMenu: null,
    arrivalScheduleDateMenu: false,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    isDisabledBlame: true,
    checkFlg: false,
    simpleDialog: false,
    // バリデーションチェックメッセージダイアログ
    alertMessageProCd: "",
    alertMessageProNm: "",
    alertMessageInUnit: "",
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    // 一覧領域表示フラグ
    listCompleteFlg: false,
    // 修正不可フラグ TRUE：修正不可、FALSE：修正可能
    modifiedFlg: false,
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    // 処理区分
    processDivSelected: "",
    defaultItem: {},
    // 倉庫
    warehouseSelected: "",
    // ロケ
    locationSelected: "",
    locationList: [],
    dummyItemList: [],
    // 品番プルダウン
    // ボタン活性化
    ableConfirm: false,
    delConfirm: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    // 入荷予定日readonlyフラグ
    isArrivalScheduleDate: false,
    locationSidList: [],
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accList: [],
    inputListItem: {},
    accKeys: [],
    focusIdx: 0,
    location: "",
    // 入荷元名
    arrivalNm: "",
    // 入荷元ドロップダウンの編集可否を判定するフラグ
    isDisableArrivalDropdown: true,
    arrivalList: [],
    arrivalHint: "",
    searchArrival: "",
    officeCd: "",
    //前画面から受け取る値
    planningSection: "",
    voucherNo: "",
    allocationResult: "",
    arrCreatedDateFrom: "",
    remrks: "",
    searchParam: "",
    // 詳細画面ポップアップ
    openDatailsAdd: false,
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
  }),

  // メソッド
  methods: {
    // 初期表示処理
    init() {
      this.lastListFlg = false;
      this.focusIdx = this.listLength;
      this.inputList = [];
      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.defaultItem.productNm = "";
        this.defaultItem.warehouseList = this.warehouseList;
        this.inputList.push({ ...Object.assign({}, this.defaultItem) });
      }

      this.headerItems = this.headerItemsForRcv;
      this.commonInput.suppliersSelected = this.suppliersSelected;
    },
    /**
     * 保管日付入力
     */
    changeRetentionPeriod(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.detailDialog[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.detailDialog[modelName] = null;
      }
    },

    // 詳細情報登録画面をポップアップで開く
    openDetailsAdd(item, index) {
      this.index = index;

      this.detailInfo = {
        inListSubSid: item.inListSubSid,
        clientSid: this.commonInput.suppliersSelected,
        inScheduleDate: this.commonInput.inScheduleDate,
        itemCd: item.itemCd,
        itemName: item.productNm,
        warehouse: item.warehouseSid,
        locationSid: item.locationSid,
      };
      item.inScheduleSub?.map((item) => {
        this.detailInfo[item.subInformation] = item.subInformationComment;
      });

      this.openDatailsAdd = true;
    },

    // 詳細情報登録ボタン押下
    addDetailsInfo(value) {
      this.openDatailsAdd = false;

      this.inputList[this.index].warehouseSid = value.infoCategoryDtl.warehouse;
      this.inputList[this.index].locationSid = value.infoCategoryDtl.locationSid;
      this.inputList[this.index].inScheduleSub = [
        {
          subInformation: "deliveryType",
          subInformationComment: value.infoCategoryDtl.deliveryType,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "groupName",
          subInformationComment: value.infoCategoryDtl.groupName,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "partsRequester",
          subInformationComment: value.infoCategoryDtl.partsRequester,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "retentionDuedate",
          subInformationComment: value.infoCategoryDtl.retentionDuedate,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "amount",
          subInformationComment: value.infoCategoryDtl.amount,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "currencyUnits",
          subInformationComment: value.infoCategoryDtl.currencyUnits,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "controlNumber",
          subInformationComment: value.infoCategoryDtl.controlNumber,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "specificationNoticeNumber",
          subInformationComment: value.infoCategoryDtl.specificationNoticeNumber,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
        {
          subInformation: "remarks",
          subInformationComment: value.infoCategoryDtl.remarks,
          // updateDatetime: dateTimeHelper.convertUTC(),
        },
      ];
      this.inputList[this.index].locationReserve = [
        {
          locationSid: value.infoCategoryDtl.locationSid,
        },
      ];

      // this.inputList[this.index].inScheduleSub.map((item) => {
      //   return (item.subInformationComment = value.infoCategoryDtl[item.subInformation]);
      // });
    },

    /**
     * 新製品登録POPUP：登録処理
     */
    popNewProductSave() {
      if (!this.$refs.newpopup.validate()) {
        return;
      }
      let cnt = 0;
      let popInboundUnitQuantityLength = this.popInboundUnitQuantity;

      if (
        popInboundUnitQuantityLength != undefined &&
        popInboundUnitQuantityLength != null &&
        popInboundUnitQuantityLength
      ) {
        popInboundUnitQuantityLength = popInboundUnitQuantityLength.replaceAll(",", "");
      }

      // 入力チェック
      if (!this.popNewProductCd) {
        // 品番未入力時、メッセージ設定
        this.alertMessageProCd = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popNewProductNm) {
        // 品名未入力時、メッセージ設定
        this.alertMessageProNm = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popInboundUnitQuantity) {
        // 入庫単位数未入力時、メッセージ設定
        this.alertMessageInUnit = i18n.tc("check.chk_input");
        return;
      }

      // 数値チェック
      if (isNaN(popInboundUnitQuantityLength)) {
        // 入庫単位数が数値でない時
        cnt += 1;
      } else {
        // 処理なし
      }
      // 整数チェック
      if (Number.isInteger(popInboundUnitQuantityLength)) {
        // 入庫単位数が整数でない時
        cnt += 1;
      } else {
        // 処理なし
      }

      if (popInboundUnitQuantityLength.length > 9) {
        return;
      } else if (cnt == 0) {
        // 入力チェックエラー、非表示
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";

        // 仮登録POSTAPI実施
        const newCode = this.addCdName();

        // 仮登録POSTAPIが成功したら、後続処理実施
        Promise.all([newCode])
          .then(() => {
            // 入庫単位数を数値に変換
            var popIn = this.popInboundUnitQuantity;
            var popInChenge = Number(commonUtil.zen2han(popIn).replaceAll(",", ""));
            var lotFlg = "0";
            var serialFlg = "0";
            var dueDateFlg = "0";
            var lotSubkey1Flg = "0";
            var lotSubkey2Flg = "0";

            if (this.popNewProductLotFlg == "01") {
              lotFlg = "1";
            }
            if (this.popNewProductSerialFlg == "01") {
              serialFlg = "1";
            }
            if (this.popNewProductDueDateFlg == "01") {
              dueDateFlg = "1";
            }
            if (this.popNewProductLotSubkey1Flg == "01") {
              lotSubkey1Flg = "1";
            }
            if (this.popNewProductLotSubkey2Flg == "01") {
              lotSubkey2Flg = "1";
            }

            var itemCd = this.popNewProductCd.substring(0, 50).trim();
            var incidental = this.popNewProductCd.slice(-50).trim();

            this.dummyItemList.push({
              text: itemCd + " " + incidental + "（" + this.popNewProductNm + "）",
              value: this.popNewProductSid,
              name: this.popNewProductNm,
              code: this.popNewProductCd,
              quantity: popInChenge,
              isNew: true,
              serialFlg: serialFlg,
              lotFlg: lotFlg,
              dueDateFlg: dueDateFlg,
              lotSubkey1Flg: lotSubkey1Flg,
              lotSubkey2Flg: lotSubkey2Flg,
            });
            // 仮登録内容を、品番/品名プルダウンに追加
            this.inputList[this.newProductSelectedIndex].productList = this.inputList[
              this.newProductSelectedIndex
            ].productList.concat(this.dummyItemList);

            this.dialogNewProduct = false;
            // this.addCdName();

            // 仮登録を選択した部分の値を変更する。（変更しないと仮登録のままなので）
            this.inputList[this.newProductSelectedIndex].productCd = this.popNewProductSid;
            this.inputList[this.newProductSelectedIndex].productHint =
              itemCd + " " + incidental + "（" + this.popNewProductNm + "）";

            //仮登録をした行のロットとS/Nのフラグをセット
            this.inputList[this.newProductSelectedIndex].lotFlg = lotFlg;
            this.inputList[this.newProductSelectedIndex].serialFlg = serialFlg;
            this.inputList[this.newProductSelectedIndex].dueDateFlg = dueDateFlg;
            this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = lotSubkey1Flg;
            this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = lotSubkey2Flg;

            // 仮登録のものの背景を黄色くする。
            this.inputList[this.newProductSelectedIndex].isNew = true;
            // 値をクリアする。
            this.popNewProductNm = "";
            this.popNewProductCd = "";
            this.popNewProductAccessoryCd = "";
            this.popInboundUnitQuantity = "";
            this.popNewProductLotFlg = "";
            this.popNewProductSerialFlg = "";
            this.popNewProductDueDateFlg = "";
            this.popNewProductLotSubkey1Flg = "";
            this.popNewProductLotSubkey2Flg = "";
          })
          .catch(() => {})
          .finally(() => {
            this.$refs.newpopup.resetValidation();
            this.dialogNewProduct = false;
          });
      }
    },

    /**
     * 入荷予定修正削除画面：業務利用商品API（POST）
     */
    addCdName() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_004;

      body.reqIdv.clientSid = this.suppliersNm;
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      if (this.popNewProductAccessoryCd) {
        body.reqIdv.itemCd =
          this.popNewProductCd.padEnd(50, " ") + this.popNewProductAccessoryCd.padEnd(50, " ");
      } else {
        body.reqIdv.itemCd = this.popNewProductCd.padEnd(50, " ") + "".padEnd(50, " ");
      }
      body.reqIdv.itemName = this.popNewProductNm;
      body.reqIdv.inboundUnitQuantity = this.popInboundUnitQuantity.replaceAll(",", "");
      body.reqIdv.referenceDatetime = dateTimeHelper.convertUTC(this.arrivalScheduleDate);
      //ロット有無フラグ
      if (this.popNewProductLotFlg == "01") {
        body.reqIdv.lotAircraftnumberFlg = "1";
      } else {
        body.reqIdv.lotAircraftnumberFlg = "0";
      }
      //S/N有無フラグ
      if (this.popNewProductSerialFlg == "01") {
        body.reqIdv.serialAircraftnumberFlg = "1";
      } else {
        body.reqIdv.serialAircraftnumberFlg = "0";
      }
      //期限日有無フラグ
      if (this.popNewProductDueDateFlg == "01") {
        body.reqIdv.dueDateFlg = "1";
      } else {
        body.reqIdv.dueDateFlg = "0";
      }
      //付属キー１有無フラグ
      if (this.popNewProductLotSubkey1Flg == "01") {
        body.reqIdv.lotSubkey1Flg = "1";
      } else {
        body.reqIdv.lotSubkey1Flg = "0";
      }
      //付属キー２有無フラグ
      if (this.popNewProductLotSubkey2Flg == "01") {
        body.reqIdv.lotSubkey2Flg = "1";
      } else {
        body.reqIdv.lotSubkey2Flg = "0";
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMBIZ, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("addCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.popNewProductNm = jsonData.resIdv.ItemsBizUse.itemName;
              this.popNewProductCd = jsonData.resIdv.ItemsBizUse.itemCd;
              this.popNewProductSid = jsonData.resIdv.ItemsBizUse.itemStandardSid;
              let popInboundUnitQuantityNum = jsonData.resIdv.ItemsBizUse.inboundUnitQuantity;
              this.popInboundUnitQuantity = String(
                commonUtil.formatToCurrency(Number(popInboundUnitQuantityNum))
              );
              resolve(response);

              // エラー時
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      return item.locationHint;
    },
    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(item, index) {
      this.inputList[index].location = "";
      this.inputList[index].locationSid = null;
      this.inputList[index].locationHint = "";
      this.inputList[index].warehouseCheckMsg = i18n.tc("");
    },
    /**
     * 新製品登録POPUP：新製品登録処理
     */
    changeProductNm(value, item, index) {
      if (value == "product0") {
        // 仮登録押下時
        this.dialogNewProduct = true;

        // 仮登録押下した行のインデックスを保持
        this.newProductSelectedIndex = index;

        // バリエーションメッセージを空設定
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";
        item.search = "";
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // 既存品番/品名を選択時
        let val = this.inputList[index].productList.find((v) => v.value == value);
        if (val ?? false) {
          // ロケーションクリア
          item.locationSid = null;
          item.location = null;
          item.locationList = [];
          item.locationHint = null;

          item.productNm = val.name;
          item.productHint = val.text;
          item.isNew = val.isNew;
          item.productList = item.productList.filter((v) => v.value == value);

          item.requiredItemCheckMsg = i18n.tc("");
          item.warehouseCheckMsg = i18n.tc("");
          item.locationCheckMsg = i18n.tc("");
        }
      }

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.productNm = "";
        item.productHint = "";
        item.serial = "";
        item.serialFlg = "0";
        item.lotFlg = "0";
        item.dueDate = "";
        item.dueDateFlg = "0";
        item.lotSubkey1 = "";
        item.lotSubkey1Flg = "0";
        item.lotSubkey2 = "";
        item.lotSubkey2Flg = "0";
        item.isNew = false;
        item.productQty = null;
        item.caseSu = null;
        item.ballSu = null;
        item.baraSu = null;
        item.lot = null;
        item.locationSid = null;
        item.location = null;
        item.warehouseSid = null;
        item.warehouse = null;
        item.locationHint = null;
        item.locationList = [];
      } else {
        // 処理なし
      }

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // 既存ロケを選択時
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
      }
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    // ロケーションの有効日時バリデーション
    inputLocationValid(item) {
      if (item.locationList && item.locationList.length > 0) {
        const location = item.locationList.find((v) => v.value == item.locationSid);
        if (location && this.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(location.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(location.validTo));
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputLocationFromToValidate");
          }
        }
      }
      if (item.location == "" || item.location == null) {
        if (item.warehouse != "" && item.warehouse != null) {
          return i18n.tc("check.chk_input");
        }
      }
      return true;
    },

    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouse) {
          return this.warehouseList[i].name;
        }
      }
    },
    inputWarehouseValid(item) {
      if (item.warehouse == "" || item.warehouse == null) {
        if (item.location != "" && item.location != null) {
          return i18n.tc("check.chk_input");
        }
      }
      return true;
    },
    // 商品の有効日時バリデーション
    inputProductValid(item) {
      if (item.productList && item.productList.length > 0) {
        const product = item.productList.find((v) => v.value == item.productCd);
        if (product && this.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(product.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(product.validTo));
          // 仮登録商品に対するチェック対象から除外
          if (product.validTo == null) {
            return true;
          }
          // 有効日時が期限内の場合、エラー対象外
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputProductFromToValidate");
          }
        }
      }

      if (
        (item.warehouse != "" && item.warehouse != null) ||
        (item.location != "" && item.location != null)
      ) {
        if (item.productCd == "" || item.productCd == null) {
          return i18n.tc("check.chk_input");
        }
      }

      return true;
    },
    /**
     * 取引先一覧・入荷元一覧を取得します。
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 取引先一覧を取得します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );

      // 非同期処理を開始します。
      Promise.all([clientList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の取引先一覧を設定します。
          this.supplierList = result[0];

          // 取引先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.supplierList.length; i++) {
            // 選択された取引先と一致する場合の処理です。
            if (this.suppliersNm == this.supplierList[i].value) {
              // 選択された取引先に取引先一覧の要素を設定します。
              this.suppliersSelected = this.supplierList[i].text;
            }
          }
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },

    /**
     * コードマスタを取得します。
     */
    getMstCode() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 有無区分
      const lotCnt = getParameter.getCodeMst(appConfig.CODETYPE.LOT_DIV);

      // 非同期処理を開始します。
      Promise.all([lotCnt])
        // 成功時の処理です。
        .then((result) => {
          this.lotPullList = result[0];
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },

    /**
     * 入荷予定修正削除画面：入荷予定を取得します。
     */
    getArrivalList() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SIDを設定します。
      config.params.clientSid = this.suppliersNm;

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 入荷伝票SIDを設定します。
      config.params.inListSid = this.arrSid;

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 入荷予定を取得します。
          .secureGet(appConfig.API_URL.BIZ_INSCHEDULES, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログに出力します。
            // console.debug("getArrivalList() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == "000") {
              // JSONオブジェクトから入荷予定を取得します。
              this.arrivalDataList = jsonData.resIdv.inSchedules;

              // 入荷予定に対する繰り返し処理です。
              for (let i = 0; i < this.arrivalDataList.length; i++) {
                // マップを生成します。
                let data = {
                  // 入荷伝票SID
                  inListSid: this.arrivalDataList[i].inListSid,
                  // 営業所SID
                  officeSid: this.arrivalDataList[i].officeSid,
                  // 取引先SID
                  clientSid: this.arrivalDataList[i].clientSid,
                  // 入荷伝票No
                  inListNo: this.arrivalDataList[i].inListNo,
                  // 入荷元名
                  fromName: this.arrivalDataList[i].fromName,
                  // 入荷予定日
                  inScheduleDate: this.arrivalDataList[i].inScheduleDate,
                  // 修正区分
                  modifyDiv: this.arrivalDataList[i].modifyDiv,
                  // 有償無償
                  isPaid: this.arrivalDataList[i].freeCostDiv,
                  // 処理区分
                  processDiv: this.arrivalDataList[i].processDiv,
                  // 処理区分名
                  processDivName: this.arrivalDataList[i].processDivName,
                  // 責任区分
                  blameDiv: this.arrivalDataList[i].blameDiv,
                  // 入荷元SID
                  fromSid: this.arrivalDataList[i].fromSid,
                  // TOSID
                  toSid: this.arrivalDataList[i].toSid,
                  // 理由CD
                  reasonCd: this.arrivalDataList[i].reasonCd,
                  // 削除フラグ
                  deleteFlg: this.arrivalDataList[i].deleteFlg,
                  // 理由CD名
                  reasonCdName: this.arrivalDataList[i].reasonCdName,
                  // 予定伝票更新日時
                  updateDatetime: this.arrivalDataList[i].updateDatetime,
                  // 完了フラグ
                  completeFlg: this.arrivalDataList[i].completeFlg,
                  // 倉庫
                  warehouseList: this.warehouseList,
                };

                // リストを検索に使う予定一覧に設定します。
                this.arrivalInputlDataList.push(data);
              }

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            console.error("getArrivalList() Resolve", resolve);

            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          // 成功・失敗に関わらず実行される処理です。
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
          });
        // 成功時の処理です。
      }).then(() => {
        if (this.arrivalInputlDataList.length > 0) {
          // 検索条件領域に値を設定
          // 有償無償区分
          this.isPaid = this.arrivalInputlDataList[0].isPaid;
          // 責任区分
          this.isRes = this.arrivalInputlDataList[0].blameDiv;

          // 処理区分名
          this.processDivSelected = this.arrivalInputlDataList[0].processDivName;
          // 入荷予定日
          this.arrivalScheduleDate = dateTimeHelper
            .convertUTC2JST(this.arrivalInputlDataList[0].inScheduleDate)
            .substr(0, 10);
          // 入荷元SID
          this.fromSidDataList = this.arrivalInputlDataList[0].fromSid;
          // 無償理由
          this.freeReasonSelected = this.arrivalInputlDataList[0].reasonCd;
          // ボタンの判定 修正登録と追加ボタン：伝票の完了フラグ1であれば非活性
          if (this.arrivalInputlDataList[0].completeFlg == 1) {
            this.isDoAddButton = true; // 追加ボタン
            this.ableConfirm = true; // 修正登録ボタン
            // this.delConfirm = true; // 伝票削除ボタン
          } else {
            this.isDoAddButton = false; // 追加ボタン
            this.ableConfirm = false; // 修正登録ボタン
            // this.delConfirm = true; // 伝票削除ボタン
          }
        } else {
          // 処理なし
        }

        // 入荷予定明細と取引先一覧・入荷元一覧を取得します。
        // this.getItems();
      });
    },

    /**
     * 仮登録行を追加
     */
    addDummy: function (item) {
      // 取引先が選択されてない場合、処理しない
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      if (item.productList.length == 0) {
        // 選択されてない場合、仮登録追加
        const list = [
          {
            text: i18n.tc("label.lbl_newProductAdd"),
            value: "product0",
            name: i18n.tc("label.lbl_newProductAdd"),
            isNew: true,
          },
        ];
        item.productList = list;
        if (this.dummyItemList.length > 0) {
          item.productList = list.concat(this.dummyItemList);
        }
      }
    },

    /**
     * 入荷予定修正削除画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定修正削除画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 入荷予定修正削除画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      // 今日以前の日付を選択できないように制御
      // let today = dateTimeHelper.convertJST();
      // if (this.arrivalScheduleDateCal == dateTimeHelper.dateCalc(today)) {
      //   return;
      // }
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrivalScheduleDateCal = null;
      }
    },
    /**
     * 入荷予定修正削除画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }
      this.inputList.splice(this.editedIndex, 1);

      if (this.inputList.length == 0) {
        this.lastListFlg = true;
      }

      // 削除後、No修正。
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = this.focusIdx + i + 1;
      }
      // console.debug("行削除");
      var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      this.$refs["productQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["caseSuCheck" + this.editedIndex].resetValidation();
      this.$refs["ballSuCheck" + this.editedIndex].resetValidation();
      this.$refs["baraSuCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 入荷予定修正削除画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      //S/N活性・非活性判定
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (this.inputList[index].productList[i].value == this.inputList[index].productCd) {
          this.inputList[index].serialFlg = this.inputList[index].productList[i].serialFlg;
          this.inputList[index].lotFlg = this.inputList[index].productList[i].lotFlg;
          this.inputList[index].dueDateFlg = this.inputList[index].productList[i].dueDateFlg;
          this.inputList[index].lotSubkey1Flg = this.inputList[index].productList[i].lotSubkey1Flg;
          this.inputList[index].lotSubkey2Flg = this.inputList[index].productList[i].lotSubkey2Flg;
        }
      }
      return this.inputList[index].productHint;
    },

    /**
     * 明細の選択した商品コードに紐づく数量を返却するメソッド
     */
    getSelectedProductQuantity(index) {
      // 返却値
      let returnQuantity = 0;

      let inputListProductCd = this.inputList[index].productCd;

      if (!inputListProductCd) {
        // inputListの商品コードを取得

        // 選択した商品コードがproductListに何番目に入っているかを調べる。
        let selectedProductListIndex = -1;
        for (var i = 0; i < this.inputList[index].productList.length; i++) {
          if (inputListProductCd == this.inputList[index].productList[i].value) {
            selectedProductListIndex = this.inputList[index].productList[i].quantity;
            break;
          }
        }
        if (0 <= selectedProductListIndex) {
          // 選択している品番の数量を取得する。
          returnQuantity = this.inputList[index].productList[selectedProductListIndex].quantity;
        }
      }
      return returnQuantity;
    },

    /**
     * 入荷予定修正削除画面：ケース数計算処理
     */
    setHintCase(value, index) {
      return this.inputList[index].caseSuHint;
    },

    /**
     * 入荷予定修正削除画面：ボール数計算処理
     */
    setHintBall(value, index) {
      return this.inputList[index].ballSuHint;
    },

    /**
     * 入荷予定修正削除画面：バラ数計算処理
     */
    setHintBara(value, index) {
      return this.inputList[index].baraSuHint;
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 入庫単位数
     */
    changePop(popInboundUnitQuantity) {
      var pop = commonUtil.zen2han(popInboundUnitQuantity).replaceAll(",", "");
      if (popInboundUnitQuantity.length > 9) {
        popInboundUnitQuantity = popInboundUnitQuantity.substring(0, 9);
      }
      if (!isNaN(Number(pop))) {
        this.popInboundUnitQuantity = commonUtil.formatToCurrency(Number(pop));
      }
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 入荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 入荷予定修正削除画面：一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        if (this.toPage) {
          this.$router.push({
            name: this.toPage,
            params: {
              headerList: this.searchParam,
              backPage: this.toPage,
            },
          });
          return;
        }

        this.updateDialogMessage = false;
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RCV_003,
          params: {
            // 取引先
            suppliersSelected: this.suppliersNm,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元ドロップダウン
            arrivalList: this.$route.params.arrivalList,
            // 入荷元ドロップダウンヒント
            arrivalNm: this.$route.params.arrivalOmitNm,
            // 入荷予定一覧検索条件
            searchFilter: this.previousSearchFilter,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入荷予定修正削除画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定修正削除画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");
      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }
      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      // 選択した商品コードがproductListに何番目に入っているかを調べる。
      let selectedProductQuantity = 0;
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (inputListProductCd == this.inputList[index].productList[i].value) {
          selectedProductQuantity = this.inputList[index].productList[i].quantity;
        }
      }

      // 各数量項目の計算処理
      if (!isNaN(Number(proQty))) {
        if (0 < selectedProductQuantity) {
          // 入荷予定数が入力されている場合
          this.inputList[index].caseSu = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].caseSuHint = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].baraSu = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].baraSuHint = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].ballSu = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          this.inputList[index].ballSuHint = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          // カンマ編集して数量にセット
          this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        } else {
          this.inputList[index].caseSu = "0";
          this.inputList[index].ballSu = "0";
          this.inputList[index].baraSu = "0";
        }
      }

      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        inQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      caseSu = caseSu.replace(/[^0-9]/gi, "");
      if (caseSu.length > 9) {
        caseSu = caseSu.substring(0, 9);
      }
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      ballSu = ballSu.replace(/[^0-9]/gi, "");
      if (ballSu.length > 9) {
        ballSu = ballSu.substring(0, 9);
      }
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
    },

    /**
     * 入荷予定修正削除画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      baraSu = baraSu.replace(/[^0-9]/gi, "");
      if (baraSu == "-0") {
        baraSu = "0";
      }
      if (baraSu.length > 9) {
        baraSu = baraSu.substring(0, 9);
      }
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
    },

    /**
     * 入荷予定修正削除画面：閉じる処理(仮登録ダイアログ)
     */
    popNewProductClose() {
      // 値をクリアする。
      this.popNewProductNm = "";
      this.popNewProductCd = "";
      this.popNewProductAccessoryCd = "";
      this.popNewProductLotFlg = "";
      this.popNewProductSerialFlg = "";
      this.popNewProductDueDateFlg = "";
      this.popNewProductLotSubkey1Flg = "";
      this.popNewProductLotSubkey2Flg = "";
      this.popInboundUnitQuantity = null;
      // 品番/品名欄を初期化
      this.inputList[this.newProductSelectedIndex].productCd = "";
      this.inputList[this.newProductSelectedIndex].isNew = false;
      this.dialogNewProduct = false;
      this.inputList[this.newProductSelectedIndex].lotFlg = "0";
      this.inputList[this.newProductSelectedIndex].serialFlg = "0";
      this.inputList[this.newProductSelectedIndex].dueDateFlg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = "0";
      this.$refs.newpopup.resetValidation();
    },

    /**
     * ボタン押下時の処理
     */
    async viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_add":
          if (!this.$refs.editList.validate()) {
            return;
          } else {
            message = await messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_add")]);
            action = await this.config;
          }
          break;
        case "btn_close":
          message = await messsageUtil.getMessage("P-COM-001_004_W");
          action = await this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = await message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 閉じる処理
     */
    close() {
      this.init();
      this.$emit("update:isOpenDetailDialog", false);
    },

    checkList() {
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].requiredItemCheckMsg = i18n.tc("");
        this.inputList[i].warehouseCheckMsg = i18n.tc("");
        this.inputList[i].locationCheckMsg = i18n.tc("");
      }

      //エラー内容初期化処理--------------------------------------------------
      let cnt = 0;
      this.alertMessage = "";
      var errNo = false;
      // 伝票部、入力チェック実施
      if (this.$refs.editList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productCd);
          }
        }

        for (let i = 0; i < this.inputList.length; i++) {
          let count = 0;
          // 品番の入力有無チェック
          if (this.inputList[i].productCd != null && this.inputList[i].productCd != "") {
            count++;
          }
          // 品名の入力有無チェック
          if (this.inputList[i].productNm != null && this.inputList[i].productNm != "") {
            count++;
          }
          // 入り数有無チェック
          if (this.inputList[i].inQty != null && this.inputList[i].inQty != "") {
            count++;
          }
          // 部品要求者チェック
          if (this.inputList[i].inQty != null && this.inputList[i].inQty != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.inputList[i].productCd == null ||
              this.inputList[i].productCd == "" ||
              this.inputList[i].inQty == null ||
              this.inputList[i].inQty == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.inputList[i].requiredItemCheckMsg = i18n.tc("check.chk_input");
            }
          }
          // 倉庫ロケーションの相関チェック(倉庫、ロケいずれかが入力されていた場合は両方入力されていないとNG)
          // 倉庫
          let warehouseSid = this.inputList[i].warehouseSid;
          // ロケ
          let location = this.inputList[i].locationReserve[0].locationSid;

          // 入力チェック;
          if (
            (warehouseSid == null || warehouseSid == "") &&
            (location == null || location == "")
          ) {
            // 倉庫とロケが両方入力されていない場合は処理なし
          } else {
            // 上記以外の場合
            if (warehouseSid != null && warehouseSid != "" && location != null && location != "") {
              // 倉庫ロケ両方入力されている場合は処理なし
            } else {
              if (warehouseSid == null || warehouseSid == "") {
                // ロケーションのみ入力されている場合

                errNo = true;
              } else {
                // 上記以外の場合（倉庫のみ入力されている場合）

                errNo = true;
              }
            }
          }
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.errorFlg = false;
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          cnt = i + 1;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            var checkInQty = Number(
              commonUtil.zen2han(this.inputList[i].inQty).replaceAll(",", "")
            );

            if (checkInQty == 0) {
              // 入り数が0の場合、警告メッセージ表示
              this.errorFlg = false;
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-SHP-003_001_E");
              cnt = i + 1;
            } else {
              // 処理なし
            }

            if (checkInQty == "") {
              // 入り数が未入力時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 数値チェック
            if (isNaN(checkInQty)) {
              // 出荷予定数、ボール数、ケース数、バラ数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }
            // 整数チェック
            if (Number.isInteger(checkInQty)) {
              // 処理なし
            } else {
              // 出荷予定数、ボール数、ケース数、バラ数が整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (0 < checkInQty) {
              // 処理なし
            } else {
              // 入荷予定数、ボール数、ケース数、バラ数が0未満の場合
              cnt = i + 1;
            }
          }
        }
        if (errNo || cnt > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    /**
     * 設定ボタン押下処理
     */
    config() {
      let sendList = [];
      var no = Number(this.inputList[0].No);
      var i = 0;
      sendList = this.inputList.filter((item) => item.productCd != null && item.productCd != "");
      let error = 0;
      sendList.forEach((data) => {
        if (!Object.prototype.hasOwnProperty.call(data, "inScheduleSub")) {
          error++;
          return;
        }
        data.No = no + i;
        i += 1;
      });

      if (error) {
        this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_008_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.isOpen = true;
        return;
      }

      this.viewDataList.push(this.inputList[0]);

      this.$emit("child-event", true, sendList);

      this.close();
    },
  },

  // 監視
  watch: {
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // // 取引先が選択されてない場合、処理しない
                // if (this.suppliersSelected == null || this.suppliersSelected == "") {
                //   return;
                // }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val.replace(/\s+/g, "") == value.productHint.replace(/\s+/g, "")) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                // // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.lot = null;
                value.serial = null;
                value.dueDate = null;
                value.lotSubkey1 = null;
                value.lotSubkey2 = null;
                value.serialFlg = "0";
                value.lotFlg = "0";
                value.dueDateFlg = "0";
                value.lotSubkey1Flg = "0";
                value.lotSubkey2Flg = "0";
                value.caseSu = null;
                value.ballSu = null;
                value.baraSu = null;
                value.locationSid = null;
                value.location = null;
                value.locationHint = null;
                value.warehouseSid = null;
                value.warehouse = null;

                console.log("pass");
                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  var itemsBiz;
                  itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersNm,
                    dateTimeHelper.convertUTC(this.arrivalScheduleDate),
                    val.replace(/\s+/g, "")
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                }
              } else {
                this.addDummy(value);
              }
            }
          );
          this.$watch(
            () => value.locaSearch,
            function handler(val, oldVal) {
              if ((val ?? false) !== false && val !== oldVal) {
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  // 取引先が選択されてない場合、処理しない
                  return;
                }

                if (val == value.locationHint) {
                  // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                  return;
                }

                // 入力桁数が定義した数異常の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  this.loadingCounter = 1;

                  const config = this.$httpClient.createGetApiRequestConfig();
                  config.params.locationNo = val;
                  config.params.officeSid = sessionStorage.getItem("sales_office_sid");
                  config.params.clientSid = this.searchSuppliersSelected;
                  if (value.warehouse) {
                    config.params.warehouseSid = value.warehouse;
                  }
                  config.params.reqComReferenceDate = dateTimeHelper.convertUTC(
                    this.arrivalScheduleDate
                  );
                  return new Promise((resolve, reject) => {
                    this.$httpClient
                      .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
                      .then((response) => {
                        // console.debug("$watch Response", response);
                        const jsonData = JSON.parse(JSON.stringify(response.data));
                        if (jsonData.resCom.resComCode == "000") {
                          const list = [];
                          jsonData.resIdv.locations.forEach((row) => {
                            list.push({
                              text: row.locationNo,
                              value: row.locationSid,
                              name: row.locationNo,
                              validFrom: row.validFrom,
                              validTo: row.validTo,
                            });
                          });
                          value.locationList = list;
                          resolve(response);
                        } else {
                          this.infoDialog.message = jsonData.resCom.resComMessage;
                          this.infoDialog.title = appConfig.DIALOG.title;
                          this.simpleDialog = true;
                          this.infoDialog.firstPageFlag = true;
                          reject();
                        }
                      })
                      .catch((ex) => {
                        this.infoDialog.message = ex;
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.simpleDialog = true;
                        // OK NGボタン
                        this.infoDialog.firstPageFlag = true;
                        reject();
                      })
                      .finally(() => {
                        // ローディング画面表示OFF
                        this.loadingCounter = 0;
                      });
                  });
                } else {
                  value.locationList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    isOpenDetailDialog(val) {
      if (val) {
        this.init();
      }
    },
    "detailDialog.retentionPeriodCal": function (newVal) {
      this.detailDialog.retentionPeriod = this.formatDate(newVal);
    },
  },
  mounted() {},
  // Vueインスタンスが生成された後
  created() {
    // 初期表示処理を実行
    this.init();
  },
};
</script>

<style>
@import "../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
#listData.v-data-table ::v-deep th {
  padding: 0 8px;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-y: hidden;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}

.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
</style>
