<template>
  <div>
    <div :style="width ? { width: width } : {}">
      <v-tooltip bottom :disabled="!isTooltipFlg">
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex" v-bind="attrs" v-on="on">
            <v-autocomplete
              ref="textElement"
              v-model="selectedItem"
              :items="internalItems"
              :search-input.sync="search"
              :hint="commonUtil.findPropertyByValue(internalItems, selectedItem)"
              :label="$t('label.lbl_productCnCd')"
              :readonly="readonly"
              :disabled="disabled"
              :append-icon="'$dropdown'"
              :rules="required ? [vRules.inputRequired, ...rules] : [...rules]"
              item-value="value"
              item-text="text"
              persistent-hint
              dense
              :error-messages="errorMessagesTxt"
              this.error
              @change="(event) => changeProductNm(event)"
              @focus="addDummy"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span></template
              >
            </v-autocomplete>
            <span class="require asterisk-spacer" v-if="required">*</span>
          </div>
        </template>
        <span
          >{{ commonUtil.findPropertyByValue(internalItems, selectedItem, "value", "text") }}
        </span>
      </v-tooltip>
    </div>
    <ItemRegisterDialog
      :clientSid="popNew.clientSid"
      :productSid.sync="popNew.productSid"
      :date="date"
      :isShow.sync="popNew.isShow"
    />
  </div>
</template>

<script>
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { getParameter } from "@/assets/scripts/js/GetParameter";
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import ItemRegisterDialog from "@/components/ItemRegisterDialog";

export default {
  inheritAttrs: false,
  name: "CItemInputRegistable",
  components: { ItemRegisterDialog },
  props: {
    // 値
    value: {
      type: [String, Number],
      default: "",
    },
    // 取引先SID
    clientSid: {
      type: String,
      required: true,
      validator(value) {
        return typeof value === "string" || null;
      },
      default: null,
    },
    // 商品Code
    itemCd: {
      type: [String, Number],
      default: "",
    },
    // 商品Name
    itemName: {
      required: false,
      type: String,
      default: undefined,
    },
    // 有効日付
    date: {
      required: false,
      type: [String],
      default: undefined,
    },
    // 商品SIDリスト
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    // 必須
    required: {
      default: false,
    },
    // エラー処理
    errorMessages: {
      required: false,
      type: String,
      default: undefined,
    },
    // 読み取り専用
    readonly: {
      type: Boolean,
      default: false,
    },
    // 無効化
    disabled: {
      type: Boolean,
      default: false,
    },
    // 幅
    width: {
      type: String,
      default: undefined,
    },
    // バリデーション
    rules: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["errorMessage", "loadingSpinner"],
  data: () => ({
    // 選択値
    selectedItem: "",
    // リスト
    internalItems: [],
    // ツールチップフラグ
    isTooltipFlg: false,
    // 最初フラグ
    isFirstFlg: false,
    // 検索
    search: "",
    // 共通機能
    commonUtil: {
      findPropertyByValue(list, targetValue, keyToCompare = "value", keyToReturn = "name") {
        return list.find((item) => item[keyToCompare] === targetValue)?.[keyToReturn] || "";
      },
    },
    // バリデーション
    vRules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
    popNew: {
      clientSid: "",
      productCd: "",
      productNm: "",
      productSid: "",
      isShow: false,
    },
    productAddList: {
      text: i18n.tc("label.lbl_ProductAdd"),
      value: "product0",
      name: i18n.tc("label.lbl_ProductAdd"),
      isNew: true,
    },
    errorMessagesTxt: "",
  }),
  methods: {
    // 初期化
    init() {
      // 初期値が存在する場合
      if (this.clientSid && this.value) {
        // 初期値設定
        this.selectedItem = this.value;
        // 商品検索
        this.searchItems({
          clientSid: this.clientSid,
          itemStandardSid: this.value,
          isMain: "1",
          reqComReferenceDate: dateTimeHelper.convertUTC(this.date),
        });
      }
      this.popNew.clientSid = this.clientSid;
      this.errorMessagesTxt = this.errorMessages;
    },
    // 商品検索
    searchItems(params) {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 商品
      const itemsBiz = getParameter.getItemMst(params);
      Promise.all([itemsBiz])
        .then((result) => {
          // 画面の初期値を設定します。
          this.internalItems = result[0];
        })
        .catch((ex) => {
          if (!this.errorMessage.isError) {
            this.errorMessage.isError = true;
            this.errorMessage.message = ex;
          }
        })
        .finally(() => {
          this.$emit(
            "update:itemName",
            this.commonUtil.findPropertyByValue(this.internalItems, this.selectedItem)
          );
          // ローディング画面表示OFF
          this.loadingSpinner.counter--;
        });
    },
    addDummy() {
      // 仮登録押下時
      if (this.internalItems.length == 0) {
        // 選択されてない場合、仮登録追加
        this.internalItems = [this.productAddList];
      }
    },
    /**
     * 新製品登録POPUP：新製品登録処理
     */
    async changeProductNm(value) {
      if (value == this.productAddList.value) {
        // 仮登録押下時
        this.popNew.isShow = await true;
        await this.internalItems.shift();
        this.search = await "";
      }
    },
  },
  watch: {
    value(newValue) {
      // 商品標準SIDがnullだった場合
      if (!newValue) {
        this.internalItems = [];
        this.selectedItem = "";
        return;
      }
      if (newValue == this.productAddList.value) {
        // this.selectedItem = "";
        return;
      }
      this.selectedItem = newValue;
      this.$nextTick(() => {
        setTimeout(() => {
          // 選択商品/品番が入力幅を超えたらツールチップを表示
          const textElement = this.$refs.textElement.$el.querySelector(".v-input__control input");
          this.isTooltipFlg = textElement.clientWidth < textElement.scrollWidth;
        }, 0);
      });
      // 3文字以上入力された場合
      if (newValue?.trim().length >= appConfig.CNTCHARITEM) {
        // 商品検索
        this.searchItems({
          clientSid: this.clientSid,
          itemStandardSid: this.selectedItem,
          isMain: "1",
          reqComReferenceDate: dateTimeHelper.convertUTC(this.date),
        });
      }
    },
    clientSid(newValue) {
      if (newValue && !this.isFirstFlg) {
        // 【取引先SIDの非同期対応】取引先SIDが最初変更される場合

        // 初期値が存在する場合
        if (this.clientSid && this.value) {
          // 初期値設定
          this.selectedItem = this.value;
          // 商品検索
          this.searchItems({
            clientSid: this.clientSid,
            itemStandardSid: this.value,
            isMain: "1",
            reqComReferenceDate: dateTimeHelper.convertUTC(this.date),
          });
        }
        // 最初フラグ
        this.isFirstFlg = true;
      } else if (this.isFirstFlg) {
        // 最初以降に取引先SIDが更新される場合、初期化
        this.selectedItem = "";
        this.internalItems = [];
      }
      this.popNew.clientSid = newValue;
    },
    errorMessages(newValue) {
      this.errorMessagesTxt = newValue;
    },
    selectedItem(newValue) {
      if (!newValue || newValue == null) {
        this.selectedItem = "";
      } else {
        this.internalItems.map((value) => {
          if (value.value == this.selectedItem) {
            this.popNew.productSid = value.value;
            this.popNew.productCd = value.code;
            this.popNew.productNm = value.name;
          }
        });
      }
      this.$emit("input", newValue);
      this.$emit("update:value", this.selectedItem);
    },
    search(newValue) {
      // 検索内容がnull, undefinedではないかつ、取引先SIDが存在する場合
      if ((newValue ?? false) && this.clientSid) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (
          newValue ==
          this.commonUtil.findPropertyByValue(
            this.internalItems,
            this.selectedItem,
            "value",
            "text"
          )
        ) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (newValue?.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (newValue.length >= appConfig.CNTCHARITEM) {
          // 商品検索
          this.searchItems({
            clientSid: this.clientSid,
            reqComReferenceDate: dateTimeHelper.convertUTC(this.date),
            itemName: newValue,
            isMain: "1",
          });
        } else {
          this.selectedItem = "";
          this.internalItems = [];
        }
      }
    },
    internalItems: {
      handler(newValue) {
        this.$emit("update:items", newValue);
        const item = this.internalItems.find((item) => {
          if (item.value == this.selectedItem) {
            return item.code;
          }
        });
        this.$emit("update:itemCd", item?.code);
      },
      deep: true,
    },
    "popNew.isShow": {
      handler(newValue) {
        if (newValue) {
          this.selectedItem = "";
        }
      },
    },
    "popNew.productSid": {
      handler(newValue) {
        if (newValue) {
          this.selectedItem = newValue;
          this.popNew.productNm = this.commonUtil.findPropertyByValue(
            this.internalItems,
            this.selectedItem
          );
        } else {
          this.selectedItem = "";
        }
      },
    },
  },
  created() {
    this.init();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
